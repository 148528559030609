// React
import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';

// Redux
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { removeNotification } from '../../store/slices/statusSlice';

// Types
import { Notification as NType } from '../../types/entities';

type NotificationProps = {
    /** Notification content */
    notification: NType;
};

/**
 * App notification
 * @description
 * Notification that appears above content in the
 * top right corner.
 * @param props
 */
function Notification(props: NotificationProps) {
    // Hooks
    const dispatch = useAppDispatch();

    // Effects
    useEffect(() => {
        setTimeout(() => {
            dispatch(removeNotification(props.notification.id));
        }, 4000);
    }, [dispatch, props.notification.id]);

    // Render
    return (
        <Transition
            show={!!props.notification}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                <div className='w-0 flex-1 p-4'>
                    <div className='flex items-start'>
                        <div className='w-0 flex-1'>
                            <p className='text-sm font-medium text-gray-900'>
                                {props.notification?.title}
                            </p>
                            <p className='mt-1 text-sm text-gray-500'>{props.notification?.text}</p>
                        </div>
                    </div>
                </div>
                <div className='flex border-l border-gray-200'>
                    <button
                        className='focus:outline-none flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-secondary hover:opacity-70 focus:ring-2 focus:ring-indigo-500'
                        onClick={() => dispatch(removeNotification(props.notification.id))}>
                        Close
                    </button>
                </div>
            </div>
        </Transition>
    );
}

export default Notification;
