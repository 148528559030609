// React
import { useEffect } from 'react';

// Hooks
import useLogin from './useLogin';
import { useAppDispatch } from './useAppDispatch';

// Store
import { logout } from '../store/slices/authenticationSlice';

// Locale
import { isLanguageAvailable } from '../locale';

// Themes
import { applyTheme } from '../themes';

// Types
import { STORAGE_KEY } from '../types/enums';

// Others
import { useTranslation } from 'react-i18next';

/**
 * Used every time the application starts.
 * Provide:
 * - autologin for returning users
 * - language detection
 */
function useOnStartup() {
    // Hooks
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const { autoLogin } = useLogin();

    // Effects
    useEffect(() => {
        // Set browser language
        const overrideLang = sessionStorage.getItem('override-lang');
        if (overrideLang) i18n.changeLanguage(overrideLang);
        else {
            const language = navigator.language;
            const languageSplit = language.split('-');
            isLanguageAvailable(languageSplit[0]) && i18n.changeLanguage(languageSplit[0]);
        }

        // Set default theme
        applyTheme('base');

        // Check authentication
        const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);

        if (refreshToken) autoLogin(refreshToken);
        else dispatch(logout());
    }, [i18n, dispatch]);
}

export default useOnStartup;
