const baseTheme = {
    primary: "#61DAFB",
    secondary: "#254E70",
    negative: "#e45b78",
    positive: "#A3D9B1",
    textPrimary: "#333",
    backgroundPrimary: "#efefef",
    backgroundSecondary: "#F6F9FC",
}

export default baseTheme;