// Types
import { Medevac } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const medevacColumns: ColumnConfig<Medevac>[] = [
    { title: 'medevacs:createdAt', key: 'general.createdAt' },
    { title: 'medevacs:vehicleIdentifier', key: 'general.vehicleIdentifier' },
    { title: 'medevacs:destination', key: 'general.destinationId' },
    {
        title: 'medevacs:distributions',
        key: 'distribution',
        renderer: medevac => (
            <div className='whitespace-nowrap px-6 py-4 text-sm text-white'>
                <span className='rounded-tl rounded-bl bg-green-600 px-2 py-1'>
                    {medevac.patients.greens}
                </span>
                <span className='bg-yellow-600 px-2 py-1'>{medevac.patients.yellows}</span>
                <span className='rounded-tr rounded-br bg-red-600 px-2 py-1'>
                    {medevac.patients.reds}
                </span>
            </div>
        )
    }
];
