export enum ROLE {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    OPERATOR = 'OPERATOR',
    DTR = 'DTR',
    DPMA = 'DPMA',
    DSS = 'DSS',
    DSR = 'DSR',
    INFO = 'INFO'
}

export enum OPERATOR_ROLE {
    MEDIC = 'MEDIC',
    NURSE = 'NURSE',
    TRANSPORT = 'TRANSPORT',
    FIREFIGHTER = 'FIREFIGHTER'
}

export enum LICENCE {
    SIEVE = 'SIEVE',
    START = 'START',
    SORT = 'SORT',
    NEWS = 'NEWS',
    VITALS = 'VITALS',
    FAS = 'FAS',
    SAS = 'SAS',
    DEAD_BODY = 'DEAD_BODY',
    MEDEVAC = 'MEDEVAC',
    NINE_LINE = 'NINE_LINE',
    METHANE = 'METHANE',
    PATIENT = 'PATIENT',
    CHECKLISTS = 'CHECKLISTS',
    CHAT = 'CHAT'
}

export enum STORAGE_KEY {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION',
    SELECTED_MISSION = 'SELECTED_MISSION',
    TICKETING_TOKEN = 'TICKETING_TOKEN'
}

export enum LOG_RESOURCE {
    LICENCE = 'LICENCE',
    DEVICE = 'DEVICE',
    ORGANIZATION = 'ORGANIZATION',
    USER = 'USER',
    OPERATOR = 'OPERATOR',
    MISSION = 'MISSION',
    LOCATION = 'LOCATION',
    CONTACT = 'CONTACT',
    VEHICLE = 'VEHICLE',
    PATIENT = 'PATIENT',
    TRIAGE = 'TRIAGE',
    FAS = 'FAS',
    SAS = 'SAS',
    CHECKLIST = 'CHECKLIST',
    MEDEVAC = 'MEDEVAC',
    METHANE = 'METHANE',
    NINE_LINE = 'NINE_LINE',
    DEAD_BODY = 'DEAD_BODY'
}

export enum LOG_OPERATION {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum CHAT_SENDER {
    USER = 'USER',
    OPERATOR = 'OPERATOR'
}

export enum CHAT_MEDIA_TYPE {
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    TEXT = 'TEXT'
}

export enum SERVER_EVENT {
    UPDATE_DASHBOARD = 'UPDATE_DASHBOARD',
    CHAT_ADDED = 'CHAT_ADDED',
    CHAT_ARCHIVED = 'CHAT_ARCHIVED',
    NEW_MESSAGE = 'NEW_MESSAGE'
}
