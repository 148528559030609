// React
import { Fragment, useEffect } from 'react';

// Others
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

type DropdownProps = {
    /** Selected option */
    selected: any;
    /** Option list */
    items?: any[];
    /** Selector label */
    label?: string;
    /** Override the name property as key */
    nameKey?: string;
    /** Override the id property as key */
    idKey?: string;
    /** Selector icon */
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    /** Called when selection changes */
    setSelected: React.Dispatch<React.SetStateAction<any>>;
};

/**
 * Dropdown selector used inside the navbar
 * @description
 * This selector has custom styles for rendering the
 * OrganizationSelector and MissionSelector on the navbar.
 * @param props
 */
function Dropdown(props: DropdownProps) {
    // Props
    const nameKey = props.nameKey ? props.nameKey : 'name';
    const idKey = props.idKey ? props.idKey : 'id';

    // Hooks
    const { t } = useTranslation();

    // Effects
    useEffect(() => {
        if (props.items && !props.selected) props.setSelected(props.items[0]);
    }, [props]);

    // Render
    return (
        <Listbox value={props.selected} onChange={props.setSelected}>
            {({ open }) => (
                <div key={props.idKey} className='relative mt-1'>
                    <Listbox.Button className='focus:outline-none relative w-full cursor-default py-2 pr-10 text-left sm:text-sm'>
                        <div className='flex'>
                            <props.icon
                                className='-ml-2 mr-2 h-5 w-5 text-secondary'
                                aria-hidden='true'
                            />
                            {props.label && (
                                <span className='mx-1 block truncate'>{t(props.label)}</span>
                            )}
                            <span
                                className={`block truncate ${
                                    props.label ? 'ml-1 font-bold' : 'ml-0'
                                }`}>
                                {props.items && props.selected ? props.selected[nameKey] : ''}
                            </span>
                        </div>
                        <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                            <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                        </span>
                    </Listbox.Button>
                    <Transition
                        show={open}
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Listbox.Options
                            static
                            className='focus:outline-none absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm'>
                            {props.items &&
                                props.items.map(item => (
                                    <Listbox.Option
                                        key={item[idKey]}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? 'bg-secondary text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={item}>
                                        {({ selected, active }) => {
                                            return (
                                                <>
                                                    <div className='flex items-center'>
                                                        <span
                                                            className={classNames(
                                                                selected
                                                                    ? 'font-semibold'
                                                                    : 'font-normal',
                                                                'ml-3 block truncate'
                                                            )}>
                                                            {item[nameKey]}
                                                        </span>
                                                    </div>
                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-secondary',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}>
                                                            <CheckIcon
                                                                className='h-5 w-5'
                                                                aria-hidden='true'
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </Listbox.Option>
                                ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    );
}

export default Dropdown;
