// Components
import AnagraphicsT3c from './t3c/AnagraphicsT3c';
import FooterT3c from './t3c/FooterT3c';
import HeaderT3c from './t3c/HeaderT3c';
import InjuryT3c from './t3c/InjuryT3c';
import MechanismOfInjuryT3c from './t3c/MechanismOfInjuryT3c';
import SignsAndSymptomsT3c from './t3c/SignsAndSymptomsT3c';
import TreatmentsT3c from './t3c/TreatmentsT3c';
import NotesT3c from './t3c/NotesT3c';
import RescuerIdT3c from './t3c/RescuerIdT3c';

// Others
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Mpaws, Patient } from '../../types/entities';

type T3cPdfProps = {
    /**  List of the paws triages */
    paws: Mpaws[];
    /** List of the patients */
    patient: Patient;
};

/**
 * T3C Pdf
 * @description
 * PDF created for the patient.
 *
 * The PDF contains a summary of the interventions
 * performed on the patient so that these can be
 * shared with the medical personnel.
 *
 * @param props
 */
function T3cPdf(props: T3cPdfProps) {
    const newPaws: Mpaws[] = props.paws.filter(d => d.general.triageType.includes('MPAWS'));

    // Render
    return (
        <Document>
            <Page wrap={false}>
                <Text style={styles.text}>TACTICAL COMBAT CASUALTY CARE (TCCC) CARD </Text>
                <View style={styles.viewContainer}>
                    <HeaderT3c
                        patient={props.patient}
                        color={newPaws[newPaws.length - 1].general.color}
                    />
                    <AnagraphicsT3c patient={props.patient} paws={newPaws[newPaws.length - 1]} />
                    <MechanismOfInjuryT3c paws={newPaws[newPaws.length - 1]} />
                    <InjuryT3c paws={newPaws[newPaws.length - 1]} />
                    <SignsAndSymptomsT3c paws={newPaws} />
                </View>
                <FooterT3c />
            </Page>
            <Page wrap={false}>
                <View style={styles.viewContainer}>
                    <HeaderT3c
                        patient={props.patient}
                        color={newPaws[newPaws.length - 1].general.color}
                    />
                    <TreatmentsT3c />
                    <NotesT3c note={newPaws[newPaws.length - 1].paws!.notes ?? ''} />
                    <RescuerIdT3c paws={newPaws[0]} />
                </View>
                <FooterT3c />
            </Page>
        </Document>
    );
}

const styles = StyleSheet.create({
    viewContainer: {
        margin: 5,
        border: 1.5,
        borderColor: 'black',
        fontFamily: 'Roboto'
    },
    text: {
        marginTop: 5,
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5,
        alignSelf: 'center'
    }
});

export default T3cPdf;
