import { StyleSheet, Text, View } from '@react-pdf/renderer';

/**
 * Bottom Checboxes Treatments T3C
 * @description
 * This section contains the second block checkboxes
 * of the treatments section of the T3C
 * @returns
 */
function BottomCheckboxesTreatmentsT3c() {
    // Render
    return (
        <View style={styles.viewContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.otherText}>Other:</Text>
                <View style={styles.couple}>
                    <View style={styles.squares} />
                    <Text style={styles.squareText}>Combat-Pill-Pack</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares} />
                    <Text style={styles.esText}>Eye-Shield(</Text>
                    <View style={styles.rLCouple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>R</Text>
                    </View>
                    <View style={styles.rLCouple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>L)</Text>
                    </View>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares} />
                    <Text style={styles.squareText}>Splint</Text>
                </View>
            </View>
            <View style={styles.lastRowContainer}>
                <View style={styles.spacer} />
                <View style={styles.couple}>
                    <View style={styles.squares} />
                    <Text style={styles.squareText}>Hypothermia-Prevention</Text>
                </View>
                <View style={styles.underline}>
                    <Text style={styles.text}> </Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    viewContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5
    },
    spacer: {
        flex: 1
    },
    couple: {
        flexDirection: 'row',
        flex: 2,

        alignItems: 'center'
    },
    rLCouple: {
        flexDirection: 'row',
        marginRight: 3,

        alignItems: 'center'
    },
    squareText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2,
        marginRight: 5
    },
    esText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2
    },
    squares: {
        border: 1,
        width: 10,
        height: 10,
        borderColor: '#1F2937'
    },
    otherText: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        flex: 1
    },
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    underline: {
        flex: 4,
        borderBottom: 1,
        display: 'flex',
        borderBottomColor: '#1F2937',
        marginBottom: 5
    },
    firstRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    lastRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: 'flex-start',
        marginRight: 10
    }
});
export default BottomCheckboxesTreatmentsT3c;
