// React
import { useMemo } from 'react';

// Config
import { BACKEND_IP, BACKEND_PORT, BACKEND_PROTOCOL } from '../../config/constants';

// Utils
import { formatDate, isDate, isValidTimestamp } from '../../utils/dates';

// Assets
import agentsOxydantsImage from '../../assets/images/hazards/agentsOxydants.png';
import ambientDangerImage from '../../assets/images/hazards/ambientDanger.png';
import corrosiveImage from '../../assets/images/hazards/corrosive.png';
import explosivesImage from '../../assets/images/hazards/explosives.png';
import flamablesImage from '../../assets/images/hazards/flamables.png';
import genericDangerImage from '../../assets/images/hazards/genericDanger.png';
import healthDangerImage from '../../assets/images/hazards/healthDanger.png';
import pressureGasImage from '../../assets/images/hazards/pressureGas.png';
import toxicityImage from '../../assets/images/hazards/toxicity.png';

// Others
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}`;

function getHazardImage(name: string) {
    switch (name) {
        case 'agentsOxydants':
            return agentsOxydantsImage;
        case 'ambientDanger':
            return ambientDangerImage;
        case 'corrosive':
            return corrosiveImage;
        case 'explosives':
            return explosivesImage;
        case 'flamables':
            return flamablesImage;
        case 'genericDanger':
            return genericDangerImage;
        case 'healthDanger':
            return healthDangerImage;
        case 'pressureGas':
            return pressureGasImage;
        case 'toxicity':
            return toxicityImage;
    }
}

type PdfDetailsCardProps = {
    /** Unique identification */
    id: string;
    /** Card title */
    title: string;
    /** Informations to be displayed */
    informations: {
        [key: string]: any;
    };
};

const N_COLUMNS = 2;

const maps = ['bleedingMap', 'injuriesMap', 'locationMap', 'irradiationMap', 'rxMap'];

/**
 * Card that displays a set of informations inside a pdf
 * @description
 * This card renders the object information provided
 * as prop in a key, value list.
 * It mimics the beahiour of DetailsCard but inside the Pdf.
 *
 * Since the value could be of any type, it handles the rendering
 * for various data types (string, numbers, dates, links...)
 *
 * @param name
 */
function PdfDetailsCard(props: PdfDetailsCardProps) {
    // Hooks
    const { t } = useTranslation();

    const sliceIntoChunks = (arr: any[], chunkSize = N_COLUMNS) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    // Memos
    const rows = useMemo(() => {
        const rows = sliceIntoChunks(Object.entries(props.informations));
        return rows;
    }, [props.informations]);

    // Methods
    const renderText = (text: any) => <Text style={[styles.text, styles.valueText]}>{text}</Text>;

    const renderHazardRow = (values: string[]) => {
        return (
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                {values.map(v => (
                    <View
                        key={v}
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Image
                            style={{
                                height: 45,
                                width: 45
                            }}
                            src={getHazardImage(v)}
                        />
                        {renderText(t(`${props.id || 't'}:${v}`))}
                    </View>
                ))}
            </View>
        );
    };

    const renderValue = (key: string, value: any) => {
        if (typeof value === 'string' && value.includes('BACKEND'))
            return (
                <Image
                    key={value}
                    src={value.replace('BACKEND', baseURL)}
                    cache={false}
                    style={styles.f3}
                />
            );
        else if (isValidTimestamp(value)) {
            return renderText(formatDate(key, value));
        } else if (isDate(value)) {
            let date = new Date(value);
            if (value.includes('T00:00:00.000'))
                date = new Date(
                    new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60 * 1000
                );
            if (!date.getHours() && !date.getMinutes() && !date.getSeconds())
                return renderText(date.toLocaleDateString());
            return renderText(date.toLocaleString());
        } else if (typeof value === 'string') return renderText(t(`${props.id || 't'}:${value}`));
        else if (typeof value === 'number') return renderText(value);
        else if (typeof value === 'boolean') {
            return renderText(value ? t('details:yes') : t('details:no'));
        } else if (Array.isArray(value) && props.id === 'hazards') {
            return (
                <View>
                    {renderHazardRow(value.slice(0, 3))}
                    {renderHazardRow(value.slice(3, 6))}
                    {renderHazardRow(value.slice(6, 9))}
                </View>
            );
        } else if (Array.isArray(value)) {
            return renderText(value.map(v => t(`${props.id || 't'}:${v}`)).join(', '));
        }
    };

    const getRowStyle = (r: any[]) => {
        if (!!r.find(d => d[0] === 'image')) return styles.imageRow;
        if (!!r.find(d => maps.includes(d[0]))) return styles.mapRow;
        if (!!r.find(d => d[0] === 'terrain')) return styles.terrainRow;
        if (!!r.find(d => d[0] === 'bodyMap')) return styles.canvasRow;
        if (props.id === 'hazards') {
            let height;
            const hazards = r[0][1];
            if (hazards.length > 0) height = 60;
            if (hazards.length > 3) height = 120;
            if (hazards.length > 6) height = 180;
            return { ...styles.hazardsRow, height };
        } else return styles.row;
    };

    const table = useMemo(
        () =>
            rows.map((r, index) => (
                <View
                    key={index}
                    style={[
                        getRowStyle(r),
                        index % 2 === 0
                            ? { backgroundColor: 'rgb(243, 244, 246)' }
                            : { backgroundColor: 'white' },
                        { display: 'flex', flexDirection: 'row' }
                    ]}>
                    {r.map((d, index) => (
                        <View
                            key={index}
                            style={{
                                width: props.id === 'hazards' ? '100%' : '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: '100%',
                                paddingVertical: 2
                            }}>
                            <View style={{ width: 120 }}>
                                <Text style={[styles.text, styles.keyText]}>
                                    {t(`${props.id || 't'}:${d[0]}`)}
                                </Text>
                            </View>
                            <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
                                {renderValue(d[0], d[1])}
                            </View>
                        </View>
                    ))}
                </View>
            )),
        [rows]
    );

    // Render
    return Object.keys(props.informations).length ? (
        <View style={styles.card} wrap={false}>
            <View style={styles.headerRow}>
                <Text style={styles.cardTitle}>{t(`${props.id || 't'}:title`)}</Text>
            </View>
            {table}
        </View>
    ) : (
        <View />
    );
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row'
    },
    card: {
        marginBottom: 10,
        backgroundColor: 'white',
        borderRadius: 3
    },
    cardTitle: {
        fontSize: 11,
        fontWeight: 700,
        //color: '#1F2937',
        color: 'white',
        backgroundColor: 'black',
        paddingHorizontal: 3,
        paddingVertical: 1,
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 10
    },
    valueText: {
        color: '#4B5563'
    },
    keyText: {
        color: '#9CA3AF'
    },
    headerRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 30
    },
    row: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 25
    },
    imageRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 100
    },
    mapRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 150
    },
    terrainRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 120
    },
    canvasRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 120
    },
    hazardsRow: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    f2: { flex: 2 },
    f3: { flex: 3 }
});

export default PdfDetailsCard;
