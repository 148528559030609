// Redux
import { useAppSelector } from '../../hooks/useAppSelector';

// Components
import Notification from './Notification';

/**
 * Notification List
 * @description
 * Renders the current list of notification present
 * in the global store.
 */
function NotificationsList() {
    // Global state
    const notifications = useAppSelector(state => state.status.notifications);

    // Render
    return (
        <div
            aria-live='assertive'
            className='pointer-events-none fixed inset-0 mt-12 flex items-end px-4 py-6 sm:items-start sm:p-6'>
            <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                {notifications.map(notification => (
                    <Notification notification={notification} key={notification.id} />
                ))}
            </div>
        </div>
    );
}

export default NotificationsList;
