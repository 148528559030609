// Pages
import ChatPage from '../pages/ChatPage';
import ChecklistsPage from '../pages/ChecklistsPage';
import ContactsPage from '../pages/ContactsPage';
import DeadBodiesPage from '../pages/DeadBodiesPage';
import DevicesPage from '../pages/DevicesPage';
import GeneralDetailsPage from '../pages/GeneralDetailsPage';
import ImagesPage from '../pages/ImagesPage';
import LicencesPage from '../pages/LicencesPage';
import LocationsPage from '../pages/LocationsPage';
import LogsPage from '../pages/LogsPage';
import MedevacsPage from '../pages/MedevacsPage';
import MethanesPage from '../pages/MethanesPage';
import MissionsPage from '../pages/MissionsPage';
import NineLinesPage from '../pages/NineLinesPage';
import OperatorsPage from '../pages/OperatorsPage';
import OrganizationsPage from '../pages/OrganizationPage';
import PatientDetailsPage from '../pages/PatientDetailsPage';
import PatientsPage from '../pages/PatientsPage';
import TriagePage from '../pages/TriagesPage';
import UsersPage from '../pages/UsersPage';
import VehiclesPage from '../pages/VehiclesPage';

// Types
import { Route } from '../types/entities';

export const routingMap: Route[] = [
    {
        path: '/users',
        page: UsersPage,
        resource: 'users'
    },
    {
        path: '/devices',
        page: DevicesPage,
        resource: 'device'
    },
    {
        path: '/organizations',
        page: OrganizationsPage,
        resource: 'users'
    },
    {
        path: '/vehicles',
        page: VehiclesPage,
        resource: 'vehicles'
    },
    {
        path: '/locations',
        page: LocationsPage,
        resource: 'locations'
    },
    {
        path: '/contacts/:locationId',
        page: ContactsPage,
        resource: 'locations'
    },
    {
        path: '/operators',
        page: OperatorsPage,
        resource: 'operators'
    },
    {
        path: '/missions',
        page: MissionsPage,
        resource: 'missions'
    },
    {
        path: '/licences',
        page: LicencesPage,
        resource: 'licence'
    },
    {
        path: '/logs',
        page: LogsPage,
        resource: 'logs'
    },
    {
        path: '/patients',
        page: PatientsPage,
        resource: 'patients'
    },
    {
        path: '/patients/:code',
        page: PatientDetailsPage,
        resource: 'patients'
    },
    {
        path: '/triages',
        page: TriagePage,
        resource: 'triages'
    },
    {
        path: '/triages/:id',
        page: GeneralDetailsPage,
        resource: 'triages'
    },
    {
        path: '/checklists',
        page: ChecklistsPage,
        resource: 'checklists'
    },
    {
        path: '/checklists/:id',
        page: GeneralDetailsPage,
        resource: 'checklists'
    },
    {
        path: '/medevacs',
        page: MedevacsPage,
        resource: 'medevacs'
    },
    {
        path: '/methanes',
        page: MethanesPage,
        resource: 'methanes'
    },
    {
        path: '/methanes/:id',
        page: GeneralDetailsPage,
        resource: 'methanes'
    },
    {
        path: '/nineLines',
        page: NineLinesPage,
        resource: 'nineLines'
    },
    {
        path: '/nineLines/:id',
        page: GeneralDetailsPage,
        resource: 'nineLines'
    },
    {
        path: '/deadBodies',
        page: DeadBodiesPage,
        resource: 'deadBodies'
    },
    {
        path: '/deadBodies/:id',
        page: GeneralDetailsPage,
        resource: 'deadBodies'
    },
    {
        path: '/images',
        page: ImagesPage,
        resource: 'images'
    },
    {
        path: '/assessments/:id',
        page: GeneralDetailsPage,
        resource: 'assessments'
    },
    {
        path: '/chat',
        page: ChatPage,
        resource: 'chat'
    }
];
