// Store
import authenticationSlice from './authenticationSlice';
import statusSlice from './statusSlice';

// Others
import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
    authentication: authenticationSlice,
    status: statusSlice
});
