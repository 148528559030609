// React
import { Switch, Route } from 'react-router-dom';

// Hooks
import { useAppSelector } from '../hooks/useAppSelector';

// Pages
import LoginPage from '../pages/LoginPage';

// Components
import Banner from '../components/common/Banner';

function UnauthenticatedView() {
    // Global state
    const error = useAppSelector(state => state.status.error);

    // Render
    return (
        <div className='h-screen'>
            <Switch>
                <Route path='/'>
                    <LoginPage />
                </Route>
            </Switch>
            {error && <Banner message={error} />}
        </div>
    );
}

export default UnauthenticatedView;
