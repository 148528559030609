// Types
import { Mission, Notification, Organization } from '../../types/entities';
import { STORAGE_KEY } from '../../types/enums';
import { StatusState } from '../../types/redux';
import { Chat } from '../../types/socketio';

// Others
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: StatusState = {
    notifications: []
};

export const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload;
        },
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications.push(action.payload);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications.filter(n => n.id !== action.payload);
        },
        setSelectedChat: (state, action: PayloadAction<Chat | undefined>) => {
            state.selectedChat = action.payload;
        },
        setSelectedMission: (state, action: PayloadAction<Mission | null>) => {
            if (action.payload)
                localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, action.payload.id.toString());
            else localStorage.removeItem(STORAGE_KEY.SELECTED_MISSION);
            state.selectedMission = action.payload;
        },
        setSelectedOrganization: (state, action: PayloadAction<Organization>) => {
            localStorage.setItem(STORAGE_KEY.SELECTED_ORGANIZATION, action.payload.id.toString());
            state.selectedOrganization = action.payload;
        }
    }
});

export const {
    setError,
    addNotification,
    removeNotification,
    setSelectedChat,
    setSelectedMission,
    setSelectedOrganization
} = statusSlice.actions;

export default statusSlice.reducer;
