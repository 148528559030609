// React
import { useMemo } from 'react';

// Config
import { assessmentColumns } from '../../config/tables/assessment';

// Types
import { Fas, Sas } from '../../types/entities';

// Others
import { Table } from '@edalab/react-tables';
import { useHistory } from 'react-router';

const detailsHeadersFirst = [
    { key: '_c', title: '<C>' },
    { key: 'a', title: 'A' },
    { key: 'b', title: 'B' },
    { key: 'c', title: 'C' },
    { key: 'd', title: 'D' },
    { key: 'e', title: 'E' }
];

const detailsHeadersSecond = [
    { key: 'history', title: 'History' },
    { key: 'objectiveExams', title: 'Objective Exams' },
    { key: 'vitalSigns', title: 'Vital Signs' },
    { key: 'instrumentalExams', title: 'Instrumental Exams' },
    { key: 'labTests', title: 'Lab Tests' }
];

type PatientAssessmentsFragmentProps = {
    /** Patient assessments */
    assessments?: (Fas | Sas)[];
};

/**
 * Ordered list of all patient assessments
 * @param props
 */
function PatientAssessmentsFragment(props: PatientAssessmentsFragmentProps) {
    // Navigation
    const history = useHistory();

    // Memos
    const assessments = useMemo<(Fas | Sas)[] | undefined>(() => {
        return props.assessments
            ?.sort((a, b) => (a.general.endTime > b.general.endTime ? -1 : 1))
            .map(a => {
                switch (a.general.assessmentType) {
                    case 'fas': {
                        const fas: Fas = { ...(a as Fas) };
                        if (fas.c.bleedingMapPaths) {
                            delete fas.c.bleedingMapPaths;
                        }
                        if (fas.e.injuriesMapPaths) {
                            delete fas.e.injuriesMapPaths;
                        }
                        return a;
                    }

                    case 'sas': {
                        const sas: Sas = { ...(a as Sas) };
                        if (sas.history.locationMapPaths) {
                            delete sas.history.locationMapPaths;
                        }
                        if (sas.history.irradiationMapPaths) {
                            delete sas.history.irradiationMapPaths;
                        }
                        if (sas.instrumentalExams.rxMapPaths) {
                            delete sas.instrumentalExams.rxMapPaths;
                        }

                        return sas;
                    }
                }
            });
    }, [props.assessments]);

    // Methods
    const navigate = (assessment: Fas | Sas) => {
        history.push({
            pathname: '/assessments/' + assessment.id,
            state: {
                details: assessment,
                headers:
                    assessment.general.assessmentType === 'fas'
                        ? detailsHeadersFirst
                        : detailsHeadersSecond,
                title: 'Assessment Details'
            }
        });
    };

    // Render
    return (
        <div className='mt-10'>
            <Table<Fas | Sas>
                columns={assessmentColumns}
                data={assessments}
                baseRowClasses='cursor-pointer'
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default PatientAssessmentsFragment;
