// Api
import useGetQuery from '../api/useGetQuery';

// Components
import BaseButton from '../components/common/BaseButton';
import Gallery from '../components/common/Gallery';
import PageHeader from '../components/common/PageHeader';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { GET_AVAILABLE_IMAGES } from '../config/endpoints';

// Types
import { PatientImagesResponse } from '../types/responses';

/**
 * Images page
 * @description
 * Renders the list of all images produced in the
 * currently selected mission
 */
function ImagesPage() {
    // Hooks
    const history = useHistory();

    // Query
    const imagesQuery = useGetQuery<PatientImagesResponse[]>(
        ['availableImages'],
        GET_AVAILABLE_IMAGES
    );

    // Render
    return (
        <div>
            <PageHeader title='images:title' />
            {imagesQuery.data?.map(codeImage => (
                <div
                    key={codeImage.code}
                    className='my-10 overflow-hidden bg-white shadow sm:rounded-lg'>
                    <div className='flex items-center justify-between px-4 py-5 sm:px-6'>
                        <div>
                            <h3 className='text-lg font-medium capitalize leading-6 text-gray-900'>
                                {codeImage.code}
                            </h3>
                        </div>
                        <BaseButton
                            label='general:details'
                            onClick={() => history.replace(`patients/${codeImage.code}`)}
                        />
                    </div>
                    <div className='bg-gray-100 px-6 py-6'>
                        <Gallery
                            basePath={`media/patients/${codeImage.code}`}
                            images={codeImage.images}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ImagesPage;
