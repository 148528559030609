export const BACKEND_PROTOCOL = (window as any)._env_?.BACKEND_PROTOCOL || 'http';
export const BACKEND_PORT = parseInt((window as any)._env_?.BACKEND_PORT) || 3000;
export const SOCKET_PORT = parseInt((window as any)._env_?.SOCKET_PORT) || 3003;
export const REFRESH_INTERVAL = parseInt((window as any)._env_?.REFRESH_INTERVAL) || 60000;

let ip = (window as any)._env_?.BACKEND_IP || 'localhost';
let path: string = '';
const parts = ip.split('/');
if (parts.length > 1) {
    const pathArr = parts.slice(1);
    path = `/${pathArr.join('/')}`;
    ip = parts[0];
}

export const BACKEND_IP = ip;
export const BACKEND_PATH = path;
