// Hooks
import { useAppSelector } from './useAppSelector';

// Types
import { LICENCE, ROLE } from '../types/enums';

/**
 * Used for checking logged user permissions
 * on a specific resource.
 */
export const usePermission = () => {
    // Global state
    const { user, licences } = useAppSelector(state => state.authentication);

    const availableLicences = licences.map(l => l.name);

    const hasPermission = (resource: string) => {
        switch (resource) {
            case 'licence':
                return [ROLE.SUPER_ADMIN].includes(user!.role);
            case 'device':
                return [ROLE.SUPER_ADMIN].includes(user!.role);
            case 'organizations':
                return [ROLE.SUPER_ADMIN].includes(user!.role);
            case 'users':
                return [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER].includes(user!.role);
            case 'operators':
                return [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER].includes(user!.role);
            case 'missions':
                return [ROLE.SUPER_ADMIN, ROLE.ADMIN].includes(user!.role);
            case 'logs':
                return true;
            case 'locations':
                return [ROLE.SUPER_ADMIN].includes(user!.role);
            case 'vehicles':
                return [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER].includes(user!.role);
            case 'patients':
                return availableLicences.includes(LICENCE.PATIENT);
            case 'triages':
                return (
                    availableLicences.includes(LICENCE.SORT) ||
                    availableLicences.includes(LICENCE.SIEVE) ||
                    availableLicences.includes(LICENCE.START) ||
                    availableLicences.includes(LICENCE.NEWS) ||
                    availableLicences.includes(LICENCE.VITALS)
                );
            case 'assessments':
                return (
                    availableLicences.includes(LICENCE.FAS) ||
                    availableLicences.includes(LICENCE.SAS)
                );
            case 'checklists':
                return availableLicences.includes(LICENCE.CHECKLISTS);
            case 'medevacs':
                return availableLicences.includes(LICENCE.MEDEVAC);
            case 'methanes':
                return availableLicences.includes(LICENCE.METHANE);
            case 'nineLines':
                return availableLicences.includes(LICENCE.NINE_LINE);
            case 'deadBodies':
                return availableLicences.includes(LICENCE.DEAD_BODY);
            case 'chat':
                return [ROLE.OPERATOR].includes(user!.role);
            case 'ticketing':
                return [ROLE.OPERATOR].includes(user!.role);
            case 'images':
                return true;
        }
        return false;
    };

    return {
        hasPermission
    };
};
