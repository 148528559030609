// React
import { useMemo } from 'react';

// Api
import useGetQuery from '../../api/useGetQuery';

// Components
import PageHeader from './PageHeader';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { GET_PATIENTS } from '../../config/endpoints';

// Types
import { Patient } from '../../types/entities';

// Others
import { UsersIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type Stat = {
    name: string;
    stat?: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    colorClass: string;
};

type Stats = {
    white: Stat;
    green: Stat;
    yellow: Stat;
    red: Stat;
};

const initialStats: Stats = {
    white: { name: 'home:totalWhites', stat: 'n/a', icon: UsersIcon, colorClass: 'bg-gray-400' },
    green: { name: 'home:totalGreens', stat: 'n/a', icon: UsersIcon, colorClass: 'bg-green-600' },
    yellow: {
        name: 'home:totalYellows',
        stat: 'n/a',
        icon: UsersIcon,
        colorClass: 'bg-yellow-600'
    },
    red: { name: 'home:totalReds', stat: 'n/a', icon: UsersIcon, colorClass: 'bg-red-600' }
};

/**
 * Counter of patient emergency colors
 */
function PatientsCounter() {
    // Hooks
    const history = useHistory();
    const { t } = useTranslation();

    // Query
    const patientsQuery = useGetQuery<Patient[]>(['patients'], GET_PATIENTS);

    // Memo
    const stats = useMemo<Stats>(() => {
        const counters = [0, 0, 0, 0];
        if (patientsQuery.data) {
            const totalPatients = patientsQuery.data.length;
            const stats = initialStats;
            patientsQuery.data.forEach(patient => {
                switch (patient.general.color) {
                    case 'white':
                        counters[0]++;
                        break;
                    case 'green':
                        counters[1]++;
                        break;
                    case 'yellow':
                        counters[2]++;
                        break;
                    case 'red':
                        counters[3]++;
                        break;
                    default:
                        break;
                }
            });
            stats.white.stat = `${counters[0]} / ${totalPatients}`;
            stats.green.stat = `${counters[1]} / ${totalPatients}`;
            stats.yellow.stat = `${counters[2]} / ${totalPatients}`;
            stats.red.stat = `${counters[3]} / ${totalPatients}`;
            return stats;
        }
        return initialStats;
    }, [patientsQuery.data]);

    // Render
    return (
        <div>
            <PageHeader title='home:status' />
            <dl className='mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4'>
                {Object.values(stats).map((item, idx) => (
                    <div
                        key={idx}
                        className='relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6'>
                        <dt>
                            <div className={`absolute rounded-md p-3 ${item.colorClass}`}>
                                <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
                            </div>
                            <p className='ml-16 truncate text-sm font-medium text-gray-500'>
                                {t(item.name)}
                            </p>
                        </dt>
                        <dd className='ml-16 flex items-baseline pb-4 sm:pb-5'>
                            <p className='text-2xl font-semibold text-gray-900'>{item.stat}</p>
                            <div className='absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6'>
                                <div className='text-sm'>
                                    <div
                                        className='cursor-pointer font-medium text-secondary'
                                        onClick={() => history.push('patients')}>
                                        {t('home:viewAll')}
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
}

export default PatientsCounter;
