// Components
import GeneralPdfPage from './GeneralPdfPage';

// Assets
import black from '../../assets/fonts/black.ttf';
import bold from '../../assets/fonts/bold.ttf';
import light from '../../assets/fonts/light.ttf';
import medium from '../../assets/fonts/medium.ttf';
import regular from '../../assets/fonts/regular.ttf';
import thin from '../../assets/fonts/thin.ttf';

// Others
import { Document, Font } from '@react-pdf/renderer';

type GeneralPdfProps = {
    /** Title */
    title: string;
    /** List of headers */
    headers: {
        key: any;
        title: string;
    }[];
    /** Object containing the details to be displayed */
    details: any;
};

Font.register({
    family: 'Roboto',
    fonts: [
        { src: black, fontWeight: 800 },
        { src: bold, fontWeight: 700 },
        { src: medium, fontWeight: 500 },
        { src: regular, fontWeight: 400 },
        { src: light, fontWeight: 300 },
        { src: thin, fontWeight: 100 }
    ]
});

/**
 * Base pdf component
 * @description
 * Declares the font used in all pdfs
 * @param props
 */
function GeneralPdf(props: GeneralPdfProps) {
    // Render
    return (
        <Document>
            <GeneralPdfPage {...props} />
        </Document>
    );
}

export default GeneralPdf;
