// React
import { useMemo } from 'react';

// Config
import { BACKEND_IP, BACKEND_PORT, BACKEND_PROTOCOL } from '../../../config/constants';

// Assets
import image from '../../../assets/images/burn.png';

// Types
import { Mpaws } from '../../../types/entities';

// Others
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

type InjuryT3cProps = {
    /** The last PAWS Triage of the patient*/
    paws: Mpaws;
};

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}/media/canvas/`;

function getLocalizedDate(data: number) {
    const date = new Date(data);
    const formatted = `Day: ${date.toLocaleDateString([
        'en-GB'
    ])} \n Hour: ${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    })}`;
    return formatted;
}

/**
 * Injury T3C
 * @description
 * This is the injury section of the T3C
 *
 * This section will display a dynamic image of
 * the touriquets applied on the user and 5 squares
 * that will describe when the TQ has been applied
 *
 * @param props
 */
function InjuryT3c(props: InjuryT3cProps) {
    // Memos
    const path = useMemo<string>(() => baseURL + props.paws.id + '/woundsMap.png', [props.paws.id]);

    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.capitalLetter}>Injury:</Text>
            </View>
            <View style={styles.container}>
                <View style={styles.columnContainer}>
                    <View style={styles.squaredcontainer}>
                        <Text style={styles.squareText}>TQ: R ARM</Text>
                        <Text style={styles.timeText}>
                            {props.paws.massiveHemorrage &&
                            props.paws.massiveHemorrage.tourniquetMapZones &&
                            props.paws.massiveHemorrage.tourniquetTime
                                ? props.paws.massiveHemorrage.tourniquetMapZones.includes('2')
                                    ? getLocalizedDate(props.paws.massiveHemorrage.tourniquetTime)
                                    : ' None'
                                : ' None'}
                        </Text>
                    </View>
                    <View style={styles.squaredcontainer}>
                        <Text style={styles.squareText}>TQ: BODY</Text>
                        <Text style={styles.timeText}>
                            {props.paws.massiveHemorrage &&
                            props.paws.massiveHemorrage.tourniquetMapZones &&
                            props.paws.massiveHemorrage.tourniquetTime
                                ? props.paws.massiveHemorrage.tourniquetMapZones.includes('1')
                                    ? getLocalizedDate(props.paws.massiveHemorrage.tourniquetTime)
                                    : ' None'
                                : ' None'}
                        </Text>
                    </View>
                    <View style={styles.squaredcontainer}>
                        <Text style={styles.squareText}>TQ: R LEG</Text>
                        <Text style={styles.timeText}>
                            {props.paws.massiveHemorrage &&
                            props.paws.massiveHemorrage.tourniquetMapZones &&
                            props.paws.massiveHemorrage.tourniquetTime
                                ? props.paws.massiveHemorrage.tourniquetMapZones.includes('4')
                                    ? getLocalizedDate(props.paws.massiveHemorrage.tourniquetTime)
                                    : ' None'
                                : ' None'}
                        </Text>
                    </View>
                </View>
                <View style={styles.customColumnContainer}>
                    <Image src={image} style={styles.img} />
                    <Image src={path} style={styles.img} />
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.squaredcontainer}>
                        <Text style={styles.squareText}>TQ: L ARM</Text>
                        <Text style={styles.timeText}>
                            {props.paws.massiveHemorrage &&
                            props.paws.massiveHemorrage.tourniquetMapZones
                                ? props.paws.massiveHemorrage.tourniquetMapZones.includes('0') &&
                                  props.paws.massiveHemorrage.tourniquetTime
                                    ? getLocalizedDate(props.paws.massiveHemorrage.tourniquetTime)
                                    : ' None'
                                : ' None'}
                        </Text>
                    </View>
                    <View style={styles.squaredcontainer}>
                        <Text style={styles.squareText}>TQ: L LEG</Text>
                        <Text style={styles.timeText}>
                            {props.paws.massiveHemorrage &&
                            props.paws.massiveHemorrage.tourniquetMapZones
                                ? props.paws.massiveHemorrage.tourniquetMapZones.includes('3') &&
                                  props.paws.massiveHemorrage.tourniquetTime
                                    ? getLocalizedDate(props.paws.massiveHemorrage.tourniquetTime)
                                    : ' None'
                                : ' None'}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        paddingBottom: 8,
        borderBottom: 1.5,
        borderBottomColor: '#1F2937',
        flexDirection: 'column',
        height: 295
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    columnContainer: {
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    squaredcontainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: 1,
        borderColor: 'black',
        height: 50,
        width: 100
    },
    capitalLetter: {
        marginTop: 5,
        fontWeight: 700,
        fontSize: 20,
        color: '#1F2937'
    },
    text: {
        alignSelf: 'flex-end',
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    squareText: {
        alignSelf: 'flex-start',
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginLeft: 5
    },
    timeText: {
        alignSelf: 'flex-start',
        color: '#1F2937',
        marginLeft: 5,
        fontSize: 10,
        fontWeight: 400
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
    },
    customColumnContainer: {
        justifyContent: 'space-between',
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 2,
        position: 'relative',
        height: 250
    },
    img: {
        height: 250,
        width: 210,
        position: 'absolute'
    }
});
export default InjuryT3c;
