// Components
import { useTranslation } from 'react-i18next';
import { Color } from '../../types/entities';
import LocaleText from '../common/LocaleText';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

type PatientDetailsTabBarProps = {
    /** Header title */
    title: string;
    /** Selected tab value */
    selectedTab: number;
    /** List of available tabs */
    tabs: string[];
    /** Current patient color */
    color: Color;
    /** Component placed under the title */
    children?: React.ReactNode;
    /** Called when the selected tab changes */
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Tab bar used inside PatientDetails
 * @param props
 */
function PatientDetailsTabBar(props: PatientDetailsTabBarProps) {
    // Hooks
    const { t } = useTranslation();

    // Methods
    const renderColor = () => {
        switch (props.color) {
            case 'green':
                return <div className='mr-3 h-4 w-4 rounded-xl bg-green-400' />;
            case 'yellow':
                return <div className='mr-3 h-4 w-4 rounded-xl bg-yellow-400' />;
            case 'red':
                return <div className='mr-3 h-4 w-4 rounded-xl bg-red-400' />;
            case 'black':
                return <div className='mr-3 h-4 w-4 rounded-xl bg-black' />;
            default:
                return <div className='mr-3 h-4 w-4 rounded-xl border border-gray-500 bg-white' />;
        }
    };

    // Render
    return (
        <div className='relative border-b border-white pb-5 sm:pb-0'>
            <div className='md:flex md:items-center md:justify-between'>
                <div className='flex items-center'>
                    {renderColor()}
                    <LocaleText className='text-2xl font-bold text-gray-900' text={props.title} />
                </div>
                <div className='flex md:absolute md:right-0 md:mt-0'>{props.children}</div>
            </div>
            <div className='mt-6'>
                <div className='sm:hidden'>
                    <label htmlFor='current-tab' className='sr-only'>
                        Select a tab
                    </label>
                    <select
                        id='current-tab'
                        name='current-tab'
                        className='focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-secondary focus:ring-secondary sm:text-sm'
                        onChange={e => props.setSelectedTab(parseInt(e.target.value))}
                        defaultValue={props.selectedTab}>
                        {props.tabs.map((tab, index) => (
                            <option key={tab} value={index}>
                                {t(tab)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='hidden sm:block'>
                    <nav className='-mb-px flex space-x-8'>
                        {props.tabs.map((tab, index) => (
                            <div
                                key={index}
                                onClick={() => props.setSelectedTab(index)}
                                className={classNames(
                                    props.selectedTab === index
                                        ? 'border-secondary text-secondary'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                )}
                                aria-current={props.selectedTab === index ? 'page' : undefined}>
                                {t(tab)}
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default PatientDetailsTabBar;
