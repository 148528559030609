import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Mpaws } from '../../../types/entities';

type RescuerIdT3cProps = {
    /** The last triage of the patient */
    paws: Mpaws;
};

/**
 * Rescuer Id T3C
 * @description
 * This is the section Rescuer Id of the T3C
 *
 * This section contains ifos about the Rescuer that rescued
 * the patient on his first Triage
 * @param props
 * @returns
 */
function RescuerIdT3c(props: RescuerIdT3cProps) {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.text}>First Responder:</Text>
            </View>
            <View style={styles.wrapper}>
                <Text style={styles.text}>RESCUER ID:</Text>
                <View style={styles.underlineName}>
                    <Text style={styles.text}>{props.paws.general.operatorId}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        paddingBottom: 8,
        flexDirection: 'column'
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    underlineName: {
        marginRight: 5,
        flexGrow: 4,
        borderBottom: 1,
        borderBottomColor: '#1F2937',
        alignItems: 'center'
    },
    wrapper: {
        marginLeft: 5,
        marginTop: 5,
        alignContent: 'flex-end',
        flexDirection: 'row',
        marginBottom: 5
    }
});

export default RescuerIdT3c;
