// React
import { useState } from 'react';

// Config
import { BACKEND_PROTOCOL, BACKEND_IP, BACKEND_PORT } from '../../config/constants';

// Utils
import { formatDate, isDate, isValidTimestamp } from '../../utils/dates';

// Assets
import agentsOxydantsImage from '../../assets/images/hazards/agentsOxydants.png';
import ambientDangerImage from '../../assets/images/hazards/ambientDanger.png';
import corrosiveImage from '../../assets/images/hazards/corrosive.png';
import explosivesImage from '../../assets/images/hazards/explosives.png';
import flamablesImage from '../../assets/images/hazards/flamables.png';
import genericDangerImage from '../../assets/images/hazards/genericDanger.png';
import healthDangerImage from '../../assets/images/hazards/healthDanger.png';
import pressureGasImage from '../../assets/images/hazards/pressureGas.png';
import toxicityImage from '../../assets/images/hazards/toxicity.png';

// Others
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}`;

type DetailsCardProps = {
    /** Unique identification */
    id: string;
    /** Card title */
    title: string;
    /** Informations to be displayed */
    informations: {
        [key: string]: any;
    };
};

function getHazardImage(name: string) {
    switch (name) {
        case 'agentsOxydants':
            return agentsOxydantsImage;
        case 'ambientDanger':
            return ambientDangerImage;
        case 'corrosive':
            return corrosiveImage;
        case 'explosives':
            return explosivesImage;
        case 'flamables':
            return flamablesImage;
        case 'genericDanger':
            return genericDangerImage;
        case 'healthDanger':
            return healthDangerImage;
        case 'pressureGas':
            return pressureGasImage;
        case 'toxicity':
            return toxicityImage;
    }
}

/**
 * Card that displays a set of informations
 * @description
 * This card renders the object information provided
 * as prop in a key, value list.
 *
 * Since the value could be of any type, it handles the rendering
 * for various data types (string, numbers, dates, links...)
 *
 * The card can be expanded or collapsed using a top right
 * placed button.
 * @param name
 */
function DetailsCard(props: DetailsCardProps) {
    // State
    const [showItems, setShowItems] = useState(true);

    // Hooks
    const { t } = useTranslation();

    // Methods
    const renderValue = (key: string, value: any) => {
        if (typeof value === 'string' && value.includes('BACKEND'))
            return (
                <img
                    src={value.replace('BACKEND', baseURL)}
                    alt='image'
                    className='w-96 cursor-pointer'
                    onError={(e: any) => (e.target.style.display = 'none')}
                />
            );
        if (isDate(value)) {
            let date = new Date(value);
            if (value.includes('T00:00:00.000'))
                date = new Date(
                    new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60 * 1000
                );

            if (!date.getHours() && !date.getMinutes() && !date.getSeconds())
                return date.toLocaleDateString();
            return date.toLocaleString();
        }
        if (isValidTimestamp(value)) return formatDate(key, value);
        if (typeof value === 'string') return t(`${props.id || 't'}:${value}`);
        if (typeof value === 'number') return value;
        if (typeof value === 'boolean') {
            return value ? (
                <CheckIcon className='mt-1 h-4 w-4' />
            ) : (
                <XIcon className='mt-1 h-4 w-4' />
            );
        }
    };

    const renderInformations = () => {
        let nodes: JSX.Element[] = [];
        let index = 0;
        for (const [key, value] of Object.entries(props.informations)) {
            if (typeof value !== 'object' && key !== 'isImage')
                nodes.push(
                    <div
                        key={index}
                        className={
                            'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ' +
                            (index % 2 === 0 ? 'bg-gray-100' : 'bg-white')
                        }>
                        <dt className='text-sm font-medium text-gray-500'>
                            {t(`${props.id || 't'}:${key}`)}
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                            {renderValue(key, value)}
                        </dd>
                    </div>
                );
            else if (Array.isArray(value) && props.id === 'hazards')
                nodes.push(
                    <div
                        key={index}
                        className={
                            'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ' +
                            (index % 2 === 0 ? 'bg-gray-100' : 'bg-white')
                        }>
                        <dt className='text-sm font-medium text-gray-500'>
                            {t(`${props.id || 't'}:${key}`)}
                        </dt>
                        <dd className='mt-1 grid grid-cols-2 gap-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:grid-cols-3'>
                            {value.map(v => (
                                <div key={v} className='flex flex-col items-center'>
                                    <img src={getHazardImage(v)} width={80} height={80} />
                                    <p>{t(`${props.id || 't'}:${v}`)}</p>
                                </div>
                            ))}
                        </dd>
                    </div>
                );
            else if (Array.isArray(value))
                nodes.push(
                    <div
                        key={index}
                        className={
                            'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ' +
                            (index % 2 === 0 ? 'bg-gray-100' : 'bg-white')
                        }>
                        <dt className='text-sm font-medium text-gray-500'>
                            {t(`${props.id || 't'}:${key}`)}
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                            {value.map(v => (
                                <p key={v}>{t(`${props.id || 't'}:${v}`)}</p>
                            ))}
                        </dd>
                    </div>
                );
            index++;
        }
        return nodes;
    };

    // Render
    return (
        <div id={props.id} className='my-10 overflow-hidden bg-white shadow sm:rounded-lg'>
            <div className='flex items-center justify-between px-4 py-5 sm:px-6'>
                <div>
                    <h3 className='text-lg font-medium capitalize leading-6 text-gray-900'>
                        {t(`${props.id || 't'}:title`)}
                    </h3>
                </div>
                <div
                    onClick={() => setShowItems(!showItems)}
                    className='mr-3 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-400 group-hover:text-gray-300'>
                    {showItems ? (
                        <ChevronUpIcon aria-hidden='true' />
                    ) : (
                        <ChevronDownIcon aria-hidden='true' />
                    )}
                </div>
            </div>
            {showItems && (
                <div className='border-t border-gray-200'>
                    <dl className='sm:divide-y sm:divide-gray-200'>{renderInformations()}</dl>
                </div>
            )}
        </div>
    );
}

export default DetailsCard;
