// Themes
import baseTheme from '../../themes/baseTheme';

// Others
import { useTranslation } from 'react-i18next';
import { ColorRing } from 'react-loader-spinner';

export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

type BaseButtonProps = {
    /** Button label */
    label: string;
    /** Override default class names */
    className?: string;
    /** Button type. It defines the basic styling */
    type?: ButtonType;
    /** HTML button type */
    inputType?: 'submit' | 'button';
    /** Shows a spinner when true inside the button */
    isLoading?: boolean;
    /** If true removes all default styles from the class list */
    overrideAll?: boolean;
    /** Called when the component is clicked */
    onClick?: () => void;
    /** Component icon to be displayed in the button */
    icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

/**
 * Basic application button
 * @description
 * Basic button that provides common styling based on its type
 * @param props
 */
function BaseButton(props: BaseButtonProps) {
    // Translation
    const { t } = useTranslation();

    // Methods
    const getButtonStyle = () => {
        switch (props.type) {
            case ButtonType.SECONDARY:
                return 'bg-white text-gray-700 hover:bg-gray-100';
            default:
                return 'bg-secondary hover:bg-primary text-white border-transparent';
        }
    };

    const getIconStyle = () => {
        switch (props.type) {
            case ButtonType.SECONDARY:
                return 'text-gray-700 hover:bg-gray-100';
            default:
                return 'text-white';
        }
    };

    const getClassName = () => {
        if (props.overrideAll) return props.className;
        else
            return `focus:outline-none flex w-full justify-center  
        rounded-md border px-4 py-1.5 text-base font-medium shadow-sm
        sm:w-auto sm:text-sm ${getButtonStyle()} ${props.className}`;
    };

    const onClick = () => {
        if (!props.isLoading && props.onClick) props.onClick();
    };

    // Render
    return (
        <button
            type={props.inputType ? props.inputType : 'button'}
            className={getClassName()}
            onClick={onClick}>
            {props.icon && (
                <props.icon
                    className={`mr-1 h-5 w-5 flex-shrink-0 ${getIconStyle()}`}
                    aria-hidden='true'
                />
            )}
            <div>{t(props.label)}</div>
            {props.isLoading && (
                <ColorRing
                    wrapperClass='flex-shrink-0 h-5 w-5 -mr-3'
                    colors={
                        props.type === ButtonType.PRIMARY
                            ? ['white', 'white', 'white', 'white', 'white']
                            : [
                                  baseTheme.secondary,
                                  baseTheme.secondary,
                                  baseTheme.secondary,
                                  baseTheme.secondary,
                                  baseTheme.secondary
                              ]
                    }
                />
            )}
        </button>
    );
}

export default BaseButton;
