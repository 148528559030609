// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { DELETE_CHECKLISTS, GET_CHECKLISTS, GET_VEHICLES } from '../config/endpoints';
import { checklistColumns } from '../config/tables/checklists';

// Types
import { Checklist, Vehicle } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

const detailsHeadersParking = [
    { key: 'status', title: 'Status', subtitle: 'Detailed informations' },
    {
        key: 'medicalSupplies',
        title: 'Medical Supplies',
        subtitle: 'Detailed informations'
    },
    { key: 'ppe', title: 'PPE', subtitle: 'Detailed informations' },
    { key: 'isolator', title: 'Isolator', subtitle: 'Detailed informations' },
    { key: 'others', title: 'Others', subtitle: 'Detailed informations' }
];

const detailsHeadersPodel = [
    { key: 'status', title: 'Status', subtitle: 'Detailed informations' },
    {
        key: 'medicalSupplies',
        title: 'Medical Supplies',
        subtitle: 'Detailed informations'
    },
    { key: 'others', title: 'Others', subtitle: 'Detailed informations' }
];

const detailsHeadersDecon = [
    { key: 'status', title: 'Status', subtitle: 'Detailed informations' },
    { key: 'options', title: 'Options', subtitle: 'Detailed informations' },
    { key: 'others', title: 'Others', subtitle: 'Detailed informations' }
];

const detailsHeadersShift = [
    { key: 'status', title: 'Status', subtitle: 'Detailed informations' },
    { key: 'others', title: 'Others', subtitle: 'Detailed informations' }
];

/**
 * Checklist page
 * @description
 * Ordered and filterable list of checklists.
 * The available checklists are parking, podel, decon and
 * end of shifts.
 * Only checklists relative to the current selection mission
 * will be shown.
 *
 * The user can access a checklist details or remove it.
 */
function ChecklistsPage() {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(checklistColumns, 0, [
        { colKey: 'general.type', locKey: 'general' }
    ]);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const vehiclesQuery = useGetQuery<Vehicle[]>(['vehicles'], GET_VEHICLES);

    const checklistsQuery = useGetQuery<Checklist[]>(['checklists'], GET_CHECKLISTS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_CHECKLISTS, {
        invalidateQueries: ['checklists']
    });

    // Memos
    const checklists = useMemo<Checklist[] | undefined>(() => {
        if (!checklistsQuery.data) return;
        let checklists = checklistsQuery.data
            .map(c => {
                const vehicle = vehiclesQuery.data?.find(l => l.id === c.general.vehicleId);
                return {
                    ...c,
                    general: {
                        ...c.general,
                        vehicleId: vehicle?.identifier || '-'
                    }
                };
            })
            .sort((a, b) => (a.general.createdAt > b.general.createdAt ? -1 : 1));

        tableView.current?.changePage(1);

        return applyFilter(checklists);
    }, [checklistsQuery.data, vehiclesQuery, filter]);

    const getHeaders = (type: 'Parking' | 'Podel' | 'Decon' | 'Shift') => {
        switch (type) {
            case 'Parking':
                return detailsHeadersParking;
            case 'Podel':
                return detailsHeadersPodel;
            case 'Decon':
                return detailsHeadersDecon;
            case 'Shift':
                return detailsHeadersShift;
        }
    };

    const navigate = (checklist: Checklist) => {
        history.push({
            pathname: '/checklists/' + checklist.id,
            state: {
                details: checklist,
                headers: getHeaders(checklist.general.type),
                title: 'checklistDetails'
            }
        });
    };

    const deleteChecklists = () => {
        if (toBeDeleted.length > 0)
            deleteMutation
                .mutateAsync({ ids: toBeDeleted })
                .then(() => {
                    setToBeDeleted([]);
                    setDeleteOpen(false);
                })
                .catch(() => {});
    };

    const onButtonPress = () => {
        if (checklistsQuery.data) {
            setToBeDeleted(checklistsQuery.data.map(m => m.id));
            setDeleteOpen(true);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => setDeleteOpen(false)}
                    onConfirm={() => deleteChecklists()}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='checklists:title'
                buttons={['general:deleteAll']}
                onButtonPress={onButtonPress}
            />
            <TableFilter columns={checklistColumns} filter={filter} setFilter={setFilter} />
            <Table<Checklist>
                ref={tableView}
                columns={checklistColumns}
                baseRowClasses='cursor-pointer'
                onCellPress={c => navigate(c)}
                rowOptions={
                    !!checklists
                        ? [
                              checklist => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([checklist.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                data={checklists}
            />
        </div>
    );
}

export default ChecklistsPage;
