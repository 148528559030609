// Api
import { baseURL } from '../api/axios';

// Types
import { UrlParam } from '../types/structs';

export const parseUrl = (url: string, params: UrlParam[] = []) => {
    let parsedUrl = url;
    const queryParams: UrlParam[] = [];

    params.forEach(p => {
        if (parsedUrl.includes(`:${p.key}`)) parsedUrl = parsedUrl.replace(`:${p.key}`, p.value);
        else queryParams.push(p);
    });

    queryParams.forEach((q, i) => {
        parsedUrl += i === 0 ? '?' : '&';
        parsedUrl += `${q.key}=${q.value}`;
    });

    return parsedUrl;
};

export const buildUrl = (url: string, params: UrlParam[] = []) =>
    baseURL.slice(-1) === '/'
        ? `${baseURL}${parseUrl(url, params)}`
        : `${baseURL}/${parseUrl(url, params)}`;

export const concurrentPromises = (promises: Promise<any>[]) => {
    return Promise.all(
        promises.map(p =>
            p.catch(() => {
                return null;
            })
        )
    );
};
