// Assets
import image from '../../../assets/images/cross.png';

// Types
import { Mpaws } from '../../../types/entities';

// Others
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';

type MechanismOfInjuryT3cProps = {
    /** The last triage of the patient */
    paws: Mpaws;
};
/**
 * Mechanism of Injury T3C
 * @description
 * Section of the T3C that describes the mechanism of injury
 *
 * This section will contain a collection of checkboxes that
 * will describe how the patient got injured
 *
 * @param props
 */
function MechanismOfInjuryT3c(props: MechanismOfInjuryT3cProps) {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.capitalLetter}>Mechanism of injury:</Text>
            </View>
            <View style={styles.secondRowContainer}>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#ff00ff') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Artillery</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#8000ff') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Blunt</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapBurnPercentage && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Burn</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#0000ff') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Fall</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#ffff00') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Grenade</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#00ff00') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>GSW</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#006400') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>IED</Text>
                </View>
            </View>
            <View style={styles.thirdRowContainer}>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#ff8000') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Landmine</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#00ffff') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>MVC</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#ff0000') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>RPG</Text>
                </View>
                <View style={styles.couple}>
                    <View style={styles.squares}>
                        {props.paws.paws!.woundsMapPaths &&
                            props.paws.paws!.woundsMapPaths.includes('#969696') && (
                                <Image src={image} style={styles.img} />
                            )}
                    </View>
                    <Text style={styles.squareText}>Other:</Text>
                </View>
                <View style={styles.underline}></View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        borderBottom: 1.5,
        borderBottomColor: '#1F2937',
        flexDirection: 'column'
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: 5,
        marginTop: 5
    },
    capitalLetter: {
        fontWeight: 700,
        fontSize: 20,
        color: '#1F2937'
    },
    titleText: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937'
    },
    text: {
        alignSelf: 'flex-end',
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    couple: {
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center'
    },
    secondRowContainer: {
        marginTop: 3,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        marginLeft: 10
    },
    thirdRowContainer: {
        marginTop: 5,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10
    },
    squareText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2,
        marginRight: 5
    },
    squares: {
        border: 1,
        width: 10,
        height: 10,
        borderColor: '#1F2937'
    },
    underline: {
        flex: 3,
        borderBottom: 1,
        borderBottomColor: '#1F2937',
        alignSelf: 'flex-end'
    },
    img: {
        height: 9,
        width: 8
    }
});

export default MechanismOfInjuryT3c;
