// Locale
import { useTranslation } from 'react-i18next';

type LocaleTextProps = {
    /** Locale text key */
    text: string;
    /** Override default styles */
    className?: string;
    /** Avoid translating text key */
    avoidTranslation?: boolean;
};

/**
 * Translate text using i18n
 * @param props
 */
function LocaleText(props: LocaleTextProps) {
    // Hooks
    const { t } = useTranslation();

    // Render
    return props.avoidTranslation ? (
        <div className={props.className}> {props.text}</div>
    ) : (
        <div className={props.className}> {t(props.text)}</div>
    );
}

export default LocaleText;
