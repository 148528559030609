export const isDate = (dateString: string) => {
    const regExp = new RegExp(
        '^((-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?Z?(\\+[0-9]{4})?)$'
    );
    return regExp.test(dateString);
};

export const getDateFromString = (dateString: any) => {
    return new Date(dateString);
};

export const isValidTimestamp = (timestamp: any) => {
    if (typeof timestamp === 'number')
        return timestamp.toString().length > 11 && new Date(timestamp).getTime() > 0;
    return false;
};

export const formatDate = (key: string, timestamp: any) => {
    if (
        key === 'createdAt' ||
        key === 'startTime' ||
        key === 'endTime' ||
        key === 'latestColorUpdate'
    )
        return new Date(timestamp).toLocaleString();
    else if (key.toLowerCase().includes('time'))
        return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    else return new Date(timestamp).toLocaleDateString();
};
