// React
import { useEffect, useMemo } from 'react';

// Hooks
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';

// Store
import { setSelectedChat } from '../store/slices/statusSlice';

// Api
import useGetQuery from '../api/useGetQuery';

// Components
import ChatDetails from '../components/chat/ChatDetails';
import ChatsList from '../components/chat/ChatsList';

// Config
import { GET_CHATS } from '../config/endpoints';

// Types
import { Chat } from '../types/socketio';

/**
 * Chat page
 * @description
 * This page allows central users to interact with operator on
 * the field.
 *
 * When an operator asks helps from the mobile app, the connected user
 * with less active chat will be in charge of the response. A notification
 * will appear on the dashboard indicating that a new chat has been
 * opened.
 *
 * An user can not open a chat with an operator himself, it only serve
 * incoming requests. It can hovewer create a direct line between two
 * field operators using the open direct button.
 *
 * Only roles under manager can access this page.
 */
function ChatPage() {
    // Hooks
    const dispatch = useAppDispatch();

    // Global state
    const _selectedChat = useAppSelector(state => state.status.selectedChat);

    // Api
    const chatsQuery = useGetQuery<Chat[]>(['chats'], GET_CHATS);

    // Memos
    const selectedChat = useMemo(() => {
        if (chatsQuery.data) return chatsQuery.data.find(chat => chat.id === _selectedChat?.id);
    }, [chatsQuery.data, _selectedChat]);

    const chats = useMemo(() => {
        if (chatsQuery.data) {
            return chatsQuery.data.sort((a, b) => {
                const latestAMessage = a.messages[a.messages.length - 1];
                const latestBMessage = b.messages[b.messages.length - 1];
                if (!latestAMessage || !latestBMessage) return 0;
                if (latestAMessage.timestamp > latestBMessage.timestamp) return -1;
                return 1;
            });
        }
    }, [chatsQuery.data]);

    // Effects
    useEffect(() => {
        return () => {
            dispatch(setSelectedChat(undefined));
        };
    }, []);

    // Render
    return (
        <div className='relative flex h-full flex-col overflow-hidden pb-20'>
            <div className='min-h-0 flex flex-1 overflow-hidden rounded-lg bg-gray-100'>
                <div className='flex min-w-0 flex-1 border-t border-gray-200'>
                    <ChatDetails chat={selectedChat} />
                    <ChatsList chats={chats} />
                </div>
            </div>
        </div>
    );
}

export default ChatPage;
