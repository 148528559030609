// React
import { ReactNode } from 'react';

// Utils
import { isDate, isValidTimestamp } from '../../utils/dates';

// Types
import { Color } from '../../types/entities';

// Others
import { CheckIcon, LocationMarkerIcon, XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

type CellText = string | number | Date;

const cleanText = (text: CellText) => {
    if (!text && text !== 0) return '-';
    else if (isValidTimestamp(text)) return new Date(text).toLocaleString();
    else if (typeof text === 'number') return text.toString();
    else if (typeof text === 'string' && isDate(text)) {
        const date = new Date(text);
        return `${date.toLocaleString()}`;
    } else return text;
};

/**
 * Basic single cell
 * @param props
 */
export function SingleTableCell(props: { text?: CellText }) {
    // Methods
    const isNotDefined = () => {
        if (!props.text && props.text !== 0) return true;
        if (props.text === 'none') return true;
        return false;
    };

    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
            {isNotDefined() ? '-' : cleanText(props.text!)}
        </div>
    );
}

/**
 * Cell that traslates the provided text
 * @param props
 */
export function LocalizedTableCell(props: { text?: string }) {
    // Hooks
    const { t } = useTranslation();

    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
            {!props.text ? '-' : t(props.text)}
        </div>
    );
}

/**
 * Cell that renders a boolean value
 * @param props
 */
export function CheckboxCell(props: { checked: boolean; onChange: (value: boolean) => void }) {
    // Methods
    const onChange: React.ChangeEventHandler<HTMLInputElement> = () => {
        props.onChange(!props.checked);
    };

    // Render
    return (
        <div className='ml-3 flex w-5 justify-center'>
            <input type='checkbox' checked={props.checked} onChange={onChange} />
        </div>
    );
}

/**
 * Cell that contains a pin with a link
 * to google maps
 * @param props
 */
export function LocationTableCell(props: { latitude?: number; longitude?: number }) {
    // Methods
    const onClick = () => {
        if (props.latitude && props.longitude)
            window.open(
                `http://www.google.com/maps/place/${props.latitude},${props.longitude}`,
                '_blank'
            );
    };

    // Render
    return (
        <div
            className='flex space-x-2 whitespace-nowrap px-4 py-4 text-sm text-gray-500'
            onClick={onClick}>
            {props.latitude && props.longitude ? (
                <LocationMarkerIcon className='h-5 w-5 text-gray-400 hover:text-secondary' />
            ) : (
                '-'
            )}
        </div>
    );
}

/**
 * Cell used during loading
 * @param props
 */
export function SkeletonTableCell(props: { children: ReactNode }) {
    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>{props.children}</div>
    );
}

/**
 * Cell for boolean values
 * @param props
 */
export function BooleanTableCell(props: { value?: boolean; className?: string }) {
    // Methods
    const renderValue = () => {
        if (props.value === undefined) return '-';
        else if (!props.value) return <XIcon className='mt-1 h-4 w-4' />;
        else return <CheckIcon className='mt-1 h-4 w-4' />;
    };

    // Render
    return (
        <div
            className={`flex flex-col justify-center whitespace-nowrap px-4 ${
                props.className || ''
            }`}>
            {renderValue()}
        </div>
    );
}

/**
 * Cell tha displays content on two rows
 * @param props
 */
export function DoubleTableCell(props: { first?: CellText; second?: CellText }) {
    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4'>
            <div className='text-sm text-gray-900'>
                {props.first ? cleanText(props.first) : '-'}
            </div>
            <div className='text-sm text-gray-500'>
                {props.second ? cleanText(props.second) : '-'}
            </div>
        </div>
    );
}

type TagTableCellProps = {
    text?: string;
    color?: 'white' | 'green' | 'yellow' | 'red';
};

/**
 * Cell with a text inside a colored badge/tag
 */
export function TagTableCell(props: TagTableCellProps) {
    // Hooks
    const { t } = useTranslation();

    // Methods
    const getTagStyle = () => {
        switch (props.color) {
            case 'green':
                return 'bg-green-200 text-green-800';
            case 'yellow':
                return 'bg-yellow-200 text-yellow-800';
            case 'red':
                return 'bg-red-200 text-red-800';
            default:
                return 'border border-gray-300';
        }
    };

    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4'>
            <span
                className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getTagStyle()}`}>
                {!props.text ? '-' : t(props.text)}
            </span>
        </div>
    );
}

type ColorTableCellProps = {
    text?: CellText;
    color?: Color;
};

/**
 * Cell used to indicates one of the emergency colors
 * @param props
 */
export function ColorTableCell(props: ColorTableCellProps) {
    // Hooks
    const { t } = useTranslation();

    // Methods
    const getTagStyle = () => {
        switch (props.color) {
            case 'green':
                return 'bg-green-200 text-green-800';
            case 'yellow':
                return 'bg-yellow-200 text-yellow-800';
            case 'red':
                return 'bg-red-200 text-red-800';
            case 'black':
                return 'bg-black text-white';
            default:
                return 'border border-gray-300';
        }
    };

    // Render
    return (
        <div className='whitespace-nowrap px-4 py-4'>
            <span
                className={`inline-flex rounded-full px-3 py-2 text-lg font-semibold uppercase leading-5 ${getTagStyle()}`}>
                {props.text ? t(`general:${props.color?.toLowerCase()}`) : '-'}
            </span>
        </div>
    );
}

/**
 * Cell used in the header of all tables
 * @param props
 */
export function HeaderTableCell(props: { name: string }) {
    // Hooks
    const { t } = useTranslation();

    // Render
    return (
        <div className='py-2 pl-1 text-left text-xs font-medium uppercase tracking-wider text-gray-500 md:py-3'>
            {t(props.name)}
        </div>
    );
}

/**
 * Pagination cell for changing table page
 * @param props
 */
export function PaginationCell(props: { text: string; isCurrent?: boolean }) {
    // Render
    return (
        <div
            className={`cursor-pointer items-center border bg-white px-4
        py-2 text-sm font-medium  hover:bg-gray-50 
            ${
                props.isCurrent
                    ? 'z-10 border-secondary text-secondary'
                    : 'border-gray-200 text-gray-500'
            } `}>
            {props.text}
        </div>
    );
}

/**
 * Cell that contains pagination icon controls
 * @param props
 */
export function PaginationIconCell(props: { children: ReactNode }) {
    // Render
    return (
        <div
            className={`cursor-pointer items-center border border-gray-200 bg-white
        px-2 py-2 text-sm  font-medium text-gray-500 hover:bg-gray-50`}>
            {props.children}
        </div>
    );
}
