// React
import { useState } from 'react';

// Hooks
import { useAppDispatch } from './useAppDispatch';

// Store
import { setError } from '../store/slices/statusSlice';

// Config
import { ticketingKey } from '../config/ticketing';

// Types
import { User } from '../types/entities';

// Others
import axios from 'axios';

/**
 * Used to generate a valid link that can be used
 * to navigate to the ticketing site.
 * @description
 * The ticketing site has its own authentication that is separate
 * from Maxxie.
 *
 * By generating an authenticated link via the provided api,
 * users can however skip the login page and access the
 * ticketing dashboard directly.
 */
function useTicketingLink() {
    // Hooks
    const dispatch = useAppDispatch();

    // State
    const [isLoading, setIsLoading] = useState(false);

    // Methods
    const getTicketingLink = () => {
        const ticketValid = localStorage.getItem('@ticketingLink') ? true : false;
        if (ticketValid)
            window.open(
                'https://appinmm.tumminellospina.it?ticket_signature=' +
                    localStorage.getItem('@ticketingToken'),
                '_blank',
                'noopener,noreferrer'
            );
        else {
            const userString = localStorage.getItem('@user');

            if (!userString) return;
            const user: User = JSON.parse(userString);
            const payload = {
                app_key: ticketingKey,
                userid: user.id,
                user_name: user.username,
                user_email: user.email
            };

            setIsLoading(true);
            axios
                .post('https://appinmm.tumminellospina.it/wp-json/ticket-hub/v1/tokens', payload)
                .then(res => {
                    localStorage.setItem('@ticketingToken', res.data.token);
                    window.open(
                        'https://appinmm.tumminellospina.it?ticket_signature=' +
                            localStorage.getItem('@ticketingToken'),
                        '_blank',
                        'noopener,noreferrer'
                    );
                })
                .catch(() => dispatch(setError('ticketingError')))
                .finally(() => setIsLoading(false));
        }
    };

    return {
        getTicketingLink,
        isLoading
    };
}

export default useTicketingLink;
