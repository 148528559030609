// React
import { useMemo, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import VehicleForm from '../components/forms/VehicleForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { DELETE_VEHICLES, GET_VEHICLES } from '../config/endpoints';
import { vehicleColumns } from '../config/tables/vehicles';

// Types
import { Vehicle } from '../types/entities';
import { DeleteVehiclesRequest } from '../types/requests';

// Others
import { Table } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

/**
 * Vehicles page
 * @description
 * Ordered and filterable list of vehicles.
 * Only vehicles relative to the current selection organization
 * will be shown.
 *
 * Only manager of the organization or admins can create, update or
 * delete vehicles.
 */
function VehiclesPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(vehicleColumns);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
    const [toBeDeleted, setToBeDeleted] = useState<number[]>([]);

    // Api
    const vehiclesQuery = useGetQuery<Vehicle[]>(['vehicles'], GET_VEHICLES);
    const deleteMutation = useDeleteMutation<DeleteVehiclesRequest>(DELETE_VEHICLES, {
        invalidateQueries: ['vehicles']
    });

    // Memos
    const vehicles = useMemo<Vehicle[] | undefined>(() => {
        if (!vehiclesQuery?.data) return;
        return applyFilter(vehiclesQuery.data);
    }, [vehiclesQuery?.data, filter]);

    // Methods
    const deleteVehicles = () => {
        if (toBeDeleted.length > 0)
            deleteMutation
                .mutateAsync({ ids: toBeDeleted })
                .then(() => {
                    setToBeDeleted([]);
                    setDeleteOpen(false);
                })
                .catch(() => {});
    };

    const onButtonPress = () => {
        setSelectedVehicle(undefined);
        setOpen(true);
    };

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <VehicleForm initialVehicle={selectedVehicle} onClose={() => setOpen(false)} />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={deleteVehicles}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='vehicles:title'
                buttons={['general:add']}
                onButtonPress={onButtonPress}
            />
            <TableFilter
                columns={vehicleColumns}
                filter={filter}
                setFilter={setFilter}
                blacklist={['dpi']}
            />
            <Table<Vehicle>
                columns={vehicleColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!vehicles
                        ? [
                              vehicle => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([vehicle.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                onCellPress={item => {
                    setSelectedVehicle(item);
                    setOpen(true);
                }}
                data={vehicles}
            />
        </div>
    );
}

export default VehiclesPage;
