// React
import { useCallback } from 'react';

// Hooks
import useOnStartup from './hooks/useOnStartup';
import { useAppSelector } from './hooks/useAppSelector';

// Store
import store from './store';

// Api
import { QueryClient, QueryClientProvider } from 'react-query';

// Views
import AuthenticatedView from './views/AuthenticatedView';
import UnauthenticatedView from './views/UnauthenticatedView';

// Navigation
import { BrowserRouter } from 'react-router-dom';

// Others
import { Provider } from 'react-redux';

const queryClient = new QueryClient();

function Navigation() {
    // Global state
    const user = useAppSelector(state => state.authentication.user);
    const { selectedMission, selectedOrganization } = useAppSelector(state => state.status);

    // Hooks
    useOnStartup();

    // Callbacks
    const renderView = useCallback(() => {
        if (!!user && selectedMission !== undefined && !!selectedOrganization) {
            return <AuthenticatedView />;
        } else if (user === null) {
            return <UnauthenticatedView />;
        } else {
            return <div />;
        }
    }, [user, selectedMission, selectedOrganization]);

    // Render
    return <BrowserRouter>{renderView()}</BrowserRouter>;
}

function App() {
    // Render
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Navigation />
            </QueryClientProvider>
        </Provider>
    );
}

export default App;
