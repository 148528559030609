// Types
import { ColumnConfig } from '@edalab/react-tables';
import { LocalizedTableCell } from '../../components/tables/TableCell';
import { Methane } from '../../types/entities';

export const methaneColumns: ColumnConfig<Methane>[] = [
    { title: 'methanes:createdAt', key: 'general.createdAt' },
    { title: 'general:latitude', key: 'general.latitude' },
    { title: 'general:longitude', key: 'general.longitude' },
    { title: 'methanes:callsignAndFrequency', key: 'majorIncident.callsignAndFrequency' },
    {
        title: 'methanes:number',
        key: 'numberOfPatients.number',
        renderer: methane => (
            <LocalizedTableCell
                text={
                    methane.numberOfPatients?.number
                        ? `numberOfPatients:${methane.numberOfPatients?.number}`
                        : '-'
                }
            />
        )
    }
];
