// Themes
import baseTheme from './baseTheme';
import {
    TailwindMappedTheme,
    AppTheme,
    AppThemes
} from '../types/theme';

const themes: AppThemes = {
    'base': baseTheme
}

export const mapTheme = (variables: AppTheme): TailwindMappedTheme => {
    return {
        '--color-primary': variables.primary || '',
        '--color-secondary': variables.secondary || '',
        '--color-positive': variables.positive || '',
        '--color-negative': variables.negative || '',
        '--color-text-primary': variables.textPrimary || '',
        '--background-primary': variables.backgroundPrimary || '',
        '--background-sec': variables.backgroundSecondary || '',
    }
}

export const applyTheme = (theme: 'base'): void => {
    const themeObject = mapTheme(themes[theme]);
    if (!themeObject) return;

    const root = document.documentElement;

    let property: keyof TailwindMappedTheme;
    for (property in themeObject) {
        root.style.setProperty(property, themeObject[property]);
    }

}

export const DEFAULT_THEME: string = 'base';