// React
import { useState } from 'react';

// Hooks
import { useAppDispatch } from '../hooks/useAppDispatch';

// Store
import { setSelectedMission } from '../store/slices/statusSlice';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';
import { useQueryClient } from 'react-query';

// Components
import BaseModal from '../components/common/BaseModal';
import LocaleText from '../components/common/LocaleText';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import MissionForm from '../components/forms/MissionForm';

// Config
import { DELETE_ARCHIVE_MISSION, GET_MISSIONS } from '../config/endpoints';

// Types
import { Mission } from '../types/entities';
import { STORAGE_KEY } from '../types/enums';
import { DeleteArchiveMissionRequest } from '../types/requests';

// Others
import { SwitchHorizontalIcon, ArchiveIcon } from '@heroicons/react/solid';

/**
 * Missions page
 * @description
 * List of missions relative to the selected organization.
 *
 * Every mission can either be:
 * - open
 * - archived (not editable or accessible)
 * - archiving (archiviation in progress)
 *
 * Only super admins can create or archive missions.
 * Missions can not be deleted.
 */
function MissionsPage() {
    // Hooks
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    // State
    const [open, setOpen] = useState(false);
    const [archiveOpen, setArchiveOpen] = useState(false);
    const [selectedMission, _setSelectedMission] = useState<Mission>();

    // Api
    const missionsQuery = useGetQuery<Mission[]>(['missions'], GET_MISSIONS);
    const archiveMutation = useDeleteMutation<DeleteArchiveMissionRequest>(DELETE_ARCHIVE_MISSION, {
        invalidateQueries: ['missions']
    });

    // Methods
    const changeMission = (mission: Mission) => {
        if (!mission.endTime && !mission.isArchiving) {
            localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, mission.id.toString());
            queryClient.invalidateQueries();
            dispatch(setSelectedMission(mission));
        }
    };

    const archiveMission = () => {
        if (selectedMission)
            archiveMutation
                .mutateAsync({
                    id: selectedMission.id
                })
                .then(res => {
                    setArchiveOpen(false);
                    changeMission(res.data);
                    _setSelectedMission(undefined);
                })
                .catch(() => {});
    };

    const renderMission = (mission: Mission, idx: number) => {
        return (
            <li
                key={idx}
                className=' col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow'>
                <div className='flex w-full items-center justify-between space-x-3 p-6'>
                    <h3 className='truncate text-lg font-medium text-gray-900'>{mission.name}</h3>
                    {!mission.endTime ? (
                        mission.isArchiving ? (
                            <LocaleText
                                text='missions:archiving'
                                className='rounded-full bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-800'
                            />
                        ) : (
                            <LocaleText
                                text='missions:open'
                                className='rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800'
                            />
                        )
                    ) : (
                        <LocaleText
                            text='missions:archived'
                            className='rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800'
                        />
                    )}
                </div>
                <div>
                    <div
                        className={`-mt-px flex divide-x divide-gray-200 ${
                            mission.endTime || mission.isArchiving
                                ? 'text-gray-200'
                                : 'text-gray-500'
                        }`}>
                        <div className='flex w-0 flex-1 cursor-pointer'>
                            <div
                                onClick={() => changeMission(mission)}
                                className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium'>
                                <SwitchHorizontalIcon className='h-5 w-5' aria-hidden='true' />
                                <LocaleText text='missions:switch' className='ml-2' />
                            </div>
                        </div>
                        <div className='-ml-px flex w-0 flex-1 cursor-pointer'>
                            <div
                                onClick={() => {
                                    if (!mission.endTime && !mission.isArchiving) {
                                        _setSelectedMission(mission);
                                        setArchiveOpen(true);
                                    }
                                }}
                                className='relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium'>
                                <ArchiveIcon className='h-5 w-5' aria-hidden='true' />
                                <LocaleText text='missions:archive' className='ml-2' />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    const renderAddButton = () => {
        return (
            <li
                onClick={() => setOpen(true)}
                className='focus:outline-none relative flex min-h-32 w-full
                           cursor-pointer flex-col justify-center rounded-lg border-2 
                           border-dashed border-gray-300 text-center hover:border-gray-400 
                           focus:ring-2 focus:ring-secondary focus:ring-offset-2'>
                <svg
                    className='mx-auto h-8 w-12 text-gray-400'
                    xmlns='http://www.w3.org/2000/svg'
                    stroke='currentColor'
                    fill='none'
                    viewBox='0 0 48 48'
                    aria-hidden='true'>
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
                    />
                </svg>
                <LocaleText
                    text='missions:create'
                    className='mt-2 block text-sm font-medium text-gray-900'
                />
            </li>
        );
    };

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <MissionForm onClose={() => setOpen(false)} />
            </BaseModal>
            <BaseModal open={archiveOpen} setOpen={setArchiveOpen}>
                <DeleteConfirmationForm
                    title='missions:archiveConfirm'
                    body='missions:archiveConfirmBody'
                    buttonText='missions:archive'
                    onClose={() => {
                        setArchiveOpen(false);
                        _setSelectedMission(undefined);
                    }}
                    onConfirm={archiveMission}
                    isLoading={archiveMutation.isLoading}
                />
            </BaseModal>
            <PageHeader title='missions:title' />
            <ul className='mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                {missionsQuery.data?.map((mission, idx) => renderMission(mission, idx))}
                {renderAddButton()}
            </ul>
        </div>
    );
}

export default MissionsPage;
