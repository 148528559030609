// Hooks
import { useAppDispatch } from './useAppDispatch';

// Store
import { logout, setLicences, setUser } from '../store/slices/authenticationSlice';
import { setError, setSelectedMission, setSelectedOrganization } from '../store/slices/statusSlice';

// Api
import useGetMutation from '../api/useGetMutation';
import usePostMutation from '../api/usePostMutation';

// Config
import {
    GET_LICENCES,
    GET_MISSIONS,
    GET_ORGANIZATIONS,
    GET_USER,
    POST_LOGIN,
    POST_REFRESH
} from '../config/endpoints';

// Types
import { Licence, Mission, Organization, User } from '../types/entities';
import { ROLE, STORAGE_KEY } from '../types/enums';
import { LoginRequest, RefreshRequest } from '../types/requests';
import { LoginResponse, RefreshResponse } from '../types/responses';

/**
 * Used to check credentials with the server when an users attemps
 * to log into Maxxie.
 * It also retieves necessary application data like permissions or
 * user informations.
 *
 */
function useLogin() {
    // Hooks
    const dispatch = useAppDispatch();

    // Api
    const loginMutation = usePostMutation<LoginRequest, LoginResponse>(POST_LOGIN, {
        unauth: true
    });
    const refreshMutation = usePostMutation<RefreshRequest, RefreshResponse>(POST_REFRESH);
    const userMutation = useGetMutation<User>(GET_USER);
    const licencesMutation = useGetMutation<Licence[]>(GET_LICENCES);
    const organizationsMutation = useGetMutation<Organization[]>(GET_ORGANIZATIONS);
    const missionsMutation = useGetMutation<Mission[]>(GET_MISSIONS);

    // Methods
    const getOrganization = async () =>
        new Promise<void>((resolve, reject) =>
            organizationsMutation
                .mutateAsync()
                .then(organizations => {
                    if (!organizations.length) {
                        reject();
                        return;
                    }
                    localStorage.setItem(
                        STORAGE_KEY.SELECTED_ORGANIZATION,
                        organizations[0].id.toString()
                    );
                    //dispatch(setOrganizations(organizations));
                    dispatch(setSelectedOrganization(organizations[0]));
                    resolve();
                })
                .catch(() => reject())
        );

    const getData = async () => {
        let user: User;
        let licences: Licence[];
        return Promise.all([userMutation.mutateAsync(), licencesMutation.mutateAsync()])
            .then(([_user, _licences]) => {
                user = _user;
                licences = _licences;

                if (user.role === ROLE.SUPER_ADMIN) return getOrganization();

                if (!user.organization) throw new Error('Organization not found');

                dispatch(setSelectedOrganization(user.organization));
                return Promise.resolve();
            })
            .then(() => missionsMutation.mutateAsync())
            .then(_missions => {
                const missions = _missions.filter(m => !m.endTime && !m.isArchiving);
                if (missions.length) {
                    localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, missions[0].id.toString());
                    dispatch(setSelectedMission(missions[0]));
                } else {
                    localStorage.removeItem(STORAGE_KEY.SELECTED_MISSION);
                    dispatch(setSelectedMission(null));
                }
                dispatch(setLicences(licences));
                dispatch(setUser(user));
            });
    };

    const login = (req: LoginRequest) => {
        loginMutation
            .mutateAsync(req)
            .then(res => {
                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, res.accessToken);
                localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, res.refreshToken);
            })
            .then(() => getData())
            .then(() => dispatch(setError()))
            .catch(error => {
                if (
                    error.response?.data?.statusCode === 401 ||
                    error.response?.data?.statusCode === 403
                )
                    dispatch(setError('invalidCredentials'));
                else if (error.response && error.response.data.message)
                    dispatch(setError('loginError'));
                else if (error.response && error.response.data.error)
                    dispatch(setError('loginError'));
                else if (error.message) dispatch(setError(error.message));

                dispatch(logout());
            });
    };

    const autoLogin = (refreshToken: string) => {
        refreshMutation
            .mutateAsync({ refreshToken })
            .then(res => {
                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, res.accessToken);
            })
            .then(() => getData())
            .catch(error => {
                if (
                    error.response?.data?.statusCode === 401 ||
                    error.response?.data?.statusCode === 403
                )
                    dispatch(setError('invalidCredentials'));
                else if (error.response && error.response.data.message)
                    dispatch(setError('loginError'));
                else if (error.response && error.response.data.error)
                    dispatch(setError('loginError'));
                else if (error.message) dispatch(setError(error.message));

                dispatch(logout());
            });
    };

    return { login, autoLogin };
}
export default useLogin;
