// Components
import HeaderPdf from './HeaderPdf';
import PdfDetailsCard from './PdfDetailsCard';
import PdfDisclosureCard from './PdfDisclosureCard';

// Assets
import black from '../../assets/fonts/black.ttf';
import bold from '../../assets/fonts/bold.ttf';
import light from '../../assets/fonts/light.ttf';
import medium from '../../assets/fonts/medium.ttf';
import regular from '../../assets/fonts/regular.ttf';
import thin from '../../assets/fonts/thin.ttf';

// Others
import { Page, StyleSheet, Font } from '@react-pdf/renderer';

type GeneralPdfPageProps = {
    /** Title */
    title: string;
    /** List of headers */
    headers: {
        key: any;
        title: string;
    }[];
    /** Object containing the details to be displayed */
    details: any;
    /** Optional emergency color shown in the pdf. Used for patients */
    color?: string;
};

Font.register({
    family: 'Roboto',
    fonts: [
        { src: black, fontWeight: 800 },
        { src: bold, fontWeight: 700 },
        { src: medium, fontWeight: 500 },
        { src: regular, fontWeight: 400 },
        { src: light, fontWeight: 300 },
        { src: thin, fontWeight: 100 }
    ]
});

/**
 * One of the pages that composes a pdf
 * @param props
 * @description
 * Renders a single PdfDetailsCard for every category
 * header specified
 */
function GeneralPdfPage(props: GeneralPdfPageProps) {
    // Methods
    const renderDetails = (): any => {
        const details: JSX.Element[] = [];
        details.push(
            <PdfDetailsCard
                key='general'
                id='general'
                title='General'
                informations={props.details.general}
            />
        );
        for (const header of props.headers) {
            if (props.details[header.key])
                details.push(
                    <PdfDetailsCard
                        key={header.key}
                        id={header.key}
                        title={header.title}
                        informations={props.details[header.key]}
                    />
                );
        }
        return details;
    };

    // Render
    return (
        <Page style={styles.page} wrap={false}>
            <HeaderPdf title={props.title} color={props.color} />
            {renderDetails()}
            <PdfDisclosureCard />
        </Page>
    );
}

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        backgroundColor: 'rgb(229, 231, 235)', //'#F9FAFB'
        padding: 10,
        paddingBottom: 70
    }
});

export default GeneralPdfPage;
