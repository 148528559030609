// Utils
import { getDateFromString } from '../../utils/dates';

// Others
import { Message } from '../../types/socketio';
import { useTranslation } from 'react-i18next';

type ChatMessageProps = {
    /** Message containing text */
    message: Message;
    /** Message header */
    header: string;
};

/**
 * Chat text message
 * @description
 * Chat bubble containing the text of the message
 * @param props
 */
function ChatMessage(props: ChatMessageProps) {
    // Hooks
    const { t } = useTranslation();

    // Methods
    const getMessageStyle = () => {
        switch (props.message.type) {
            case 'OPERATOR':
                return 'bg-gray-50 mr-8 md:mr-24';
            default:
                return 'bg-white ml-8 md:ml-24';
        }
    };

    // Render
    return (
        <div className={`px-4 py-6 shadow sm:rounded-lg sm:px-6 ${getMessageStyle()}`}>
            <div className='sm:flex sm:items-baseline sm:justify-between'>
                <h3 className='text-base font-medium'>
                    <span className='text-gray-900'>{props.header}</span>{' '}
                    <span className='text-gray-600'>{t('chat:wrote')}</span>
                </h3>
                <p className='mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3'>
                    {getDateFromString(props.message.timestamp).toLocaleString()}
                </p>
            </div>
            <div className='mt-4 space-y-6 text-sm text-gray-800'>{props.message.text}</div>
        </div>
    );
}

export default ChatMessage;
