// React
import { useMemo } from 'react';

// Config
import { BACKEND_IP, BACKEND_PORT, BACKEND_PROTOCOL } from '../../config/constants';

// Utils
import { getDateFromString } from '../../utils/dates';

// Types
import { Message } from '../../types/socketio';

// Others
import { useTranslation } from 'react-i18next';

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}`;

type ChatImageProps = {
    /** Message containing the image */
    message: Message;
    /** Message header */
    header: string;
};

/**
 * Chat image
 * @description
 * Chat image message in the form of a bubble.
 *
 * The message props contains only the name of the image that
 * will be sourced directly from the server
 * @param props
 */
function ChatImage(props: ChatImageProps) {
    // Hooks
    const { t } = useTranslation();

    // Memos
    const image = useMemo(() => `${baseURL}/media/chat/image/${props.message.mediaName}.jpg`, []);

    // Methods
    const getMessageStyle = () => {
        switch (props.message.type) {
            case 'OPERATOR':
                return 'bg-gray-50 mr-8 md:mr-24';
            default:
                return 'bg-white ml-8 md:ml-24';
        }
    };

    // Render
    return (
        <div
            className={`px-4 py-6 shadow sm:rounded-lg sm:px-6 ${getMessageStyle()}`}
            style={{ maxWidth: '70%' }}>
            <div className='mb-2 sm:flex sm:items-baseline sm:justify-between'>
                <h3 className='text-base font-medium'>
                    <span className='text-gray-900'>{props.header}</span>{' '}
                    <span className='text-gray-600'>{t('chat:sent')}</span>
                </h3>
                <p className='mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3'>
                    {getDateFromString(props.message.timestamp).toLocaleString()}
                </p>
            </div>
            <img
                src={image}
                alt=''
                className='cursor-pointer'
                onError={(e: any) => (e.target.style.display = 'none')}
            />
        </div>
    );
}

export default ChatImage;
