// Assets
import image from '../../../assets/images/cross.png';

// Types
import { Mpaws, Patient } from '../../../types/entities';

// Others
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';

type AnagraphicsT3cProps = {
    /** The patient of the t3c  */
    patient: Patient;
    /** The last triage of the patient */
    paws: Mpaws;
};

/**
 * Anagraphics T3c
 * @description
 * Section with the anagraphics of the patient
 *
 * This section will contain infos about the patient,
 * such as Name, last 4 digits of his SSN, Date and Time
 * of the last PAWS Triage
 *
 * @param props
 */
function AnagraphicsT3c(props: AnagraphicsT3cProps) {
    // Methods
    function getLocalizedDate(data: number) {
        const date = new Date(data);
        const formatted = `${date.toLocaleDateString(['en-GB'])}`;
        return formatted;
    }

    function getLocalizedTime(data: number) {
        const date = new Date(data);
        const formatted = `${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        })}`;
        return formatted;
    }

    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.evacWrapper}>
                <Text style={styles.text}>NAME:</Text>
                <Text style={styles.subtext}> (Last, First)</Text>
                <View style={styles.underlineName}>
                    <Text style={styles.normalText}>
                        {(props.patient.personalInformations.lastName
                            ? props.patient.personalInformations.lastName + ', '
                            : ' ') +
                            (props.patient.personalInformations.firstName
                                ? props.patient.personalInformations.firstName
                                : ' ')}
                    </Text>
                </View>
                <Text style={styles.text}>LAST 4:</Text>
                <View style={styles.underlineCode}>
                    <Text style={styles.normalText}>
                        {props.patient.general.code
                            ? props.patient.general.code.substring(
                                  props.patient.general.code.length - 4,
                                  props.patient.general.code.length
                              )
                            : ' '}
                    </Text>
                </View>
            </View>
            <View style={styles.evacWrapper}>
                <Text style={styles.text}>GENDER:</Text>
                <View style={styles.squareContainer}>
                    <View style={styles.squares}>
                        {props.patient.personalInformations.sex?.toLowerCase() === 'male' && (
                            <Image src={image} style={styles.img} />
                        )}
                    </View>
                    <Text style={styles.squareText}>M</Text>
                    <View style={styles.squares}>
                        {props.patient.personalInformations.sex?.toLocaleLowerCase() ===
                            'female' && <Image src={image} style={styles.img} />}
                    </View>
                    <Text style={styles.squareText}>F</Text>
                </View>
                <Text style={styles.text}>DATE:</Text>
                <Text style={styles.subtext}> (DD-MM-YYYY)</Text>
                <View style={styles.underlineCode}>
                    <Text style={styles.text}>
                        {getLocalizedDate(props.paws.general.startTime)}
                    </Text>
                </View>
                <Text style={styles.text}>TIME:</Text>
                <View style={styles.underlineCode}>
                    <Text style={styles.text}>
                        {getLocalizedTime(props.paws.general.startTime)}
                    </Text>
                </View>
            </View>
            <View style={styles.evacWrapper}>
                <Text style={styles.text}>SERVICE:</Text>
                <View style={styles.underlineService} />
                <Text style={styles.text}>UNIT:</Text>
                <View style={styles.underlineUnit} />
                <Text style={styles.text}>ALLERGIES:</Text>
                <View style={styles.underlineAllergies}>
                    <Text style={styles.text}>{false ? 'true' : 'NKDA'}</Text>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        paddingBottom: 8,
        borderBottom: 1.5,
        borderBottomColor: '#1F2937',
        flexDirection: 'column'
    },
    squareContainer: {
        marginTop: 3,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    evacWrapper: {
        marginLeft: 5,
        marginTop: 5,
        alignItems: 'baseline',
        flexDirection: 'row',
        marginBottom: 5
    },
    img: {
        height: 9,
        width: 8
    },
    squares: {
        border: 1,
        width: 10,
        height: 10,
        borderColor: '#1F2937'
    },
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 2
    },
    normalText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937'
    },
    subtext: {
        fontWeight: 400,
        fontSize: 8,
        color: '#1F2937',
        marginRight: 5,
        alignSelf: 'flex-end'
    },
    squareText: {
        fontWeight: 400,
        alignSelf: 'baseline',
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2,
        marginRight: 5
    },
    underlineName: {
        marginRight: 5,
        flexGrow: 4,
        borderBottom: 1,
        borderBottomColor: '#1F2937'
    },
    underlineCode: {
        marginRight: 5,
        flexGrow: 2,
        borderBottom: 1,
        borderBottomColor: '#1F2937'
    },
    underlineService: {
        marginRight: 5,
        flexGrow: 1,
        borderBottom: 1,
        borderBottomColor: '#1F2937'
    },
    underlineUnit: {
        marginRight: 5,
        flexGrow: 2,
        borderBottom: 1,
        borderBottomColor: '#1F2937'
    },
    underlineAllergies: {
        marginRight: 5,
        flexGrow: 1,
        borderBottom: 1,
        borderBottomColor: '#1F2937'
    }
});

export default AnagraphicsT3c;
