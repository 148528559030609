// Types
import { Color } from '../types/entities';

export const getColorValue = (color?: Color) => {
    switch (color) {
        case 'white':
            return 1;
        case 'green':
            return 2;
        case 'yellow':
            return 3;
        case 'red':
            return 4;
        case 'black':
            return 0;
        default:
            return -1;
    }
};
