// Config
import { BACKEND_IP, BACKEND_PATH, BACKEND_PORT, BACKEND_PROTOCOL } from '../config/constants';
import { POST_REFRESH } from '../config/endpoints';

// Types
import { STORAGE_KEY } from '../types/enums';
import { RefreshResponse } from '../types/responses';

// Others
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}${BACKEND_PATH}`;

const unathAxios = axios.create({
    baseURL,
    timeout: 20 * 1000
});

const authAxios = axios.create({
    baseURL,
    timeout: 20 * 1000
});

authAxios.interceptors.request.use(request => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    const selectedMission = localStorage.getItem(STORAGE_KEY.SELECTED_MISSION);
    const selectedOrganization = localStorage.getItem(STORAGE_KEY.SELECTED_ORGANIZATION);
    request.headers['Authorization'] = `Bearer ${accessToken}`;
    if (selectedOrganization !== undefined && selectedOrganization !== null)
        request.headers['x-organization-id'] = selectedOrganization;
    if (selectedMission !== undefined && selectedMission !== null)
        request.headers['x-mission-id'] = selectedMission;
    return request;
});

const refreshLogic = (failedRequest: any) => {
    const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);

    console.debug('[Tokens refresh] Triggering refresh');
    return new Promise<void>((resolve, reject) => {
        unathAxios
            .post<RefreshResponse>(POST_REFRESH, { refreshToken })
            .then(res => {
                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, res.data.accessToken);
                //localStorage.setString(STORAGE_KEY.REFRESH_TOKEN, res.data.refreshToken);
                failedRequest.response.config.headers['Authorization'] =
                    'Bearer ' + res.data.accessToken;
                console.debug('[Tokens refresh] Tokens refreshed');
                resolve();
            })
            .catch(e => reject(e));
    });
};

createAuthRefreshInterceptor(authAxios, refreshLogic, { statusCodes: [401, 403] });

export { unathAxios, authAxios };

export default authAxios;
