// Components
import { TagTableCell } from '../../components/tables/TableCell';

// Types
import { Operator } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const operatorColumns: ColumnConfig<Operator>[] = [
    { title: 'operators:id', key: 'id', flex: 0.5 },
    { title: 'operators:firstName', key: 'firstName' },
    { title: 'operators:lastName', key: 'lastName' },
    { title: 'operators:username', key: 'username' },
    { title: 'operators:callsign', key: 'callsign' },
    { title: 'operators:frequency', key: 'frequency' },

    {
        title: 'operators:role',
        key: 'role',
        renderer: operator => <TagTableCell text={`operators:${operator.role}`} />
    },
    {
        title: 'operators:mission',
        key: 'mission.name',
        renderer: operator => <TagTableCell text={operator.mission.name} />
    }
];
