import { StyleSheet, Text, View } from '@react-pdf/renderer';

type NotesT3cProps = {
    /** String that contains the notes */
    note: string;
};

/**
 * Notes T3C
 * @description
 * Notes section of the T3C
 *
 * @param props
 */
function NotesT3c(props: NotesT3cProps) {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.title}>NOTES:</Text>
                <Text style={styles.noteText}>{props.note}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        paddingBottom: 8,
        borderBottom: 1.5,
        height: 115,
        borderBottomColor: '#1F2937',
        flexDirection: 'column'
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        marginTop: 5,
        fontWeight: 500,
        fontSize: 15,
        color: '#1F2937'
    },
    noteText: {
        marginTop: 5,
        fontWeight: 400,
        fontSize: 13,
        color: '#1F2937'
    }
});

export default NotesT3c;
