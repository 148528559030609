// React
import { useEffect, useState } from 'react';

// Utils
import { getDateFromString } from '../../utils/dates';

// Types
import { Color, Fas, Sas, Triage } from '../../types/entities';

// Others
import {
    ClipboardListIcon,
    ClipboardCheckIcon,
    TruckIcon,
    LocationMarkerIcon,
    UserIcon
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

export enum ActivityType {
    TRIAGE = 'Triage',
    ASSESSMENT = 'Assessment',
    TRANSFER = 'Transfer',
    CREATE = 'Create'
}

export enum AssessmentType {
    FIRST = 'FIRST_ASSESSMENT',
    SECOND = 'SECOND_ASSESSMENT'
}

export type Activity =
    | {
          type: ActivityType.TRIAGE;
          createdAt: number;
          operatorId: number;
          triageType: string;
          latitude: number;
          longitude: number;
          color: Color;
      }
    | {
          type: ActivityType.ASSESSMENT;
          createdAt: number;
          operatorId: number;
          latitude: number;
          longitude: number;
          assessmentType: AssessmentType;
          color: Color;
      }
    | {
          type: ActivityType.TRANSFER;
          createdAt: number;
          operatorId: number;
          locationId: number;
          latitude: number;
          longitude: number;
          color: Color;
      }
    | {
          type: ActivityType.CREATE;
          createdAt: number;
          latitude: number;
          longitude: number;
      };

type FeedProps = {
    /** Patient position on creation */
    initialPosition?: {
        timestamp: number;
        latitude: number;
        longitude: number;
    };
    /** List of patient triages */
    triages?: Triage[];
    /** List of patient assessments */
    assessments?: (Fas | Sas)[];
};

/**
 * Patient history feeed
 * @descriptions
 * List of all patient movement with a google map
 * link to the coordinates
 * @param props
 */
function Feed(props: FeedProps) {
    // Hooks
    const { t } = useTranslation();

    // State
    const [activities, setActivities] = useState<Activity[]>([]);

    // Methods
    const renderActivity = (activity: Activity) => {
        switch (activity.type) {
            case ActivityType.TRIAGE:
                return (
                    <p className='text-sm text-gray-500'>
                        {t('patients:performed')}
                        <span className='font-medium text-gray-900'>{` ${t(
                            `patients:${activity.triageType}`
                        )} `}</span>
                        {t('patients:by')}
                        <span className='font-medium text-gray-900'>{` operator ${activity.operatorId}`}</span>
                    </p>
                );
            case ActivityType.ASSESSMENT:
                return (
                    <p className='text-sm text-gray-500'>
                        {t('patients:performed')}
                        <span className='font-medium text-gray-900'>{` ${t(
                            `patients:${activity.assessmentType}`
                        )} `}</span>
                        {t('patients:by')}
                        <span className='font-medium text-gray-900'>{` operator ${activity.operatorId}`}</span>
                    </p>
                );
            case ActivityType.TRANSFER:
                return (
                    <p className='text-sm text-gray-500'>
                        {t('patients:moved')}
                        <span className='font-medium text-gray-900'>{` Ospedale San Bortolo `}</span>
                        {t('patients:by')}
                        <span className='font-medium text-gray-900'>{` operator ${activity.operatorId}`}</span>
                    </p>
                );
            case ActivityType.CREATE:
                return <p className='text-sm text-gray-500'>{t('patients:created')}</p>;
        }
    };

    const getColor = (color?: Color) => {
        switch (color) {
            case 'green':
                return 'bg-green-400';
            case 'yellow':
                return 'bg-yellow-400';
            case 'red':
                return 'bg-red-400';
            case 'black':
                return 'bg-black';
            default:
                return 'bg-gray-400';
        }
    };

    const renderIcon = (activityType: ActivityType) => {
        switch (activityType) {
            case ActivityType.TRIAGE:
                return <ClipboardCheckIcon className='h-5 w-5 text-white' aria-hidden='true' />;
            case ActivityType.ASSESSMENT:
                return <ClipboardListIcon className='h-5 w-5 text-white' aria-hidden='true' />;
            case ActivityType.TRANSFER:
                return <TruckIcon className='h-5 w-5 text-white' aria-hidden='true' />;
            case ActivityType.CREATE:
                return <UserIcon className='h-5 w-5 text-white' aria-hidden='true' />;
        }
    };

    // Effects
    useEffect(() => {
        let data: Activity[] = props.initialPosition
            ? [
                  {
                      type: ActivityType.CREATE,
                      createdAt: props.initialPosition.timestamp,
                      latitude: props.initialPosition.latitude,
                      longitude: props.initialPosition.longitude
                  }
              ]
            : [];
        data = props.triages
            ? data.concat(
                  props.triages.map(triage => {
                      return {
                          type: ActivityType.TRIAGE,
                          createdAt: triage.general.endTime,
                          operatorId: triage.general.operatorId,
                          triageType: triage.general.triageType,
                          latitude: triage.general.latitude,
                          longitude: triage.general.longitude,
                          color: triage.general.color
                      };
                  })
              )
            : data;
        data = props.assessments
            ? data.concat(
                  props.assessments.map(assessment => {
                      return {
                          type: ActivityType.ASSESSMENT,
                          createdAt: assessment.general.endTime,
                          operatorId: assessment.general.operatorId,
                          assessmentType: assessment.a
                              ? AssessmentType.FIRST
                              : AssessmentType.SECOND,
                          latitude: assessment.general.latitude,
                          longitude: assessment.general.longitude,
                          color: assessment.general.color
                      };
                  })
              )
            : data;
        setActivities(data);
    }, [props]);

    // Render
    return (
        <div className='flow-root'>
            <ul className='-mb-8'>
                {activities.map((activity, index) => (
                    <li key={index}>
                        <div className='relative pb-8'>
                            {index !== 0 ? (
                                <span
                                    className='absolute left-4 -ml-px h-full w-0.5 bg-white'
                                    aria-hidden='true'
                                />
                            ) : null}
                        </div>
                        <div className='relative flex space-x-3'>
                            <div>
                                <span
                                    className={classNames(
                                        getColor((activity as any).color || ''),
                                        'flex h-8 w-8 items-center justify-center rounded-full'
                                    )}>
                                    {renderIcon(activity.type)}
                                </span>
                            </div>
                            <div className='flex min-w-0 flex-1 justify-between space-x-4 pt-1.5'>
                                <div>{renderActivity(activity)}</div>
                                <div className='flex items-center whitespace-nowrap text-right text-sm text-gray-500'>
                                    <LocationMarkerIcon
                                        className='mr-6 h-6 w-6 cursor-pointer'
                                        onClick={() =>
                                            window.open(
                                                `http://www.google.com/maps/place/${activity.latitude},${activity.longitude}`,
                                                '_blank'
                                            )
                                        }
                                    />
                                    <p>{`${getDateFromString(
                                        activity.createdAt
                                    ).toLocaleString()}`}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Feed;
