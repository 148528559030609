// React
import { useMemo, useState } from 'react';

// Api
import useGetQuery from '../../api/useGetQuery';
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput, FormSelect } from '../forms/FormInput';

// Config
import { GET_MISSIONS, POST_OPERATOR } from '../../config/endpoints';

// Types
import { Mission, Operator } from '../../types/entities';
import { OPERATOR_ROLE } from '../../types/enums';
import { PostOperatorRequest } from '../../types/requests';

// Others
import { useTranslation } from 'react-i18next';

type OperatorFormProps = {
    /** Operator to update */
    initialOperator?: Operator;
    /** Called on submit or cancel */
    onClose?: () => void;
};

function fromOperatorToDto(operator: Operator): PostOperatorRequest {
    const { mission, ...operatorRequest } = operator;
    return {
        ...operatorRequest,
        missionId: mission.id
    };
}

/**
 * Operator form
 * @description
 * Form used to create or update an operator.
 *
 * Uses HTML validation.
 * @param props
 */
function OperatorForm(props: OperatorFormProps) {
    // Hooks
    const { t } = useTranslation();

    // State
    const [operator, setOperator] = useState<PostOperatorRequest>(
        props.initialOperator
            ? fromOperatorToDto(props.initialOperator)
            : ({
                  role: OPERATOR_ROLE.MEDIC
              } as PostOperatorRequest)
    );
    const [selectedMission, setSelectedMission] = useState<Mission | undefined>(
        props.initialOperator?.mission
    );
    const [selectedRole, setSelectedRole] = useState<OPERATOR_ROLE>(operator.role);

    // Api
    const missionsQuery = useGetQuery<Mission[]>(['missions'], GET_MISSIONS);
    const operatorMutation = usePostMutation<PostOperatorRequest>(POST_OPERATOR, {
        invalidateQueries: ['operators']
    });

    // Memos
    const roleOptions = useMemo(
        () =>
            Object.keys(OPERATOR_ROLE).map(role => ({
                id: role,
                name: t(`operators:${role}`)
            })),
        []
    );

    // Methods
    const handlePostRequest = () => {
        if (selectedMission && selectedRole) {
            const data: PostOperatorRequest = {
                ...operator,
                missionId: selectedMission.id,
                role: selectedRole
            };
            operatorMutation
                .mutateAsync(data)
                .then(() => {
                    props.onClose && props.onClose();
                })
                .catch(() => {});
        }
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='operators:firstName'
                    value={operator.firstName || ''}
                    onChange={text => setOperator({ ...operator, firstName: text })}
                />
                <FormInput
                    label='operators:lastName'
                    value={operator.lastName || ''}
                    onChange={text => setOperator({ ...operator, lastName: text })}
                />
                <FormInput
                    label='operators:username'
                    value={operator.username || ''}
                    onChange={text => setOperator({ ...operator, username: text })}
                />
                <FormInput
                    label='operators:callsign'
                    value={operator.callsign || ''}
                    onChange={text => setOperator({ ...operator, callsign: text })}
                />
                <FormInput<number>
                    label='operators:frequency'
                    value={operator.frequency || ''}
                    contentType='number'
                    onChange={text => setOperator({ ...operator, frequency: text })}
                />
                {!props.initialOperator && (
                    <FormInput
                        label='operators:password'
                        value={operator.password || ''}
                        contentType='password'
                        onChange={text => setOperator({ ...operator, password: text })}
                    />
                )}
                <FormSelect
                    label='operators:mission'
                    items={
                        missionsQuery.data?.filter(m => !m.endTime && !m.isArchiving) || [
                            selectedMission
                        ]
                    }
                    selected={selectedMission}
                    setSelected={setSelectedMission}
                />
                <FormSelect
                    label='operators:role'
                    items={roleOptions}
                    selected={{ id: selectedRole, name: t(`operators:${selectedRole}`) }}
                    setSelected={role => setSelectedRole(role.id)}
                />
            </div>
            <div className='bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialOperator ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={operatorMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default OperatorForm;
