// React
import { useMemo, useRef } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useGetQuery from '../api/useGetQuery';

// Components
import PageHeader from '../components/common/PageHeader';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_APP_LOGS } from '../config/endpoints';
import { appLogColumns } from '../config/tables/appLogs';

// Types
import { AppLog } from '../types/entities';

// Others
import { Table, TableView } from '@edalab/react-tables';

/**
 * Logs page
 * @description
 * This page shows all the logs produced by Maxxie.
 * Both dashboard and mobile application logs are shown.
 *
 * Only logs produced by lower user roles will included.
 */
function LogsPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(appLogColumns, 1, [
        { colKey: 'operatorRole', locKey: 'operators' }
    ]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const appLogsQuery = useGetQuery<AppLog[]>(['logs', 'app'], GET_APP_LOGS);

    // Memos
    const appLogs = useMemo<AppLog[] | undefined>(() => {
        if (!appLogsQuery.data) return;
        tableView.current?.changePage(1);
        return applyFilter(appLogsQuery.data || []);
    }, [appLogsQuery.data, filter]);

    // Render
    return (
        <div>
            <PageHeader title='logs:title' />
            <TableFilter columns={appLogColumns} filter={filter} setFilter={setFilter} />
            <Table<AppLog>
                ref={tableView}
                columns={appLogColumns}
                baseRowClasses='cursor-pointer'
                data={appLogs}
            />
        </div>
    );
}

export default LogsPage;
