// Types
import { ROLE } from '../types/enums';

export const getRoleValue = (role: ROLE) => {
    switch (role) {
        case ROLE.SUPER_ADMIN:
            return 3;
        case ROLE.ADMIN:
            return 2;
        case ROLE.MANAGER:
            return 1;
        default:
            return 0;
    }
};

export const isHigherOrEqualRole = (first: ROLE, second: ROLE) => {
    const firstValue = getRoleValue(first);
    const secondValue = getRoleValue(second);

    if (firstValue >= secondValue) return true;
    return false;
};
