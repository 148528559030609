// Types
import { ColumnConfig } from "@edalab/react-tables";
import { ColorTableCell } from "../../components/tables/TableCell";
import { Triage } from "../../types/entities";

export const triageColumns: ColumnConfig<Triage>[] = [
    {
        title: 'triages:color',
        key: 'general.color',
        renderer: triage => <ColorTableCell text={triage.general.color} color={triage.general.color} />
    },
    { title: 'triages:start', key: 'general.startTime' },
    { title: 'triages:end', key: 'general.endTime' },
    { title: 'triages:type', key: 'general.triageType' },
    { title: 'triages:patient', key: 'general.patCode' },
    { title: 'triages:operator', key: 'general.operatorId' },
];