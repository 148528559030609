// Types
import { Organization } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const organizationsColumns: ColumnConfig<Organization>[] = [
    { title: 'organizations:id', key: 'id' },
    { title: 'organizations:name', key: 'name' }
];
