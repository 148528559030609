// Api
import axios from './axios';
import { useQuery } from 'react-query';

// Utils
import { parseUrl } from '../utils/api';

// Types
import { QueryOptions } from '../types/structs';

function useGetQuery<T = any, C = T>(key: string[], url: string, options?: QueryOptions<T, C>) {
    // Async function
    type QueryType = () => Promise<C>;
    const request: QueryType = async () => {
        const res = await axios.get<T>(parseUrl(url, options?.params));
        return options?.transform ? options.transform(res.data) : (res.data as unknown as C);
    };

    // Query
    const query = useQuery(key, request, {
        refetchOnWindowFocus: options?.refetchOnWindowFocus,
        retry: 0,
        refetchInterval: options?.refetchInterval,
        onSuccess: res => {
            options?.onSuccess && options.onSuccess(res);
        },
        enabled: options?.enable
    });

    return {
        ...query
    };
}

export default useGetQuery;
