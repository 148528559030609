// React
import { useMemo } from 'react';

// Components
import T3cPdf from '../../components/pdf/T3cPdf';

// Config
import { triageColumns } from '../../config/tables/triages';

// Types
import { Mpaws, Patient, Triage } from '../../types/entities';

// Others
import { Table } from '@edalab/react-tables';
import { useHistory } from 'react-router';

const detailsHeaders = [
    { key: 'details', title: 'Details', subtitle: 'Specific triage details' },
    { key: 'massiveHemorrage', title: 'Massive hemorrage' },
    { key: 'airwaysManagement', title: 'Airways open' },
    { key: 'circulation', title: 'Circulation' },
    { key: 'respiration', title: 'Respiration' },
    { key: 'headInjury', title: 'Head injury' },
    { key: 'paws', title: 'Paws' }
];

type PatientTriagesFragmentProps = {
    /** Patient triages */
    triages?: (Triage | Mpaws)[];
    patient: Patient;
};

/**
 * Ordered list of patient triages
 * @param props
 */
function PatientTriagesFragment(props: PatientTriagesFragmentProps) {
    // Navigation
    const history = useHistory();

    // Memos
    const triages = useMemo<(Triage | Mpaws)[] | undefined>(() => {
        return props.triages
            ?.sort((a, b) => (a.general.endTime > b.general.endTime ? -1 : 1))
            .map(a => {
                if (a.general.triageType.includes('MPAWS')) {
                    const mpaws: Mpaws = structuredClone(a);
                    if (mpaws.circulation.minorBleedingMapPaths)
                        delete mpaws.circulation.minorBleedingMapPaths;
                    if (mpaws.massiveHemorrage.massiveBleedingMapPaths)
                        delete mpaws.massiveHemorrage.massiveBleedingMapPaths;
                    if (mpaws.massiveHemorrage.tourniquetMapPaths)
                        delete mpaws.massiveHemorrage.tourniquetMapPaths;
                    if (mpaws.massiveHemorrage.tourniquetMapZones)
                        delete mpaws.massiveHemorrage.tourniquetMapZones;
                    if (mpaws.paws.woundsMapPaths) delete mpaws.paws.woundsMapPaths;
                    if (mpaws.paws.fracturesMapPaths) delete mpaws.paws.fracturesMapPaths;
                    if (mpaws.paws.woundsMapBurnPercentage)
                        delete mpaws.paws.woundsMapBurnPercentage;
                    if (mpaws.paws.woundsMapBurnZones) delete mpaws.paws.woundsMapBurnZones;

                    return mpaws;
                }
                return a;
            });
    }, [props.triages]);

    // Methods
    const navigate = (triage: Triage | Mpaws) => {
        history.push({
            pathname: '/triages/' + triage.id,
            state: {
                details: triage,
                headers: detailsHeaders,
                title: 'Triage Details'
            }
        });
    };

    /*     const getTriages = () => {
        return props.triages
            ?.filter(triage => triage.general.triageType.includes('MPAWS'))
            .sort(function (a, b) {
                return a.general.startTime - b.general.startTime;
            })! as Mpaws[];
    }; 

    console.log(getTriages()!);*/

    // Render
    return (
        <div className='mt-10'>
            <Table<Triage>
                columns={triageColumns}
                data={triages}
                baseRowClasses='cursor-pointer'
                onCellPress={item => navigate(item)}
            />
            {/* <div className='mt-20'>
                <PDFViewer style={{ width: '100%', height: 1000 }}>
                    <T3cPdf paws={getTriages()!} patient={props.patient} />
                </PDFViewer>
            </div> */}
        </div>
    );
}

export default PatientTriagesFragment;
