// React
import { useState } from 'react';

// Hooks
import { useAppSelector } from '../hooks/useAppSelector';
import { usePermission } from '../hooks/usePermission';

// Pages
import HomePage from '../pages/HomePage';

// Components
import Banner from '../components/common/Banner';
import NotificationsList from '../components/common/NotificationsList';
import Navbar from '../components/navigation/Navbar';
import Sidebar from '../components/navigation/Sidebar';
import SocketProvider from '../components/providers/SocketProvider';
import {
    SingleTableCell,
    HeaderTableCell,
    SkeletonTableCell
} from '../components/tables/TableCell';

// Navigation
import { Switch, Route, Redirect } from 'react-router-dom';

// Config
import { routingMap } from '../config/routing';
import { paginationConfig, rowColors } from '../config/tables/general';

// Others
import { BREAKPOINT, InitTables } from '@edalab/react-tables';

function AuthenticatedView() {
    // Hooks
    const { hasPermission } = usePermission();

    // Global state
    const selectedMission = useAppSelector(state => state.status.selectedMission);

    // State
    const error = useAppSelector(state => state.status.error);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // Render
    return (
        <SocketProvider>
            <div className='flex max-h-screen'>
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='flex h-screen flex-1 flex-col bg-gray-100'>
                    <Navbar />
                    <div className='no-scrollbar flex-1 overflow-y-scroll py-5 px-6 2xl:px-8'>
                        <InitTables
                            breakpoint={BREAKPOINT.MD}
                            className='overflow-hidden rounded-lg bg-white py-3 shadow md:py-0'
                            cellRenderer={text => <SingleTableCell text={text} />}
                            headerRenderer={text => <HeaderTableCell name={text} />}
                            baseHeaderClasses='bg-white border-b border-gray-200'
                            rowColors={rowColors}
                            skeletonConfig={{
                                rows: 5,
                                renderer: children => (
                                    <SkeletonTableCell>{children}</SkeletonTableCell>
                                )
                            }}
                            paginationConfig={paginationConfig}>
                            <Switch>
                                {selectedMission && (
                                    <Route path='/' exact>
                                        <HomePage />
                                    </Route>
                                )}
                                {routingMap.map(
                                    (route, index) =>
                                        hasPermission(route.resource!) && (
                                            <Route key={index} path={route.path} exact>
                                                <route.page />
                                            </Route>
                                        )
                                )}
                            </Switch>
                            {!selectedMission && <Redirect to='/missions' />}
                        </InitTables>
                    </div>
                </div>
                {error && <Banner message={error} />}
                <NotificationsList />
            </div>
        </SocketProvider>
    );
}

export default AuthenticatedView;
