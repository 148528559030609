// Types
import { ColumnConfig } from '@edalab/react-tables';
import { LocalizedTableCell, SingleTableCell } from '../../components/tables/TableCell';
import { NineLine } from '../../types/entities';

export const nineLineColumns: ColumnConfig<NineLine>[] = [
    { title: 'nineLines:createdAt', key: 'general.createdAt' },
    { title: 'general:latitude', key: 'general.latitude' },
    { title: 'general:longitude', key: 'general.longitude' },
    {
        title: 'nineLines:number',
        key: 'numberOfPatients.number',
        renderer: nineLine => (
            <SingleTableCell
                text={
                    (nineLine.patientsStatus.convenience || 0) +
                    (nineLine.patientsStatus.priority || 0) +
                    (nineLine.patientsStatus.routine || 0) +
                    (nineLine.patientsStatus.surgery || 0) +
                    (nineLine.patientsStatus.urgent || 0)
                }
            />
        )
    },
    {
        title: 'nineLines:security',
        key: 'security.type',
        renderer: nineLine => (
            <LocalizedTableCell
                text={nineLine.security?.type ? `security:${nineLine.security.type}` : '-'}
            />
        )
    },
    { title: 'nineLines:operatorId', key: 'general.operatorId' }
];
