// React
import { useState } from 'react';

// Utils
import { isDate, isValidTimestamp } from '../utils/dates';
import { getByString } from '../utils/objects';

// Types
import { Filter } from '../types/structs';

// Others
import { ColumnConfig } from '@edalab/react-tables';
import { useTranslation } from 'react-i18next';

/** Used to apply filter to the data of a table */
function useFilter(
    config: ColumnConfig[],
    defaultColIndex = 0,
    localizedKeys?: {
        colKey: string;
        locKey: string;
    }[]
) {
    // Hooks
    const { t } = useTranslation();

    // State
    const [filter, setFilter] = useState<Filter>({
        column: config[defaultColIndex],
        value: ''
    });

    // Methods
    const applyFilter = (list: any[]) => {
        if (filter.value !== '') {
            return list.filter(p => {
                let v = getByString(p, filter.column.key);

                if (isDate(v)) v = new Date(v).toLocaleString();
                if (isValidTimestamp(v)) v = new Date(v).toLocaleString();

                if (localizedKeys && v !== undefined) {
                    const localizedKey = localizedKeys?.find(l => l.colKey === filter.column.key);
                    if (localizedKey) {
                        v = t(`${localizedKey.locKey}:${v}`);
                    }
                }

                if (typeof v === 'string' && v.toLowerCase().includes(filter.value.toLowerCase()))
                    return true;
                else if (typeof v !== 'string' && String(v).includes(filter.value.toLowerCase()))
                    return true;

                return false;
            });
        }
        return list;
    };

    return {
        filter,
        setFilter,
        applyFilter
    };
}

export default useFilter;
