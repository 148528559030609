// Components
import LocaleText from '../components/common/LocaleText';
import LoginForm from '../components/forms/LoginForm';

// Assets
import image from '../assets/images/logoINMM.png';

/**
 * Login page
 * @description
 * Here the user can insert its credentials in order to
 * authenticate.
 */
function LoginPage() {
    // Render
    return (
        <div className='flex h-full items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8'>
            <div className='w-full max-w-md space-y-8'>
                <div>
                    <img className='mx-auto h-10 w-auto' src={image} alt='Maxxie' />
                    <LocaleText
                        text='login:title'
                        className='mt-6 text-center text-3xl font-extrabold text-gray-900'
                    />
                </div>
                <LoginForm />
            </div>
        </div>
    );
}

export default LoginPage;
