// Components
import Gallery from '../../components/common/Gallery';

type PatientGalleryFragmentProps = {
    /** Patient code */
    patCode: string;
    /** Patient images names */
    images?: string[];
};

/**
 * List of patient images
 * @param props
 */
function PatientGalleryFragment(props: PatientGalleryFragmentProps) {
    // Render
    return (
        <div className='mt-6'>
            <Gallery basePath={`media/patients/${props.patCode}`} images={props.images} />
        </div>
    );
}

export default PatientGalleryFragment;
