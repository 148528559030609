// React
import { useEffect, useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import DeviceForm from '../components/forms/DeviceForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_DEVICES, DELETE_DEVICES } from '../config/endpoints';
import { devicesColumns } from '../config/tables/devices';

// Types
import { Device } from '../types/entities';
import { DeleteDevicesRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

/**
 * Devices page
 * @description
 * Ordered and filterable list of devices.
 * This list is global and shared between all organizations.
 *
 * Maxxie will authenticate requests comuning from mobile devices
 * with the uuids specified here
 *
 * Only super admins can create, update or delete devices.
 */
function DevicesPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(devicesColumns, 1);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState<Device>();

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const devicesQuery = useGetQuery<Device[]>(['devices'], GET_DEVICES);
    const deleteMutation = useDeleteMutation<DeleteDevicesRequest>(DELETE_DEVICES, {
        invalidateQueries: ['devices']
    });

    // Memos
    const devices = useMemo<Device[] | undefined>(() => {
        if (!devicesQuery.data) return;
        tableView.current?.changePage(1);
        return applyFilter(devicesQuery.data);
    }, [devicesQuery.data, filter]);

    // Effects
    useEffect(() => {
        if (!open && !!selectedDevice) setSelectedDevice(undefined);
    }, [open]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <DeviceForm onClose={() => setOpen(false)} initialDevice={selectedDevice} />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setSelectedDevice(undefined);
                    }}
                    onConfirm={() => {
                        if (selectedDevice)
                            deleteMutation
                                .mutateAsync({ ids: [selectedDevice.id] })
                                .then(() => {
                                    setDeleteOpen(false);
                                    setSelectedDevice(undefined);
                                })
                                .catch(() => {});
                    }}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='devices:title'
                buttons={['general:add']}
                onButtonPress={() => setOpen(true)}
            />
            <TableFilter columns={devicesColumns} filter={filter} setFilter={setFilter} />
            <Table<Device>
                ref={tableView}
                columns={devicesColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!devices
                        ? [
                              device => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setSelectedDevice(device);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                onCellPress={item => {
                    setSelectedDevice(item);
                    setOpen(true);
                }}
                data={devices}
            />
        </div>
    );
}

export default DevicesPage;
