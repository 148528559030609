// Types
import { ColumnConfig } from '@edalab/react-tables';
import { DoubleTableCell, SingleTableCell } from '../../components/tables/TableCell';
import { Location } from '../../types/entities';

export const locationColumns: ColumnConfig<Location>[] = [
    { title: 'locations:id', key: 'id' },
    {
        title: 'locations:name',
        key: 'name',
        flex: 2,
        renderer: location => <DoubleTableCell first={location.name} second={location.address} />
    },
    { title: 'locations:info', key: 'info', flex: 2 },
    {
        title: 'locations:places',
        key: 'places',
        flex: 2,
        renderer: location => (
            <SingleTableCell
                text={
                    location.availablePlaces -
                    (location.busyGreen +
                        location.busyRed +
                        location.busyYellow +
                        location.busyWhite)
                }
            />
        )
    },
    {
        title: 'locations:intensiveCare',
        key: 'intensiveCare',
        flex: 2,
        renderer: location => (
            <SingleTableCell text={location.intensiveCarePlaces - location.busyIntensiveCare} />
        )
    },
    {
        title: 'locations:distributions',
        key: 'distribution',
        flex: 2,
        renderer: location => (
            <div className='whitespace-nowrap px-6 py-4 text-sm text-white'>
                <span className='rounded-tl rounded-bl bg-green-600 px-2 py-1'>
                    {location.busyGreen}
                </span>
                <span className='bg-yellow-600 px-2 py-1'>{location.busyYellow}</span>
                <span className='rounded-tr rounded-br bg-red-600 px-2 py-1'>
                    {location.busyRed}
                </span>
            </div>
        )
    }
];
