// React
import { useState } from 'react';

// Components
import LocaleText from '../common/LocaleText';

// Icons
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

type SidebarItemHeaderProps = {
    /** Category name */
    label: string;
    /** Component containing category items */
    children?: React.ReactNode;
};

/**
 * One of the header items used inside
 * @description
 * Groups a category of sidebar items.
 *
 * Provides show and collapse functionality.
 * @param props
 */
function SidebarItemHeader(props: SidebarItemHeaderProps) {
    // State
    const [showItems, setShowItems] = useState(true);

    // Render
    return (
        <>
            <div
                onClick={() => setShowItems(!showItems)}
                className={`flex cursor-pointer items-center justify-between rounded-md px-2 py-2 text-sm font-medium ${
                    showItems
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                }`}>
                <LocaleText className='ml-3' text={props.label} />

                {showItems ? (
                    <ChevronUpIcon
                        className='mr-3 h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-300'
                        aria-hidden='true'
                    />
                ) : (
                    <ChevronDownIcon
                        className='mr-3 h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-300'
                        aria-hidden='true'
                    />
                )}
            </div>
            {showItems && props.children}
        </>
    );
}

export default SidebarItemHeader;
