// React
import { useMemo, useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormCheckbox, FormInput } from '../forms/FormInput';

// Config
import { POST_VEHICLES } from '../../config/endpoints';

// Types
import { Vehicle } from '../../types/entities';

type VehicleFormProps = {
    /** Vehicle to updated */
    initialVehicle?: Vehicle;
    /** Called on submit or cancel */
    onClose?: () => void;
};

/**
 * Vehicle form
 * @description
 * Form used to create or update a vehicle.
 *
 * Uses HTML validation.
 * @param props
 */
function VehicleForm(props: VehicleFormProps) {
    // State
    const [vehicle, setVehicle] = useState<Vehicle>(
        props.initialVehicle ? props.initialVehicle : ({ dpi: false } as Vehicle)
    );

    // Api
    const vehicleMutation = usePostMutation<Vehicle>(POST_VEHICLES, {
        invalidateQueries: ['vehicles']
    });

    // Methods
    const handlePostRequest = () => {
        vehicleMutation
            .mutateAsync({
                ...vehicle
            })
            .then(() => {
                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='vehicles:identifier'
                    value={vehicle.identifier || ''}
                    onChange={text => setVehicle({ ...vehicle, identifier: text })}
                />
                <div className='grid grid-cols-2 gap-2'>
                    <FormInput
                        label='vehicles:type'
                        value={vehicle.type || ''}
                        optional
                        onChange={text => setVehicle({ ...vehicle, type: text })}
                    />
                    <FormInput
                        label='vehicles:team'
                        value={vehicle.team || ''}
                        optional
                        onChange={text => setVehicle({ ...vehicle, team: text })}
                    />
                </div>
                <div className='grid grid-cols-2 gap-2'>
                    <FormInput
                        label='vehicles:status'
                        value={vehicle.status || ''}
                        optional
                        onChange={text => setVehicle({ ...vehicle, status: text })}
                    />
                    <FormInput
                        label='vehicles:color'
                        value={vehicle.color || ''}
                        optional
                        onChange={text => setVehicle({ ...vehicle, color: text })}
                    />
                </div>
                <FormInput
                    label='vehicles:equipment'
                    value={vehicle.equipment || ''}
                    optional
                    onChange={text => setVehicle({ ...vehicle, equipment: text })}
                />
                <div className='flex'>
                    <div className='flex-1'>
                        <FormInput
                            label='vehicles:currentLocation'
                            value={vehicle.currentLocation || ''}
                            optional
                            onChange={text => setVehicle({ ...vehicle, currentLocation: text })}
                        />
                    </div>
                    <FormCheckbox
                        label='vehicles:dpi'
                        value={vehicle.dpi}
                        optional
                        onChange={value => setVehicle({ ...vehicle, dpi: value })}
                    />
                </div>
            </div>
            <div className='rounded-bl-lg rounded-br-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialVehicle ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={vehicleMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default VehicleForm;
