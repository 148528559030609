// Pages
import DeadBodiesPage from '../pages/DeadBodiesPage';
import MedevacsPage from '../pages/MedevacsPage';
import MethanesPage from '../pages/MethanesPage';
import NineLinesPage from '../pages/NineLinesPage';
import PatientsPage from '../pages/PatientsPage';
import TriagesPage from '../pages/TriagesPage';

// Components
import PatientsCounter from '../components/common/PatientsCounter';

/**
 * Home page
 * @description
 * Provides a resume of the current selected mission
 * situation.
 */
function HomePage() {
    // Render
    return (
        <div className='space-y-10'>
            <PatientsCounter />
            <PatientsPage numberOfRows={10} />
            <TriagesPage numberOfRows={5} />
            <MedevacsPage numberOfRows={5} />
            <NineLinesPage numberOfRows={5} />
            <MethanesPage numberOfRows={5} />
            <DeadBodiesPage numberOfRows={5} />
        </div>
    );
}

export default HomePage;
