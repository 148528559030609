import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

/** Disclosure appended at the end of a pdf */
function PdfDisclosureCard() {
    // Hooks
    const { t } = useTranslation();

    // Render
    return (
        <View style={styles.container} fixed>
            <Text style={styles.text}>{t('pdf:disclosure')}</Text>
            <View style={styles.signWrapper}>
                <View>
                    <Text style={styles.text}> {t('pdf:sign')}</Text>
                </View>
                <View style={styles.signContainer}></View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 3,
        padding: 10,
        position: 'absolute',
        bottom: 10,
        left: 10
    },
    text: {
        fontSize: 8,
        color: '#9CA3AF'
    },
    signWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
    },
    signContainer: {
        flex: 1,
        borderBottom: 1,
        borderBottomColor: '#9CA3AF',
        marginLeft: 10
    }
});

export default PdfDisclosureCard;
