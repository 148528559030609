// React
import { useMemo } from 'react';

// Components
import SearchFilter from '../common/SearchFilter';

// Types
import { Filter } from '../../types/structs';

// Others
import { ColumnConfig } from '@edalab/react-tables';
import { SearchIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

type TableFilterProps<T> = {
    /** Filter value */
    filter: Filter;
    /** Table column config */
    columns: ColumnConfig<T>[];
    /** Column keys that should not be filterable */
    blacklist?: string[];
    /** Called when filter changes */
    setFilter: (filter: Filter) => void;
};

/**
 * Input text used for filtering tables
 * @description
 * This component allows the user to choose a filter specifying a specific
 * column and a partial match.
 * @param props
 */
function TableFilter<T = any>(props: TableFilterProps<T>) {
    // Memos
    const columns = useMemo<ColumnConfig<T>[]>(() => {
        if (!props.blacklist) return props.columns;
        else return props.columns.filter(c => !props.blacklist?.includes(c.key));
    }, [props.columns, props.blacklist]);

    // Render
    return (
        <div className='mb-5 flex space-x-1'>
            <div className='relative flex-1'>
                <div className='absolute left-0 top-0 flex h-full w-10'>
                    <SearchIcon
                        height={15}
                        width={15}
                        className='m-auto text-gray-600'
                        strokeWidth={40}
                    />
                </div>
                <input
                    type='text'
                    className='w-full rounded border border-gray-300 py-2 pl-10 text-sm shadow focus:ring-secondary'
                    value={props.filter.value}
                    onChange={e => {
                        props.setFilter({
                            ...props.filter,
                            value: e.target.value
                        });
                    }}
                />
                <div
                    className='absolute right-0 top-0 flex h-full w-10 cursor-pointer'
                    onClick={() =>
                        props.setFilter({
                            ...props.filter,
                            value: ''
                        })
                    }>
                    <XIcon height={16} width={17} className='m-auto text-gray-600' />
                </div>
            </div>
            <SearchFilter columns={columns} filter={props.filter} setFilter={props.setFilter} />
        </div>
    );
}

export default TableFilter;
