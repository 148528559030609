// Types
import { Contact } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const contactsColumns: ColumnConfig<Contact>[] = [
    { title: 'contacts:id', key: 'id', flex: 0.5 },
    { title: 'contacts:firstName', key: 'firstName' },
    { title: 'contacts:lastName', key: 'lastName' },
    { title: 'contacts:email', key: 'email' },
    { title: 'contacts:address', key: 'address' },
    { title: 'contacts:phoneNumber', key: 'phoneNumber' }
];
