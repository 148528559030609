// React
import { Fragment } from 'react';

// Hooks
import useTicketingLink from '../../hooks/useTicketingLink';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { usePermission } from '../../hooks/usePermission';

// Store
import { logout } from '../../store/slices/authenticationSlice';

// Components
import LanguageSelector from '../common/LanguageSelector';
import SidebarItem from './SidebarItem';
import SidebarItemHeader from './SidebarItemHeader';

// Navigation
import { useHistory } from 'react-router-dom';

// Assets
import logoINMM from '../../assets/icons/logoINMM.svg';

// Types
import { ROLE } from '../../types/enums';

// Others
import { Dialog, Transition } from '@headlessui/react';
import {
    UsersIcon,
    ClipboardCheckIcon,
    ShieldCheckIcon,
    DeviceMobileIcon,
    TruckIcon,
    OfficeBuildingIcon,
    LocationMarkerIcon,
    XIcon,
    CheckCircleIcon,
    ChatIcon,
    DocumentReportIcon,
    MapIcon,
    PhotographIcon,
    LinkIcon,
    TicketIcon,
    DocumentIcon,
    TagIcon
} from '@heroicons/react/outline';
import { LogoutIcon } from '@heroicons/react/solid';

type SidebarProps = {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Side navigation bar for the authenticated user
 * @description
 * Shows all available modules for the logged user.
 *
 * Modules are organized by category.
 * @param props
 */
function Sidebar(props: SidebarProps) {
    // Hooks
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { getTicketingLink, isLoading } = useTicketingLink();

    const { hasPermission } = usePermission();

    // Global State
    const { user } = useAppSelector(state => state.authentication);
    const selectedMission = useAppSelector(state => state.status.selectedMission);

    const renderOptions = () => {
        return (
            <>
                {user?.role === ROLE.SUPER_ADMIN && (
                    <SidebarItemHeader label='sidebar:admin'>
                        {hasPermission('licence') && (
                            <SidebarItem
                                label='sidebar:licences'
                                route='/licences'
                                icon={ShieldCheckIcon}
                            />
                        )}
                        {hasPermission('device') && (
                            <SidebarItem
                                label='sidebar:devices'
                                route='/devices'
                                icon={DeviceMobileIcon}
                            />
                        )}
                        {hasPermission('organizations') && (
                            <SidebarItem
                                label='sidebar:organizations'
                                route='/organizations'
                                icon={OfficeBuildingIcon}
                            />
                        )}
                    </SidebarItemHeader>
                )}
                <SidebarItemHeader label='sidebar:manager'>
                    {hasPermission('users') && (
                        <SidebarItem label='sidebar:users' route='/users' icon={UsersIcon} />
                    )}
                    {hasPermission('operators') && (
                        <SidebarItem
                            label='sidebar:operators'
                            route='/operators'
                            icon={UsersIcon}
                        />
                    )}
                    {hasPermission('missions') && (
                        <SidebarItem label='sidebar:missions' route='/missions' icon={TagIcon} />
                    )}
                    {hasPermission('locations') && (
                        <SidebarItem
                            label='sidebar:locations'
                            route='/locations'
                            icon={LocationMarkerIcon}
                        />
                    )}
                    {hasPermission('vehicles') && (
                        <SidebarItem label='sidebar:vehicles' route='/vehicles' icon={TruckIcon} />
                    )}
                    {selectedMission && (
                        <SidebarItem label='sidebar:logs' route='/logs' icon={DocumentIcon} />
                    )}
                </SidebarItemHeader>
                {selectedMission && (
                    <SidebarItemHeader label='sidebar:mission'>
                        {hasPermission('patients') && (
                            <SidebarItem
                                label='sidebar:patients'
                                route='/patients'
                                icon={UsersIcon}
                            />
                        )}
                        {hasPermission('triages') && (
                            <SidebarItem
                                label='sidebar:triages'
                                route='/triages'
                                icon={ClipboardCheckIcon}
                            />
                        )}
                        {hasPermission('checklists') && (
                            <SidebarItem
                                label='sidebar:checklists'
                                route='/checklists'
                                icon={CheckCircleIcon}
                            />
                        )}
                        {hasPermission('medevacs') && (
                            <SidebarItem
                                label='sidebar:medevacs'
                                route='/medevacs'
                                icon={OfficeBuildingIcon}
                            />
                        )}
                        {hasPermission('methanes') && (
                            <SidebarItem
                                label='sidebar:methanes'
                                route='/methanes'
                                icon={DocumentReportIcon}
                            />
                        )}
                        {hasPermission('nineLines') && (
                            <SidebarItem
                                label='sidebar:nineLines'
                                route='/ninelines'
                                icon={MapIcon}
                            />
                        )}
                        {hasPermission('deadBodies') && (
                            <SidebarItem
                                label='sidebar:deadBodies'
                                route='/deadBodies'
                                icon={XIcon}
                            />
                        )}
                        {hasPermission('patients') && (
                            <SidebarItem
                                label='sidebar:images'
                                route='/images'
                                icon={PhotographIcon}
                            />
                        )}
                    </SidebarItemHeader>
                )}
                {selectedMission && (
                    <SidebarItemHeader label='sidebar:others'>
                        {hasPermission('chat') && (
                            <SidebarItem label='sidebar:chat' route='/chat' icon={ChatIcon} />
                        )}
                        {hasPermission('locations') && (
                            <SidebarItem
                                label='sidebar:arcgis'
                                externalLink={
                                    (window as any)._env_?.ARCGIS_URL
                                        ? (window as any)._env_?.ARCGIS_URL
                                        : 'https://maps.urbyetorbit.it/arcgisportal/apps/experiencebuilder/experience/?id=ca0c146b3b854a0f9b369c1fc7e9ff0b'
                                }
                                icon={LinkIcon}
                            />
                        )}
                        {hasPermission('ticketing') && (
                            <SidebarItem
                                label='sidebar:Ticketing'
                                onClick={getTicketingLink}
                                icon={TicketIcon}
                                isLoading={isLoading}
                            />
                        )}
                    </SidebarItemHeader>
                )}
            </>
        );
    };

    // Render
    return (
        <div className='z-10 flex overflow-hidden bg-white'>
            {/* Mobile */}
            <Transition.Root show={props.sidebarOpen} as={Fragment}>
                <Dialog
                    as='div'
                    static
                    className='fixed inset-0 z-40 flex lg:hidden'
                    open={props.sidebarOpen}
                    onClose={props.setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter='transition ease-in-out duration-300 transform'
                        enterFrom='-translate-x-full'
                        enterTo='translate-x-0'
                        leave='transition ease-in-out duration-300 transform'
                        leaveFrom='translate-x-0'
                        leaveTo='-translate-x-full'>
                        <div className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-in-out duration-300'
                                enterFrom='opacity-0'
                                enterTo='opacity-100'
                                leave='ease-in-out duration-300'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'>
                                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                                    <button
                                        type='button'
                                        className='focus:outline-none ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-inset focus:ring-white'
                                        onClick={() => props.setSidebarOpen(false)}>
                                        <span className='sr-only'>Close sidebar</span>
                                        <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className='flex flex-shrink-0 items-center px-4'>
                                <div className='p-1'>
                                    <img
                                        className='h-10 w-auto cursor-pointer'
                                        src={logoINMM}
                                        alt='Maxxie'
                                    />
                                </div>
                                <div className='ml-3 font-semibold uppercase text-white'>
                                    Maxxie
                                </div>
                            </div>
                            <div className='mt-5 h-0 flex-1 overflow-y-auto'>
                                <nav className='px-2'>
                                    <div className='space-y-1'>{renderOptions()}</div>
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className='w-14 flex-shrink-0' aria-hidden='true' />
                </Dialog>
            </Transition.Root>

            {/* Desktop */}
            <div className='hidden h-screen md:flex md:flex-shrink-0'>
                <div className='flex w-56 flex-col'>
                    <div className='flex h-0 flex-1 flex-col'>
                        <div
                            className='flex h-14 flex-shrink-0 cursor-pointer items-center bg-gray-900 px-4'
                            onClick={() => {
                                history.replace({
                                    pathname: '/'
                                });
                            }}>
                            <div className='p-1'>
                                <img className='h-7 w-auto' src={logoINMM} alt='Maxxie' />
                            </div>
                            <div className='ml-3 font-semibold uppercase text-white'>Maxxie</div>
                        </div>
                        <div className='flex flex-1 flex-col overflow-y-auto'>
                            <nav className='flex-1 bg-gray-800 px-2 py-2'>
                                <div className='space-y-1'>{renderOptions()}</div>
                            </nav>
                        </div>
                        <div className='flex flex-shrink-0 bg-gray-700 px-4 py-3'>
                            <div className='group block w-full flex-shrink-0'>
                                <div className='flex justify-between'>
                                    <div className='flex'>
                                        <LanguageSelector />
                                        <div className='ml-1'>
                                            <p className='text-sm font-medium text-white'>{`${user?.firstName} ${user?.lastName}`}</p>
                                            <p className='text-xs font-medium text-gray-300 group-hover:text-gray-200'>
                                                {user?.email}
                                            </p>
                                        </div>
                                    </div>
                                    <LogoutIcon
                                        className='h-4 w-4 cursor-pointer text-white'
                                        onClick={() => dispatch(logout())}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
