// Hooks
import useCsv from '../hooks/useCsv';

// Components
import BaseButton, { ButtonType } from '../components/common/BaseButton';
import DetailsCard from '../components/common/DetailsCard';
import Gallery from '../components/common/Gallery';
import PageHeader from '../components/common/PageHeader';
import PdfDownloadButton from '../components/common/PdfDownloadButton';
import GeneralPdf from '../components/pdf/GeneralPdf';

// Others
import { DownloadIcon } from '@heroicons/react/outline';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router';

type GeneralDetailsPageProps = {
    /** Details info */
    details: any;
    /** Category headers */
    headers: {
        key: any;
        title: string;
    }[];
    /** Page title */
    title: string;
    /** Page subtitle */
    subtitle?: string;
    /** Base media path */
    basePath?: string;
    /** Image names */
    images?: string[];
};

/**
 * General details page
 * @description
 * Given an object, renders a generic details with information
 * grouped by the provided category headers.
 *
 * It's used to generate all details page of Maxxie other
 * then the PatientDetailsPage.
 */
function GeneralDetailsPagePage() {
    // Params
    const { state } = useLocation<GeneralDetailsPageProps>();

    // Hooks
    const generateCsv = useCsv();

    // Methods
    const renderDetails = () => {
        const details: React.ReactChild[] = [];

        for (const header of state.headers) {
            if (state.details[header.key])
                details.push(
                    <DetailsCard
                        key={header.key}
                        id={header.key}
                        title={header.title}
                        informations={state.details[header.key]}
                    />
                );
        }
        return details;
    };

    // Render
    return (
        <div>
            <PageHeader title={`generalDetails:${state.title}`} pretitle={state.subtitle}>
                <CSVLink filename='CSV Report' data={generateCsv(state.details)}>
                    <BaseButton
                        label='CSV'
                        className='ml-2'
                        type={ButtonType.SECONDARY}
                        onClick={() => {}}
                        icon={DownloadIcon}
                    />
                </CSVLink>
                <PdfDownloadButton
                    document={
                        <GeneralPdf
                            details={state.details}
                            title={state.title}
                            headers={state.headers}
                        />
                    }
                />
            </PageHeader>
            {/* <div className='w-full bg-red-100'>
                <PDFViewer style={{ width: '100%', height: 1000 }}>
                    <GeneralPdf
                        details={state.details}
                        title={state.title}
                        headers={state.headers}
                    />
                </PDFViewer>
            </div> */}
            {state.details.general && (
                <DetailsCard title='General' id='general' informations={state.details.general} />
            )}
            {renderDetails()}
            {state.images && state.basePath && (
                <Gallery images={state.images} basePath={state.basePath} />
            )}
        </div>
    );
}

export default GeneralDetailsPagePage;
