// Components
import { DoubleTableCell } from '../../components/tables/TableCell';

// Types
import { DeadBody } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const deadBodyColumns: ColumnConfig<DeadBody>[] = [
    {
        title: 'deadBodies:code',
        key: 'general.code',
        renderer: deadBody => (
            <DoubleTableCell
                first={deadBody.general.code}
                second={
                    deadBody.general.createdAt
                        ? new Date(deadBody.general.createdAt).toLocaleString()
                        : '-'
                }
            />
        )
    },
    { title: 'deadBodies:name', key: 'general.name' },
    { title: 'deadBodies:place', key: 'general.place' },
    { title: 'deadBodies:status', key: 'general.status' },
    { title: 'deadBodies:details', key: 'general.recoveryDetails' },
    { title: 'deadBodies:operatorId', key: 'general.operatorId' }
];
