// Types
import { Mpaws } from '../../../types/entities';

// Others
import { View, StyleSheet, Text } from '@react-pdf/renderer';

type SignsAndSymptomsT3cProps = {
    /** List of the PAWS Triages of the patient */
    paws: Mpaws[];
};

function getLocalizedDate(data: number) {
    const date = new Date(data);
    const formatted = `${date.toLocaleDateString(['en-GB'])} \n ${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    })}`;
    return formatted;
}

/**
 * Sign and Symptoms T3C
 * @description
 * Section Sign and Symptoms of the T3C
 *
 * This section contains a table that displays info about
 * the first three and the last Triages of the patient
 *
 * @param props
 * @returns
 */
function SignsAndSymptomsT3c(props: SignsAndSymptomsT3cProps) {
    // Methods
    const generateInternalRow = (
        key: string,
        color: string,
        firstCell: string,
        data?: string[]
    ) => {
        return (
            <View key={key} style={{ ...styles.internalRowTable, backgroundColor: color }}>
                <View style={styles.customCell}>
                    <Text style={styles.tabletext}>{firstCell}</Text>
                </View>
                <View style={styles.normalCell}>
                    <Text style={styles.tabletext}>{data && data.length > 0 ? data[0] : ' '}</Text>
                </View>
                <View style={styles.normalCell}>
                    <Text style={styles.tabletext}>{data && data.length > 1 ? data[1] : ' '}</Text>
                </View>
                <View style={styles.normalCell}>
                    <Text style={styles.tabletext}>{data && data.length > 2 ? data[2] : ' '}</Text>
                </View>
                <View style={styles.normalCell}>
                    <Text style={styles.tabletext}>
                        {data && data.length > 3 ? data[data.length - 1] : ' '}
                    </Text>
                </View>
            </View>
        );
    };

    // Render
    return (
        <View style={styles.container}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.capitalLetter}>Sign & symptoms:</Text>
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: 5
                }}>
                <View style={styles.firstRowTable}>
                    <View style={styles.firstCell}>
                        <Text style={styles.tabletext}>Time</Text>
                    </View>
                    <View style={styles.firstRowCell}>
                        <Text style={styles.timeText}>
                            {props.paws && props.paws?.length > 0
                                ? getLocalizedDate(props.paws[0].general.startTime)
                                : ' '}
                        </Text>
                    </View>
                    <View style={styles.firstRowCell}>
                        <Text style={styles.timeText}>
                            {props.paws && props.paws?.length > 1
                                ? getLocalizedDate(props.paws[0].general.startTime)
                                : ' '}{' '}
                        </Text>
                    </View>
                    <View style={styles.firstRowCell}>
                        <Text style={styles.timeText}>
                            {props.paws && props.paws?.length > 2
                                ? getLocalizedDate(props.paws[0].general.startTime)
                                : ' '}
                        </Text>
                    </View>
                    <View style={styles.lastCell}>
                        <Text style={styles.timeText}>
                            {' '}
                            {props.paws && props.paws?.length > 3
                                ? getLocalizedDate(
                                      props.paws[props.paws.length - 1].general.startTime
                                  )
                                : ' '}
                        </Text>
                    </View>
                </View>
                {generateInternalRow(
                    'Pulse',
                    '#D2D2D2',
                    'Pulse (Rate & Location)',
                    props.paws.map(
                        d =>
                            `${d.circulation?.heartRate || ''} ${
                                d.circulation?.evaluatedPulsePresence || ''
                            }`
                    )
                )}
                {generateInternalRow(
                    'Blood',
                    '#D2D2D2',
                    'Blood Pressure',
                    props.paws.map(d =>
                        d.circulation!.systolicBloodPressure
                            ? d.circulation!.systolicBloodPressure.toString()
                            : ''
                    )
                )}
                {generateInternalRow(
                    'RR',
                    '#FFFFFF',
                    'Respiratory Rate',
                    props.paws.map(d =>
                        d.respiration!.respiratoryRate
                            ? d.respiration!.respiratoryRate.toString()
                            : ''
                    )
                )}
                {generateInternalRow(
                    'Ox',
                    '#FFFFFF',
                    'Pulse Ox % O2 Sat',
                    props.paws.map(d => (d.respiration!.spo2 ? d.respiration!.spo2.toString() : ''))
                )}
                {generateInternalRow(
                    'AVPU',
                    '#D2D2D2',
                    'AVPU',
                    props.paws.map(d =>
                        d.airwaysManagement!.consciousness
                            ? d.airwaysManagement!.consciousness.toString()
                            : ''
                    )
                )}
                {generateInternalRow(
                    'Pain',
                    '#D2D2D2',
                    'Pain Scale (0-10)',
                    props.paws.map(d => (d.paws!.painScale ? d.paws!.painScale.toString() : ''))
                )}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: 5,
        paddingBottom: 8,
        flexDirection: 'column'
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    firstRowTable: {
        borderBottom: 1.5,
        display: 'flex',
        flexDirection: 'row'
    },
    internalRowTable: {
        borderBottom: 1.5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    capitalLetter: {
        marginTop: 5,
        fontWeight: 700,
        fontSize: 20,
        color: '#1F2937'
    },
    text: {
        alignSelf: 'flex-end',
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937'
    },
    tabletext: {
        alignSelf: 'flex-end',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        padding: 5
    },
    timeText: {
        alignSelf: 'center',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 11,
        color: '#1F2937',
        padding: 5
    },
    normalCell: {
        flex: 2,
        borderRight: 1.5
    },
    customCell: {
        flex: 3,
        borderRight: 1.5,
        borderLeft: 1.5
    },
    firstCell: {
        flex: 3,
        borderRight: 1.5,
        borderRightColor: 'white'
    },
    firstRowCell: {
        flex: 2,
        borderLeft: 1.5,
        borderTop: 1.5
    },
    lastCell: {
        flex: 2,
        borderLeft: 1.5,
        borderTop: 1.5,
        borderRight: 1.5
    }
});

export default SignsAndSymptomsT3c;
