// React
import { useMemo, useState } from 'react';

// Hooks
import { useAppSelector } from '../../hooks/useAppSelector';

// Api
import useGetQuery from '../../api/useGetQuery';
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput, FormSelect } from '../forms/FormInput';

// Config
import { GET_ORGANIZATIONS, POST_USER } from '../../config/endpoints';

// Utils
import { isHigherOrEqualRole } from '../../utils/role';

// Types
import { Organization, User } from '../../types/entities';
import { ROLE } from '../../types/enums';
import { PostUserRequest } from '../../types/requests';

const roleOptions = Object.keys(ROLE).map(role => ({
    id: role,
    name: role
}));

type UserFormProps = {
    /** Usert to update */
    initialUser?: User;
    /** Called on submit or cancel */
    onClose?: () => void;
};

function fromUserToDto(user: User): PostUserRequest {
    return {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        password: user.password,
        username: user.username
    };
}

/**
 * User form
 * @description
 * Form used to create or update an user.
 *
 * Uses HTML validation.
 * @param props
 */
function UserForm(props: UserFormProps) {
    // Global state
    const loggedUser = useAppSelector(state => state.authentication.user);
    const currentOrganization = useAppSelector(state => state.status.selectedOrganization!);

    // State
    const [user, setUser] = useState<PostUserRequest>(
        props.initialUser
            ? fromUserToDto(props.initialUser)
            : ({ role: ROLE.OPERATOR, organizationId: currentOrganization.id } as PostUserRequest)
    );
    const [selectedRole, setSelectedRole] = useState<ROLE>(user.role);
    const [selectedOrganization, setSelectedOrganization] =
        useState<Organization>(currentOrganization);

    // Api
    const organizationsQuery = useGetQuery<Organization[]>(['organizations'], GET_ORGANIZATIONS, {
        enable: isHigherOrEqualRole(selectedRole, ROLE.ADMIN)
    });

    const userMutation = usePostMutation<PostUserRequest>(POST_USER, {
        invalidateQueries: ['users']
    });

    // Memos
    const availableRoles = useMemo(
        () =>
            roleOptions.filter(r => {
                if (isHigherOrEqualRole(r.id as ROLE, loggedUser!.role)) return false;
                return true;
            }),
        [loggedUser?.role]
    );

    const canUpdateOrganization = useMemo<boolean>(() => {
        if (loggedUser?.role === ROLE.SUPER_ADMIN) {
            if (!isHigherOrEqualRole(selectedRole, ROLE.SUPER_ADMIN)) return true;
        }
        return false;
    }, [loggedUser, selectedRole]);

    // Methods
    const handlePostRequest = () => {
        if (selectedRole) {
            const data: PostUserRequest = {
                ...user,
                role: selectedRole,
                organizationId: canUpdateOrganization ? selectedOrganization.id : undefined
            };
            userMutation
                .mutateAsync(data)
                .then(() => {
                    props.onClose && props.onClose();
                })
                .catch(() => {});
        }
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='users:firstName'
                    value={user.firstName || ''}
                    onChange={text => setUser({ ...user, firstName: text })}
                />
                <FormInput
                    label='users:lastName'
                    value={user.lastName || ''}
                    onChange={text => setUser({ ...user, lastName: text })}
                />
                <FormInput
                    label='users:username'
                    value={user.username || ''}
                    onChange={text => setUser({ ...user, username: text })}
                />
                <FormInput
                    label='users:email'
                    value={user.email || ''}
                    contentType='email'
                    onChange={text => setUser({ ...user, email: text })}
                />
                {props.initialUser ? (
                    <FormInput
                        label='users:password'
                        contentType='password'
                        optional
                        onChange={text => setUser({ ...user, password: text })}
                    />
                ) : (
                    <FormInput
                        label='users:password'
                        contentType='password'
                        onChange={text => setUser({ ...user, password: text })}
                    />
                )}
                <FormSelect
                    label='users:role'
                    items={availableRoles}
                    selected={{ id: selectedRole, name: selectedRole }}
                    setSelected={role => setSelectedRole(role.id)}
                />
                {canUpdateOrganization && (
                    <FormSelect
                        label='users:organization'
                        items={organizationsQuery.data || [selectedOrganization]}
                        selected={selectedOrganization}
                        setSelected={setSelectedOrganization}
                    />
                )}
            </div>
            <div className='bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialUser ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={userMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default UserForm;
