// Hooks
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

// Store
import { setSelectedChat } from '../../store/slices/statusSlice';

// Components
import LocaleText from '../common/LocaleText';

// Utils
import { getDateFromString, isDate } from '../../utils/dates';

// Types
import { CHAT_MEDIA_TYPE } from '../../types/enums';
import { Chat, Message } from '../../types/socketio';

// Others
import { useTranslation } from 'react-i18next';

type ChatsListProps = {
    chats?: Chat[];
};

/**
 * Chat list
 * @description
 * List of all the chat for the logged user.
 *
 * Every chat can be selected, but messages can only
 * be sent on the active ones.
 *
 * Shows the number of unread messages for every chat
 * @param props
 */
function ChatsList(props: ChatsListProps) {
    // Hooks
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // Global state
    const selectedChat = useAppSelector(state => state.status.selectedChat);

    // Methods
    const isChatSelected = (chatId: string) => {
        if (selectedChat === null && props.chats?.length && props.chats[0].id === chatId)
            return true;
        else if (selectedChat?.id === chatId) return true;
        return false;
    };

    const getLatestChatMessage = (chat: Chat) => {
        if (chat.messages.length > 0) {
            return chat.messages.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            });
        } else return null;
    };

    const getMessagePreview = (message: Message) => {
        switch (message.mediaType) {
            case CHAT_MEDIA_TYPE.AUDIO:
                return t('chat:audio');
            case CHAT_MEDIA_TYPE.IMAGE:
                return t('chat:image');
            default:
                return message.text;
        }
    };

    const getUnreadCount = (chat: Chat) => {
        if (chat.isClosed) return 0;
        let unread = 0;
        const lastRead = localStorage.getItem(chat.id);

        if (!lastRead || !isDate(lastRead)) return chat.messages.length;

        for (const message of chat.messages) {
            new Date(message.timestamp).getTime() > new Date(lastRead).getTime() &&
                message.type === 'OPERATOR' &&
                unread++;
        }
        return unread;
    };

    const selectChat = (chat: Chat) => {
        dispatch(setSelectedChat(chat));
        localStorage.setItem(chat.id, new Date().toISOString());
    };

    const renderChat = (chat: Chat, index: number) => {
        const latestMessage = getLatestChatMessage(chat);
        return (
            <div
                key={index}
                className={`relative cursor-pointer bg-white py-5 px-6 hover:bg-gray-50 
                    ${
                        isChatSelected(chat.id)
                            ? 'border-l-2 border-secondary'
                            : 'border-l-2 border-gray-200'
                    }`}
                onClick={() => {
                    selectChat(chat);
                }}>
                {getUnreadCount(chat) > 0 && (
                    <span className='absolute top-4 right-3 items-center rounded-full bg-secondary px-2.5 py-0.5 text-xs font-medium text-gray-200'>
                        {getUnreadCount(chat)}
                    </span>
                )}
                <div className='flex justify-between'>
                    <div className='min-w-0 flex-1'>
                        <div className='focus:outline-none block'>
                            <span className='absolute inset-0' aria-hidden='true' />
                            <p className='truncate text-sm font-medium text-gray-900'>{`Operator ${chat.operatorId}`}</p>
                            <p className='mt-1 truncate text-sm text-gray-500'>
                                {latestMessage
                                    ? getMessagePreview(latestMessage)
                                    : t('chat:noMessage')}
                            </p>
                        </div>
                    </div>
                    <div className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500'></div>
                </div>
                {latestMessage && (
                    <p className='line-clamp-2 text-sm text-gray-600'>
                        {getDateFromString(latestMessage.timestamp).toLocaleString()}
                    </p>
                )}
            </div>
        );
    };

    // Render
    return (
        <aside className='order-first block flex-shrink-0'>
            <div className='relative h-full w-40 border-r border-gray-200 bg-gray-100 sm:w-60 md:w-72 lg:w-96'>
                <div className='flex-shrink-0'>
                    <div className='flex h-16 flex-col justify-center bg-white px-6'>
                        <h2 className='text-lg font-medium text-gray-900'>Chats</h2>
                    </div>
                </div>
                <LocaleText
                    className='border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500'
                    text='chat:active'
                />
                <div
                    aria-label='Message list'
                    className='min-h-0 no-scrollbar flex-1 overflow-y-auto'>
                    <div className='divide-y divide-gray-200 border-b border-gray-200'>
                        {props.chats &&
                            props.chats.map(
                                (chat, index) => !chat.isClosed && renderChat(chat, index)
                            )}
                    </div>
                </div>
                <LocaleText
                    className='border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500'
                    text='chat:archived'
                />
                <div
                    aria-label='Message list'
                    className='min-h-0 no-scrollbar flex-1 overflow-y-auto'>
                    <div className='divide-y divide-gray-200 border-b border-gray-200'>
                        {props.chats &&
                            props.chats.map(
                                (chat, index) => chat.isClosed && renderChat(chat, index)
                            )}
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default ChatsList;
