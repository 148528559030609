// React
import { useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput } from '../forms/FormInput';

// Config
import { POST_DEVICE } from '../../config/endpoints';

// Types
import { Device } from '../../types/entities';

// Others
import { PostDeviceRequest } from '../../types/requests';

type DeviceFormProps = {
    /** Device to update */
    initialDevice?: Device;
    /** Called on submit or cancel */
    onClose?: () => void;
};

/**
 * Device form
 * @description
 * Form used to create or update a device.
 *
 * Uses HTML validation.
 * @param props
 */
function DeviceForm(props: DeviceFormProps) {
    // State
    const [device, setDevice] = useState<PostDeviceRequest>(
        props.initialDevice ? props.initialDevice : ({} as PostDeviceRequest)
    );

    // Query
    const deviceMutation = usePostMutation<PostDeviceRequest>(POST_DEVICE, {
        invalidateQueries: ['devices']
    });

    // Methods
    const handlePostRequest = () => {
        deviceMutation
            .mutateAsync(device)
            .then(() => {
                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='devices:name'
                    value={device.name || ''}
                    onChange={text => setDevice({ ...device, name: text })}
                />
                <FormInput
                    label='devices:uuid'
                    value={device.uuid || ''}
                    onChange={text => setDevice({ ...device, uuid: text })}
                />
            </div>
            <div className='rounded-bl-lg rounded-br-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialDevice ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={deviceMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default DeviceForm;
