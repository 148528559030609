// React
import { ReactNode, useMemo } from 'react';

// Components
import LocaleText from '../common/LocaleText';

// Navigation
import { Link, useLocation } from 'react-router-dom';

// Others
import { HomeIcon } from '@heroicons/react/outline';
import { ColorRing } from 'react-loader-spinner';

type SidebarItemProps = {
    /** Item label */
    label: string;
    /** Path to one of the internal routes */
    route?: string;
    /** Link to an external site */
    externalLink?: string;
    /** Show a loading indicator */
    isLoading?: boolean;
    /** Called when the item is clicked */
    onClick?: () => void;
    /** Icon component */
    icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

/**
 * One of the items used inside the Sidebar
 * @param props
 */
function SidebarItem(props: SidebarItemProps) {
    // Hooks
    const location = useLocation();

    // Memos
    const isSelected = useMemo<boolean | undefined>(() => {
        if (location.pathname === '/') return false;
        return props.route?.toLowerCase().includes(location.pathname.toLowerCase());
    }, [props.route, location.pathname]);

    // Methods
    const renderButton = (children: ReactNode) => {
        if (props.onClick) {
            return (
                <div
                    onClick={props.onClick}
                    className={`group flex cursor-pointer items-center rounded-md px-2 py-1.5 text-sm font-medium ${
                        isSelected
                            ? 'hover-bg-gray-900 bg-gray-700 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    }`}>
                    {children}
                </div>
            );
        } else if (props.externalLink)
            return (
                <div
                    onClick={() => {
                        window.open(props.externalLink, '_blank', 'noopener,noreferrer');
                    }}
                    className={`group flex cursor-pointer items-center rounded-md px-2 py-1.5 text-sm font-medium ${
                        isSelected
                            ? 'hover-bg-gray-900 bg-gray-700 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    }`}>
                    {children}
                </div>
            );
        else
            return (
                <Link
                    to={props.route ? props.route : '/'}
                    className={`group flex items-center rounded-md px-2 py-1.5 text-sm font-medium ${
                        isSelected
                            ? 'hover-bg-gray-900 bg-gray-700 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    }`}>
                    {children}
                </Link>
            );
    };

    // Render
    return renderButton(
        <>
            {props.icon ? (
                <props.icon
                    className='ml-3 mr-3 h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-300'
                    aria-hidden='true'
                />
            ) : (
                <HomeIcon
                    className='mr-3 h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-300'
                    aria-hidden='true'
                />
            )}
            <div className='flex flex-1 justify-between pr-5'>
                <LocaleText text={props.label} />
                {props.isLoading && (
                    <ColorRing
                        wrapperClass='flex-shrink-0 h-5 w-5 -mr-3'
                        colors={['white', 'white', 'white', 'white', 'white']}
                    />
                )}
            </div>
        </>
    );
}

export default SidebarItem;
