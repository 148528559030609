// Components
import BaseButton from '../common/BaseButton';

// Others
import { Dialog } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

type DeleteConfirmationFormProps = {
    /** Modal title */
    title?: string;
    /** Modal text content */
    body?: string;
    /** Confirmation button text */
    buttonText?: string;
    /** Indicates if the confirm button should be loading */
    isLoading?: boolean;
    /** Called on cancel press */
    onClose: () => void;
    /** Called on confirm press */
    onConfirm: () => void;
};

/**
 * Confirm deletion
 * @description
 * This modal shows when the user attempts to delete
 * a resource from the system and asks confirmation.
 * @param props
 */
function DeleteConfirmationForm(props: DeleteConfirmationFormProps) {
    // Hooks
    const { t } = useTranslation();

    // Render
    return (
        <div className='inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle'>
            <div>
                <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-200'>
                    <ExclamationCircleIcon
                        className='h-10 w-10 text-yellow-600'
                        aria-hidden='true'
                    />
                </div>
                <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                        {t(props.title || 'general:deleteConfirm')}
                    </Dialog.Title>
                    <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                            {t(props.body || 'general:deleteConfirmBody')}
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                <BaseButton
                    className='focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:opacity-70 sm:col-start-2 sm:text-sm'
                    onClick={props.onConfirm}
                    label={props.buttonText || 'general:delete'}
                    overrideAll
                    isLoading={props.isLoading}
                />
                <BaseButton
                    className='focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm'
                    onClick={() => {
                        if (!props.isLoading) props.onClose();
                    }}
                    label='general:cancel'
                    overrideAll
                />
            </div>
        </div>
    );
}

export default DeleteConfirmationForm;
