// Components
import { LocalizedTableCell } from '../../components/tables/TableCell';

// Types
import { Checklist } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const checklistColumns: ColumnConfig<Checklist>[] = [
    {
        title: 'checklists:type',
        key: 'general.type',
        renderer: item => <LocalizedTableCell text={`general:${item.general.type}`} />
    },
    { title: 'general:createdAt', key: 'general.createdAt' },
    { title: 'checklists:vehicleId', key: 'general.vehicleId' },
    { title: 'checklists:operatorId', key: 'general.operatorId' }
];
