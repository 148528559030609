// Components
import { ColorTableCell, TagTableCell } from '../../components/tables/TableCell';

// Types
import { Fas, Sas } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const assessmentColumns: ColumnConfig<Fas | Sas>[] = [
    {
        title: 'assessments:color',
        key: 'general.color',
        renderer: assessment => (
            <ColorTableCell text={assessment.general.color} color={assessment.general.color} />
        )
    },
    { title: 'assessments:start', key: 'general.startTime' },
    { title: 'assessments:end', key: 'general.endTime' },
    {
        title: 'assessments:type',
        key: 'general.assessmentType',
        renderer: assessment => (
            <TagTableCell text={`general:${assessment.general.assessmentType}`} />
        )
    },
    { title: 'assessments:patient', key: 'general.patCode' },
    { title: 'assessments:operator', key: 'general.operatorId' }
];
