// Components
import { PaginationCell, PaginationIconCell } from '../../components/tables/TableCell';

// Others
import { PaginationConfig, RowColors } from '@edalab/react-tables';

export const rowColors: RowColors = {
    primary: 'bg-gray-50',
    secondary: 'bg-white',
    hover: 'bg-gray-100'
};

export const paginationConfig: PaginationConfig = {
    entryPerPage: parseInt((window as any)._env_?.ENTRY_PER_PAGE) || 8,
    renderer: (text, isCurrent) => <PaginationCell text={text} isCurrent={isCurrent} />,
    iconRenderer: children => <PaginationIconCell>{children}</PaginationIconCell>
};
