// Types
import { Device } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const devicesColumns: ColumnConfig<Device>[] = [
    { title: 'devices:uuid', key: 'uuid' },
    { title: 'devices:name', key: 'name' }
];
