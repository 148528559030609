// React
import { useMemo } from 'react';

// Hooks
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

// Store
import { setSelectedMission } from '../../store/slices/statusSlice';

// Api
import useGetQuery from '../../api/useGetQuery';
import { useQueryClient } from 'react-query';

// Components
import NavbarDropdown from './NavbarDropdown';

// Config
import { GET_MISSIONS } from '../../config/endpoints';

// Types
import { Mission } from '../../types/entities';
import { STORAGE_KEY } from '../../types/enums';

// Others
import { TagIcon } from '@heroicons/react/outline';

/**
 * Mission selector
 * @description
 * Selects a mission from the available ones. When there is a change,
 * all of the dashboard content updates to show only the
 * data relative to the requested mission.
 *
 * This selector is available to all the dahboard users.
 */
function MissionSelector() {
    // Hook
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    // State
    const selectedMission = useAppSelector(state => state.status.selectedMission!);

    // Api
    const missionsQuery = useGetQuery<Mission[]>(['missions'], GET_MISSIONS);

    // Memos
    const validMissions = useMemo<Mission[]>(
        () =>
            missionsQuery.data
                ? missionsQuery.data.filter(m => !m.isArchiving && !m.endTime)
                : [selectedMission],
        [missionsQuery.data]
    );

    // Methods
    const changeMission = (mission: Mission) => {
        localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, mission.id.toString());
        queryClient.invalidateQueries();
        dispatch(setSelectedMission(mission));
    };

    // Render
    return (
        <NavbarDropdown
            items={validMissions}
            selected={selectedMission}
            setSelected={changeMission}
            icon={TagIcon}
            label='infobar:selectedMission'
        />
    );
}

export default MissionSelector;
