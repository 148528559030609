// React
import { useEffect, useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import OrganizationForm from '../components/forms/OrganizationForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_ORGANIZATIONS } from '../config/endpoints';
import { organizationsColumns } from '../config/tables/organizations';

// Types
import { Organization } from '../types/entities';

// Others
import { Table, TableView } from '@edalab/react-tables';

/**
 * Organizations page
 * @description
 * Ordered and filterable list of organizations.
 * This list is global and shared between all organizations.
 *
 * Organizations are group of users coordinates by a manager
 * that shares a set of missions.
 *
 * Only super admins can create or update organizations.
 * Organizations can not be deleted.
 */
function OrganizationsPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(organizationsColumns, 0);

    // State
    const [open, setOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization>();

    // Reference
    const tableView = useRef<TableView>(null);

    // Api
    const organizationsQuery = useGetQuery<Organization[]>(['organizations'], GET_ORGANIZATIONS);

    // Memos
    const organizations = useMemo<Organization[] | undefined>(() => {
        if (!organizationsQuery.data) return;
        tableView.current?.changePage(1);
        return applyFilter(organizationsQuery.data);
    }, [organizationsQuery.data, filter]);

    // Effects
    useEffect(() => {
        if (!open && !!selectedOrganization) setSelectedOrganization(undefined);
    }, [open]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <OrganizationForm
                    onClose={() => setOpen(false)}
                    initialOrganization={selectedOrganization}
                />
            </BaseModal>
            <PageHeader
                title='organizations:title'
                buttons={['general:add']}
                onButtonPress={() => setOpen(true)}
            />
            <TableFilter columns={organizationsColumns} filter={filter} setFilter={setFilter} />
            <Table<Organization>
                ref={tableView}
                columns={organizationsColumns}
                baseRowClasses='cursor-pointer'
                onCellPress={item => {
                    setSelectedOrganization(item);
                    setOpen(true);
                }}
                data={organizations}
            />
        </div>
    );
}

export default OrganizationsPage;
