// Others
import { View, Text, StyleSheet } from '@react-pdf/renderer';

/**
 * Footer T3C
 * @description
 * This is the last section of the pages
 *
 * This section will contain static text
 */
function FooterT3c() {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.container}>
                <Text style={styles.text}>DD Form 1380, JUN 2014 </Text>
            </View>
            <View style={styles.container}>
                <Text style={styles.endText}> TCCC CARD</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        position: 'absolute',
        bottom: 10,
        left: 10
    },
    text: {
        fontSize: 8,
        marginRight: 400,
        color: '#9CA3AF'
    },
    padding: {
        flexGrow: 3
    },
    endText: {
        fontSize: 8,
        alignSelf: 'flex-end',
        flex: 1,
        color: '#9CA3AF'
    },
    container: { display: 'flex', flexGrow: 1 }
});

export default FooterT3c;
