// React
import { Switch as HUISwitch } from '@headlessui/react';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

type SwitchProps = {
    /** Switch value */
    enabled: boolean;
    /** Called when switch value changes */
    setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Simple switch component with default styling
 * @param props
 */
function Switch(props: SwitchProps) {
    return (
        <HUISwitch
            checked={props.enabled}
            onChange={props.setEnabled}
            className={classNames(
                props.enabled ? 'bg-secondary' : 'bg-gray-200',
                'focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-secondary focus:ring-offset-2'
            )}>
            <span className='sr-only'>Use setting</span>
            <span
                className={classNames(
                    props.enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}>
                <span
                    className={classNames(
                        props.enabled
                            ? 'opacity-0 duration-100 ease-out'
                            : 'opacity-100 duration-200 ease-in',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden='true'>
                    <svg className='h-3 w-3 text-gray-400' fill='none' viewBox='0 0 12 12'>
                        <path
                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                            stroke='currentColor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </span>
                <span
                    className={classNames(
                        props.enabled
                            ? 'opacity-100 duration-200 ease-in'
                            : 'opacity-0 duration-100 ease-out',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden='true'>
                    <svg className='h-3 w-3 text-secondary' fill='currentColor' viewBox='0 0 12 12'>
                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                    </svg>
                </span>
            </span>
        </HUISwitch>
    );
}

export default Switch;
