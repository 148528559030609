// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { DELETE_METHANES, GET_METHANES } from '../config/endpoints';
import { methaneColumns } from '../config/tables/methanes';

// Types
import { Methane } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

type MethanesPageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

const detailsHeaders = [
    { key: 'majorIncident', title: 'M - Major incident', subtitle: 'Detailed informations' },
    { key: 'exactLocation', title: 'E - Exact location', subtitle: 'Detailed informations' },
    { key: 'types', title: 'T - Type of incident', subtitle: 'Detailed informations' },
    { key: 'accessLocation', title: 'A - Access locations', subtitle: 'Detailed informations' },
    { key: 'hazards', title: 'H - Hazards', subtitle: 'Detailed informations' },
    { key: 'numberOfPatients', title: 'N- Number of patients', subtitle: 'Detailed informations' },
    { key: 'extraResources', title: 'E - Extra Resources', subtitle: 'Detailed informations' }
];

/**
 * Methanes page
 * @description
 * Ordered and filterable list of methanes.
 * Only methanes relative to the current selection mission
 * will be shown.
 *
 * The user can remove one of all of the methanes.
 */
function MethanesPage(props: MethanesPageProps) {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(methaneColumns, 0, [
        { colKey: 'numberOfPatients.number', locKey: 'numberOfPatients' }
    ]);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const methanesQuery = useGetQuery<Methane[]>(['methanes'], GET_METHANES);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_METHANES, {
        invalidateQueries: ['methanes']
    });

    // Memos
    const methanes = useMemo<Methane[] | undefined>(() => {
        if (!methanesQuery.data) return;
        let methanes = (methanesQuery.data || []).sort((a, b) =>
            a.general.createdAt > b.general.createdAt ? -1 : 1
        );

        methanes = applyFilter(methanes);
        tableView.current?.changePage(1);

        return props.numberOfRows ? methanes.slice(0, props.numberOfRows) : methanes;
    }, [methanesQuery.data, filter]);

    // Methods
    const navigate = (methane: Methane) => {
        history.push({
            pathname: '/methanes/' + methane.id,
            state: {
                details: {
                    ...methane,
                    exactLocation: {
                        image: `BACKEND/media/methanes/${methane.id}/map_e.png`
                    },
                    accessLocation: {
                        image: `BACKEND/media/methanes/${methane.id}/map_a.png`
                    }
                },
                headers: detailsHeaders,
                title: 'methaneDetails'
            }
        });
    };

    const deleteMethanes = () => {
        if (toBeDeleted.length > 0)
            deleteMutation
                .mutateAsync({ ids: toBeDeleted })
                .then(() => {
                    setToBeDeleted([]);
                    setDeleteOpen(false);
                })
                .catch(() => {});
    };

    const onButtonPress = () => {
        if (methanesQuery.data) {
            setToBeDeleted(methanesQuery.data.map(m => m.id));
            setDeleteOpen(true);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={deleteMethanes}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='methanes:title'
                buttons={!props.numberOfRows ? ['general:deleteAll'] : []}
                onButtonPress={onButtonPress}
            />
            <TableFilter columns={methaneColumns} filter={filter} setFilter={setFilter} />
            <Table<Methane>
                ref={tableView}
                columns={methaneColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!methanes
                        ? [
                              methane => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([methane.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                data={methanes}
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default MethanesPage;
