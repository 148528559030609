// Components
import { TagTableCell } from '../../components/tables/TableCell';

// Types
import { AppLog } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const appLogColumns: ColumnConfig<AppLog>[] = [
    { title: 'general:createdAt', key: 'timestamp' },
    { title: 'logs:resource', key: 'resource' },
    {
        title: 'logs:operation',
        key: 'operation',
        renderer: log => <TagTableCell text={`logs:${log.operation}`} />
    },
    { title: 'logs:operatorId', key: 'operatorId' },
    {
        title: 'logs:operatorRole',
        key: 'operatorRole',
        renderer: log => <TagTableCell text={`operators:${log.operatorRole}`} />
    },
    { title: 'logs:targetId', key: 'targetId' }
];
