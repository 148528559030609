import { StyleSheet, Text, View } from '@react-pdf/renderer';

/**
 * First Table Treatments T3C
 * @description
 * This is the first table of the treatments section of the T3C
 * @returns
 */
function FirstTableTreatmentsT3c() {
    // Methods
    const generateSmallColumns = (
        firstCell: string,
        secondCell?: string,
        thirdCell?: string,
        fourthCell?: string,
        fifthCell?: string
    ) => {
        return (
            <View key={firstCell} style={styles.firstCell}>
                <View style={styles.cell}>
                    <Text style={styles.text}>{firstCell}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{secondCell ? secondCell : ' '}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{thirdCell ? thirdCell : ' '}</Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.text}>{fourthCell ? fourthCell : ' '}</Text>
                </View>
                <View style={styles.cellwithBorder}>
                    <Text style={styles.text}>{fifthCell ? fifthCell : ' '}</Text>
                </View>
            </View>
        );
    };

    // Render
    return (
        <View style={styles.container}>
            <View style={styles.firstRow}>
                <View style={styles.tableHeader}>
                    <Text style={styles.text}>C:</Text>
                </View>
                <View style={styles.headerGray}>
                    <Text style={styles.text}>Fluid</Text>
                </View>
                <View style={styles.header}>
                    <Text style={styles.text}>Blood Product</Text>
                </View>
            </View>
            <View style={styles.secondRow}>
                <View style={styles.cell}>
                    <Text style={styles.text}>Name</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.cellwithBorder}>
                    <Text style={styles.text}> </Text>
                </View>
            </View>

            {generateSmallColumns('Volume')}
            {generateSmallColumns('Route')}
            {generateSmallColumns('Time')}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
        borderRight: 1
    },
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        alignSelf: 'center',
        padding: 5
    },
    firstRow: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2
    },
    greyRow: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1,
        backgroundColor: '#D2D2D2'
    },
    firstCell: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    cell: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1
    },
    cellwithBorder: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1,
        borderBottom: 1
    },
    secondRow: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3
    },
    tableHeader: {
        flex: 1,
        borderLeft: 1,
        borderLeftColor: 'white',
        borderTop: 1,
        borderTopColor: 'white'
    },
    headerGray: {
        flex: 2,
        borderLeft: 1,
        borderTop: 1,
        backgroundColor: '#D2D2D2',
        justifyContent: 'center'
    },
    header: {
        flex: 2,
        borderLeft: 1,
        borderTop: 1,
        borderBottom: 1,
        justifyContent: 'center'
    }
});
export default FirstTableTreatmentsT3c;
