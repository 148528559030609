// React
import { useState } from 'react';

// Hooks
import { useAppSelector } from '../../hooks/useAppSelector';

// Components
import BaseModal from '../common/BaseModal';
import LiveUpdatesIndicator from '../common/LiveUpdatesIndicator';
import MissionSelector from '../common/MissionSelector';
import OrganizationSelector from '../common/OrganizationSelector';
import InfoForm from '../forms/InfoForm';

// Utils
import { isHigherOrEqualRole } from '../../utils/role';

// Types
import { ROLE } from '../../types/enums';

// Others
import { InformationCircleIcon } from '@heroicons/react/outline';

/**
 * Navigation bar for the authenticated user
 * @description
 * Allows the user to:
 * - access UserMenu
 * - change selected organization (if role is admin or greater)
 * - change selected mission
 */
function Navbar() {
    // Global state
    const selectedMission = useAppSelector(state => state.status.selectedMission);
    const user = useAppSelector(state => state.authentication.user);

    // State
    const [modalOpen, setModalOpen] = useState(false);

    // Methods
    const openDialog = () => setModalOpen(true);

    // Render
    return (
        <div className='mx-auto h-14 w-full border-b border-gray-300 bg-white px-4 sm:px-6 lg:px-8'>
            <BaseModal open={modalOpen} setOpen={setModalOpen}>
                <InfoForm />
            </BaseModal>
            <div className='flex h-full items-center justify-between'>
                <div className='flex space-x-5'>
                    {isHigherOrEqualRole(user?.role || ROLE.OPERATOR, ROLE.ADMIN) && (
                        <OrganizationSelector />
                    )}
                    {selectedMission && <MissionSelector />}
                </div>
                <div className='flex items-center space-x-4'>
                    <LiveUpdatesIndicator />
                    {isHigherOrEqualRole(user?.role || ROLE.OPERATOR, ROLE.MANAGER) && (
                        <InformationCircleIcon
                            className='h-5 w-5 cursor-pointer text-secondary'
                            onClick={openDialog}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Navbar;
