// GET
export const GET_USER = 'users/logged';
export const GET_MISSIONS = 'missions';
export const GET_ORGANIZATIONS = 'organizations';
export const GET_USERS = 'users';
export const GET_OPERATORS = 'operators';
export const GET_GROUPS = 'groups';
export const GET_LICENCES = 'licences';
export const GET_ROLE_MODULES = 'roles/modules';
export const GET_DEVICES = 'devices';
export const GET_PATIENTS = 'patients';
export const GET_DEAD_BODIES = 'deadBodies';
export const GET_TRIAGES = 'triages';
export const GET_LOCATIONS = 'locations';
export const GET_VEHICLES = 'vehicles';
export const GET_CHECKLISTS = 'checklists';
export const GET_MEDEVACS = 'medevacs';
export const GET_METHANES = 'methanes';
export const GET_NINE_LINE = 'nineLines';
export const GET_CHATS = 'chat';
export const GET_APP_LOGS = 'logs/app';
export const GET_DASHBOARD_LOGS = 'logs/dashboard';
export const GET_AVAILABLE_IMAGES = 'patients/images';
export const GET_PATIENT = 'patients/:code';
export const GET_PATIENT_IMAGES = 'patients/images/:code';
export const GET_PATIENT_TRIAGES = 'triages/:id';
export const GET_DEAD_BODY_IMAGES = 'deadBodies/images/:code';
export const GET_PATIENT_ASSESSMENTS = 'assessments/:id';
export const GET_CONTACTS = 'locations/:locationId/contacts';
export const GET_VERSIONS = 'versions';

// POST
export const POST_LOGIN = 'auth/login';
export const POST_REFRESH = 'auth/refresh';
export const POST_MISSIONS = 'missions';
export const POST_ORGANIZATION = 'organizations';
export const POST_OPERATOR = 'operators';
export const POST_USER = 'users';
export const POST_DEVICE = 'devices';
export const POST_LICENCES = 'licences';
export const POST_LOCATION = 'locations';
export const POST_VEHICLES = 'vehicles';
export const POST_CHAT = 'chat/message';
export const POST_DIRECT = 'direct';
export const POST_CONTACT = 'locations/:locationId/contacts';

// DELETE
export const DELETE_DEVICES = 'devices';
export const DELETE_OPERATORS = 'operators';
export const DELETE_USERS = 'users';
export const DELETE_LOCATIONS = 'locations';
export const DELETE_VEHICLES = 'vehicles';
export const DELETE_PATIENTS = 'patients';
export const DELETE_CHECKLISTS = 'checklists';
export const DELETE_MEDEVACS = 'medevacs';
export const DELETE_NINE_LINES = 'nineLines';
export const DELETE_METHANES = 'methanes';
export const DELETE_DEAD_BODIES = 'deadBodies';
export const DELETE_CONTACTS = 'locations/:locationId/contacts';
export const DELETE_ARCHIVE_MISSION = 'missions';
