// React
import { useMemo, useRef } from 'react';

// Hooks
import useLogin from '../../hooks/useLogin';

// Components
import BaseButton from '../common/BaseButton';
import LocaleText from '../common/LocaleText';
import { FormInput } from '../forms/FormInput';

// Types
import { LoginRequest } from '../../types/requests';

/**
 * Login form
 * @description
 * Allows user to insert credentials in order to authenticate
 * with the system
 */
function LoginForm() {
    // Query
    const { login } = useLogin();

    // State
    const emptyCredentials = useMemo<LoginRequest>(() => ({} as LoginRequest), []);
    const credentials = useRef<LoginRequest>(emptyCredentials);

    // Methods
    const handleAuthRequest = () => {
        login(credentials.current);
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handleAuthRequest();
            }}
            className='mt-8 space-y-6 rounded-md bg-white px-10 pt-2 pb-8 shadow'>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='-space-y-px rounded-md rounded-t-lg bg-white shadow-sm'>
                <FormInput
                    label='Username'
                    onChange={text => {
                        credentials.current.username = text;
                    }}
                />
                <FormInput
                    label='Password'
                    contentType='password'
                    onChange={text => {
                        credentials.current.password = text;
                    }}
                />
            </div>

            <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <input
                        id='remember_me'
                        name='remember_me'
                        type='checkbox'
                        className='h-4 w-4 rounded border-gray-300 text-secondary focus:ring-indigo-500'
                        defaultChecked
                    />
                    <LocaleText
                        text='login:remember'
                        className='ml-2 block text-sm text-gray-900'
                    />
                </div>
            </div>

            <div>
                <BaseButton label='login:login' className='sm:w-full' inputType='submit' />
            </div>
        </form>
    );
}

export default LoginForm;
