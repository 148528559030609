// React
import { useState } from 'react';

// Components
import BaseButton, { ButtonType } from './BaseButton';

// Others
import { DownloadIcon } from '@heroicons/react/outline';
import { PDFDownloadLink } from '@react-pdf/renderer';

type PdfDownloadButtonProps = {
    /** Pdf render component */
    document: any;
    /** Label of the button */
    label?: string;
};

/**
 * Button for downloading a pdf
 * @description
 * This button, when clicked, generates the provided
 * pdf in the props and allows the user to download it.
 *
 * The pdf will not be rendered on mount improve performance.
 * @param props
 */
function PdfDownloadButton(props: PdfDownloadButtonProps) {
    // State
    const [hasRendered, setHasRendered] = useState(false);

    // Methods
    const onClick = () => {
        setHasRendered(!hasRendered);
    };

    // Render
    return hasRendered ? (
        <div onClick={onClick}>
            <PDFDownloadLink document={props.document} fileName='report.pdf'>
                {({ loading }) =>
                    loading ? (
                        <BaseButton
                            label='general:generatingPdf'
                            type={ButtonType.SECONDARY}
                            icon={DownloadIcon}
                            className='ml-2'
                        />
                    ) : (
                        <BaseButton
                            label='general:downloadPdf'
                            className='ml-2'
                            icon={DownloadIcon}
                            onClick={onClick}
                        />
                    )
                }
            </PDFDownloadLink>
        </div>
    ) : (
        <BaseButton
            label={props.label ? props.label : 'general:generatePdf'}
            className='ml-2'
            icon={DownloadIcon}
            onClick={onClick}
        />
    );
}

export default PdfDownloadButton;
