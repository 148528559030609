// Components
import { BooleanTableCell, DoubleTableCell } from '../../components/tables/TableCell';

// Types
import { Vehicle } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const vehicleColumns: ColumnConfig<Vehicle>[] = [
    {
        title: 'vehicles:identifier',
        key: 'identifier'
    },
    {
        title: 'vehicles:type',
        key: 'type',
        renderer: vehicle => <DoubleTableCell first={vehicle.type} second={vehicle.team} />
    },
    {
        title: 'vehicles:status',
        key: 'status',
        renderer: vehicle => <DoubleTableCell first={vehicle.status} second={vehicle.color} />
    },
    { title: 'vehicles:equipment', key: 'equipment' },
    { title: 'vehicles:currentLocation', key: 'currentLocation' },
    {
        title: 'vehicles:dpi',
        key: 'dpi',
        renderer: vehicle => <BooleanTableCell value={vehicle.dpi} />
    }
];
