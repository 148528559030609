// React
import { useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton from '../common/BaseButton';
import BaseModal from '../common/BaseModal';
import LocaleText from '../common/LocaleText';
import OpenDirectForm from '../forms/OpenDirectForm';
import ChatAudio from './ChatAudio';
import ChatImage from './ChatImage';
import ChatMessage from './ChatMessage';

// Config
import { POST_CHAT, POST_DIRECT } from '../../config/endpoints';

// Types
import { CHAT_MEDIA_TYPE } from '../../types/enums';
import { DirectRequest, MessageRequest } from '../../types/requests';
import { Chat, Message } from '../../types/socketio';

// Others
import { ChatAltIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

type ChatDetailsProps = {
    /** Chat entity */
    chat?: Chat;
};

/**
 * Details of the selected chat
 * @description
 * Shows the message list for the selected chat with the
 * traditional bubble style.
 *
 * Can make an open direct request with the open direct form.
 *
 * Messages on the right are sent by the user while the on
 * the left are sent by the opearator on the field.
 * @param props
 */
function ChatDetails(props: ChatDetailsProps) {
    // Hooks
    const { t } = useTranslation();

    // State
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');

    // Query
    const messageMutation = usePostMutation<MessageRequest>(POST_CHAT, {
        invalidateQueries: ['chats']
    });
    const openDirectMutation = usePostMutation<DirectRequest>(POST_DIRECT, {
        invalidateQueries: ['chats']
    });

    // Methods
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
        event.key === 'Enter' && handleMessageRequest();
    };

    const handleMessageRequest = () => {
        if (props.chat) {
            const message: MessageRequest = {
                operatorId: props.chat.operatorId,
                text: text,
                timestamp: new Date().getTime()
            };
            messageMutation
                .mutateAsync(message)
                .then(() => setText(''))
                .catch(() => {});
        }
    };

    const getMessageHeader = (type: 'OPERATOR' | 'USER') => {
        switch (type) {
            case 'OPERATOR':
                return `OPERATOR ${props.chat?.operatorId}`;
            default:
                return `USER ${props.chat?.userId}`;
        }
    };

    const renderMessage = (message: Message, index: number) => {
        switch (message.mediaType) {
            case CHAT_MEDIA_TYPE.AUDIO:
                return (
                    <ChatAudio
                        key={index}
                        header={getMessageHeader(message.type)}
                        message={message}
                    />
                );
            case CHAT_MEDIA_TYPE.IMAGE:
                return (
                    <ChatImage
                        key={index}
                        header={getMessageHeader(message.type)}
                        message={message}
                    />
                );
            default:
                return (
                    <ChatMessage
                        key={index}
                        header={getMessageHeader(message.type)}
                        message={message}
                    />
                );
        }
    };

    const openDirectRequest = (senderId: number, receiverId: number) =>
        openDirectMutation.mutate({ receiverId, senderId });

    const isChatOpened = () => props.chat && !props.chat.isClosed;

    // Render
    return (
        <section
            className='flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last'
            onClick={() =>
                props.chat && localStorage.setItem(props.chat.id, new Date().toISOString())
            }>
            {/* Direct Modal */}
            <BaseModal open={open} setOpen={setOpen} widthClass='sm:max-w-2xl'>
                {props.chat?.operatorId !== undefined && (
                    <OpenDirectForm
                        currentChatOperatorId={props.chat?.operatorId}
                        onOperatorSelected={receiver =>
                            props.chat && openDirectRequest(props.chat.operatorId, receiver.id)
                        }
                        onClose={() => setOpen(false)}
                    />
                )}
            </BaseModal>
            {/* Chat header */}
            <div className='mb-1 flex h-16 flex-col justify-center border-b border-gray-200 bg-white'>
                <div className='px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-6'>
                    <div className='sm:w-0 sm:flex-1'>
                        {props.chat ? (
                            <LocaleText
                                className='text-lg font-medium text-gray-900'
                                text={`${t('chat:messagesWithOperator')} ${props.chat.operatorId}`}
                            />
                        ) : (
                            <LocaleText
                                className='text-lg font-medium text-gray-900'
                                text='chat:noChatSelected'
                            />
                        )}
                    </div>
                    {isChatOpened() && (
                        <BaseButton label='chat:openDirect' onClick={() => setOpen(true)} />
                    )}
                </div>
            </div>
            {/* Messages List */}
            <div className='min-h-0 no-scrollbar flex-1 overflow-y-auto'>
                <div className='space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-6'>
                    {props.chat &&
                        props.chat.messages.map((message, index) => renderMessage(message, index))}
                    <div id='dummyChat' className='opacity-0' />
                </div>
            </div>
            {/* Input field */}
            {isChatOpened() && (
                <div className='flex h-16 flex-col justify-center border-t border-gray-200 bg-gray-50 '>
                    <div className='px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-6'>
                        <div className='sm:w-0 sm:flex-1'>
                            <input
                                type='text'
                                className='w-full rounded-lg border-gray-200 text-gray-600'
                                placeholder={t('chat:startTyping')}
                                onKeyDown={handleKeyDown}
                                value={text}
                                onChange={event => setText(event.target.value)}
                            />
                        </div>
                        <div
                            className='mt-4 flex items-center justify-between rounded bg-secondary px-3 py-1.5 sm:mt-0 sm:ml-2 sm:flex-shrink-0 sm:justify-start'
                            onClick={handleMessageRequest}>
                            <span className='inline-flex items-center  text-sm font-medium text-white '>
                                {t('chat:send')}
                            </span>
                            <ChatAltIcon className='ml-2 h-5 w-4 text-white' />
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

export default ChatDetails;
