// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetMutation from '../api/useGetMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { GET_DEAD_BODIES, GET_DEAD_BODY_IMAGES, DELETE_DEAD_BODIES } from '../config/endpoints';
import { deadBodyColumns } from '../config/tables/deadBodies';

// Types
import { DeadBody } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

type DeadBodiesPageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

const detailsHeaders = [
    { key: 'about', title: 'About', subtitle: 'Detailed informations' },
    { key: 'accessories', title: 'Accessories', subtitle: 'Detailed informations' },
    { key: 'identity', title: 'Identity', subtitle: 'Detailed informations' },
    { key: 'hypotesisIdentity', title: 'Hypotesis of identity', subtitle: 'Detailed informations' },
    { key: 'status', title: 'Status', subtitle: 'Detailed informations' }
];

/**
 * Dead bodies page
 * @description
 * Ordered and filterable list of dead bodies.
 * Only dead bodies relative to the current selection mission
 * will be shown.
 *
 * Dead bodies differs from patient with the "black"
 * emergency code because they were found already deceased.
 *
 * The user can access the details of a dead body
 * or remove it.
 */
function DeadBodiesPage(props: DeadBodiesPageProps) {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(deadBodyColumns);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const deadBodiesQuery = useGetQuery<DeadBody[]>(['deadBodies'], GET_DEAD_BODIES);
    const imagesMutation = useGetMutation<string[]>(GET_DEAD_BODY_IMAGES);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_DEAD_BODIES, {
        invalidateQueries: ['deadBodies']
    });

    // Memos
    const deadBodies = useMemo<DeadBody[] | undefined>(() => {
        if (!deadBodiesQuery.data) return;
        let deadBodies = (deadBodiesQuery.data || []).sort((a, b) =>
            a.general.createdAt > b.general.createdAt ? -1 : 1
        );

        deadBodies = applyFilter(deadBodies).map(d => {
            const db: DeadBody = { ...(d as DeadBody) };
            if (db.about?.bodyMapPaths) {
                delete db.about?.bodyMapPaths;
            }
            return db;
        });

        tableView.current?.changePage(1);

        return props.numberOfRows ? deadBodies.slice(0, props.numberOfRows) : deadBodies;
    }, [deadBodiesQuery.data, filter]);

    // Methods
    const navigate = (deadBody: DeadBody) => {
        imagesMutation
            .mutateAsync([{ key: 'code', value: deadBody.general.code }])
            .then(images => {
                history.push({
                    pathname: 'deadBodies/' + deadBody.id,
                    state: {
                        details: deadBody,
                        headers: detailsHeaders,
                        title: 'deadBodyDetails',
                        basePath: `media/deadbodies/${deadBody.general.code}`,
                        images
                    }
                });
            })
            .catch(() => {
                history.push({
                    pathname: 'deadBodies/' + deadBody.id,
                    state: {
                        details: deadBody,
                        headers: detailsHeaders,
                        title: 'Dead Body Details'
                    }
                });
            });
    };

    const deleteDeadBodies = () => {
        if (toBeDeleted.length > 0) {
            deleteMutation.mutate({ ids: toBeDeleted });
            setToBeDeleted([]);
            setDeleteOpen(false);
        }
    };

    const onButtonPress = () => {
        if (deadBodiesQuery.data) {
            setToBeDeleted(deadBodiesQuery.data.map(m => m.id));
            setDeleteOpen(true);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={() => deleteDeadBodies()}
                />
            </BaseModal>
            <PageHeader
                title='deadBodies:title'
                buttons={!props.numberOfRows ? ['general:deleteAll'] : []}
                onButtonPress={onButtonPress}
            />
            <TableFilter columns={deadBodyColumns} filter={filter} setFilter={setFilter} />
            <Table<DeadBody>
                ref={tableView}
                columns={deadBodyColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!deadBodies
                        ? [
                              deadBody => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([deadBody.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                data={deadBodies}
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default DeadBodiesPage;
