// Config
import { BACKEND_PROTOCOL, BACKEND_IP, BACKEND_PORT } from '../../config/constants';

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}`;

type GalleryProps = {
    /** Base media path on the server */
    basePath: string;
    /** List of images name */
    images?: string[];
};

/**
 * Image Gallery
 * @description
 * Renders a list of image sourcing them from the server
 * @param props
 */
function Gallery(props: GalleryProps) {
    // Methods
    const getFullImagePath = (imageName: string) => `${baseURL}/${props.basePath}/${imageName}`;

    // Render
    return (
        <ul className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'>
            {props.images &&
                props.images.map((imageName, idx) => (
                    <li key={idx} className='relative'>
                        <div className='aspect-w-10 aspect-h-7 group block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100'>
                            <img
                                src={getFullImagePath(imageName)}
                                alt=''
                                className='pointer-events-none object-cover group-hover:opacity-75'
                            />
                        </div>
                    </li>
                ))}
        </ul>
    );
}
export default Gallery;
