// React
import { useEffect, useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import OperatorForm from '../components/forms/OperatorForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_OPERATORS, DELETE_OPERATORS } from '../config/endpoints';
import { operatorColumns } from '../config/tables/operators';

// Types
import { Operator } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

/**
 * Operators page
 * @description
 * Ordered and filterable list of operators.
 *
 * This are the operators that authenticates in the mobile app.
 *
 * Only manager of the organization or admins can create, update or
 * delete operators.
 */
function OperatorsPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(operatorColumns, 1, [
        { colKey: 'role', locKey: 'operators' }
    ]);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState<Operator>();

    // Reference
    const tableView = useRef<TableView>(null);

    // Api
    const operatorsQuery = useGetQuery<Operator[]>(['operators'], GET_OPERATORS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest>(DELETE_OPERATORS, {
        invalidateQueries: ['operators']
    });

    // Memos
    const operators = useMemo<Operator[] | undefined>(() => {
        if (!operatorsQuery.data) return;
        tableView.current?.changePage(1);

        return applyFilter(operatorsQuery.data);
    }, [operatorsQuery.data, filter]);

    useEffect(() => {
        if (!open && !!selectedOperator)
            setTimeout(() => {
                setSelectedOperator(undefined);
            }, 200);
    }, [open]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <OperatorForm initialOperator={selectedOperator} onClose={() => setOpen(false)} />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setSelectedOperator(undefined);
                    }}
                    onConfirm={() => {
                        if (selectedOperator)
                            deleteMutation
                                .mutateAsync({ ids: [selectedOperator.id] })
                                .then(() => {
                                    setDeleteOpen(false);
                                    setSelectedOperator(undefined);
                                })
                                .catch(() => {});
                    }}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='operators:title'
                buttons={['general:add']}
                onButtonPress={() => setOpen(true)}
            />
            <TableFilter columns={operatorColumns} filter={filter} setFilter={setFilter} />
            <Table<Operator>
                ref={tableView}
                columns={operatorColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!operators
                        ? [
                              operator => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setSelectedOperator(operator);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : []
                }
                onCellPress={item => {
                    setSelectedOperator(item);
                    setOpen(true);
                }}
                data={operators}
            />
        </div>
    );
}

export default OperatorsPage;
