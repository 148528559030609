// Assets
import logo from '../../assets/images/logoINMM.png';

// Others
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

type HeaderPdfProps = {
    /** Pdf title */
    title: string;
    /** Patient color */
    color?: string;
};

/**
 * Pdf header
 * @description
 * Can show an emergency color near the title. Used for patient pdf.
 * @param props
 */
function HeaderPdf(props: HeaderPdfProps) {
    // Hooks
    const { t } = useTranslation();

    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.titleWrapper}>
                {props.color && (
                    <View style={[styles.colorContainer, { backgroundColor: props.color }]} />
                )}
                <View>
                    <Text style={styles.title}>MAXXIE REPORT</Text>
                    <Text style={styles.subTitle}>{t(`generalDetails:${props.title}`)}</Text>
                    <Text style={styles.date}>{new Date().toLocaleString()}</Text>
                </View>
            </View>
            <Image source={logo} style={styles.logo} />
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginBottom: 15,
        borderBottom: 1.5,
        paddingBottom: 5,
        borderBottomColor: '#1F2937',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    titleWrapper: {
        flexDirection: 'row'
    },
    colorContainer: {
        height: '100%',
        width: 40,
        backgroundColor: 'red',
        marginRight: 10
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: '#1F2937',
        marginBottom: 2
    },
    subTitle: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 3
    },
    logo: {
        height: 50,
        width: 50
    },
    date: {
        fontWeight: 500,
        fontSize: 11,
        color: '#4B5563'
    }
});

export default HeaderPdf;
