// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { GET_NINE_LINE, DELETE_NINE_LINES } from '../config/endpoints';
import { nineLineColumns } from '../config/tables/nineLines';

// Types
import { NineLine } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

type NineLinesPageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

const detailsHeaders = [
    { key: 'patientsStatus', title: 'Number of patients', subtitle: 'Detailed informations' },
    { key: 'equipment', title: 'Special equipment', subtitle: 'Detailed informations' },
    { key: 'patientsType', title: 'To be carried', subtitle: 'Detailed informations' },
    { key: 'security', title: 'Security at pickup zone', subtitle: 'Detailed informations' },
    { key: 'markingMethods', title: 'Marking methods', subtitle: 'Detailed informations' },
    {
        key: 'numberOfPatients',
        title: 'Patients by nationality status',
        subtitle: 'Detailed informations'
    },
    { key: 'pickupZone', title: 'Pickup zone', subtitle: 'Detailed informations' }
];

/**
 * Nine lines page
 * @description
 * Ordered and filterable list of nine lines.
 * Only nine lines relative to the current selection mission
 * will be shown.
 *
 * The user can access a nine line details or remove it.
 */
function NineLinesPage(props: NineLinesPageProps) {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(nineLineColumns, 0, [
        { colKey: 'security.type', locKey: 'security' }
    ]);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const nineLinesQuery = useGetQuery<NineLine[]>(['nineLines'], GET_NINE_LINE);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_NINE_LINES, {
        invalidateQueries: ['nineLines']
    });

    // Memos
    const nineLines = useMemo<NineLine[] | undefined>(() => {
        if (!nineLinesQuery.data) return;
        let nineLines = (nineLinesQuery.data || []).sort((a, b) =>
            a.general.createdAt > b.general.createdAt ? -1 : 1
        );

        nineLines = applyFilter(nineLines);
        tableView.current?.changePage(1);

        return props.numberOfRows ? nineLines.slice(0, props.numberOfRows) : nineLines;
    }, [nineLinesQuery.data, filter]);

    // Methods
    const navigate = (nineLine: NineLine) => {
        history.push({
            pathname: '/nineLines/' + nineLine.id,
            state: {
                details: {
                    ...nineLine,
                    pickupZone: {
                        ...nineLine.pickupZone,
                        terrain: `BACKEND/media/ninelines/${nineLine.id}/hazards.png`
                    }
                },
                headers: detailsHeaders,
                title: 'nineLineDetails'
            }
        });
    };

    const deleteNineLines = () => {
        if (toBeDeleted.length > 0)
            deleteMutation
                .mutateAsync({ ids: toBeDeleted })
                .then(() => {
                    setToBeDeleted([]);
                    setDeleteOpen(false);
                })
                .catch(() => {});
    };

    const onButtonPress = () => {
        if (nineLinesQuery.data) {
            setToBeDeleted(nineLinesQuery.data.map(m => m.id));
            setDeleteOpen(true);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={deleteNineLines}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='nineLines:title'
                buttons={!props.numberOfRows ? ['general:deleteAll'] : []}
                onButtonPress={onButtonPress}
            />
            <TableFilter
                columns={nineLineColumns}
                filter={filter}
                setFilter={setFilter}
                blacklist={['numberOfPatients.number']}
            />
            <Table<NineLine>
                ref={tableView}
                columns={nineLineColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!nineLines
                        ? [
                              nineLine => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([nineLine.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                data={nineLines}
                onCellPress={item => navigate(item)}
            />
        </div>
    );
}

export default NineLinesPage;
