// React
import { useEffect, useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import LocationForm from '../components/forms/LocationForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_LOCATIONS, DELETE_LOCATIONS } from '../config/endpoints';
import { locationColumns } from '../config/tables/locations';

// Types
import { Location } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon, PhoneIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';

/**
 * Locations page
 * @description
 * Ordered and filterable list of a specific location locations.
 * Only locations relative to the current selection organization
 * will be shown.
 *
 * Locations are the destination point for patient medevacs.
 *
 * Only manager of the organization or admins can create, update or
 * delete locations.
 */
function LocationsPage() {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(locationColumns, 1);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<Location>();

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const locationsQuery = useGetQuery<Location[]>(['locations'], GET_LOCATIONS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest>(DELETE_LOCATIONS, {
        invalidateQueries: ['locations']
    });

    // Memos
    const locations = useMemo<Location[]>(() => {
        if (!locationsQuery.data) return [];
        tableView.current?.changePage(1);
        return applyFilter(locationsQuery.data);
    }, [locationsQuery.data, filter]);

    // Methods
    const navigate = (id: number) => {
        history.push({
            pathname: '/contacts/' + id
        });
    };

    const onFormClose = () => {
        setOpen(false);
        setSelectedLocation(undefined);
    };

    // Effects
    useEffect(() => {
        if (!open && !!selectedLocation) setSelectedLocation(undefined);
    }, [open]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen} onClose={onFormClose}>
                <LocationForm initialLocation={selectedLocation} onClose={onFormClose} />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setSelectedLocation(undefined);
                    }}
                    onConfirm={() => {
                        if (selectedLocation)
                            deleteMutation
                                .mutateAsync({ ids: [selectedLocation.id] })
                                .then(() => {
                                    setDeleteOpen(false);
                                    setSelectedLocation(undefined);
                                })
                                .catch(() => {});
                    }}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='locations:title'
                buttons={['general:add']}
                onButtonPress={() => setOpen(true)}
            />
            <TableFilter
                columns={locationColumns}
                filter={filter}
                setFilter={setFilter}
                blacklist={['distribution', 'places', 'intensiveCare']}
            />
            <Table<Location>
                ref={tableView}
                columns={locationColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={[
                    location => (
                        <PhoneIcon
                            className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                            onClick={() => navigate(location.id)}
                        />
                    ),
                    location => (
                        <TrashIcon
                            className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                            onClick={() => {
                                setSelectedLocation(location);
                                setDeleteOpen(true);
                            }}
                        />
                    )
                ]}
                onCellPress={item => {
                    setSelectedLocation(item);
                    setOpen(true);
                }}
                data={locations}
            />
        </div>
    );
}

export default LocationsPage;
