// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { DELETE_MEDEVACS, GET_LOCATIONS, GET_MEDEVACS } from '../config/endpoints';
import { medevacColumns } from '../config/tables/medevacs';

// Types
import { Medevac, Location } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

type MedevacsPageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

/**
 * Medevacs page
 * @description
 * Ordered and filterable list of medevacs.
 * Only medevacs relative to the current selection mission
 * will be shown.
 *
 * A medevac indicates the transfer of a group of patients
 * to a medical point or location.
 *
 * The user can remove one of all of the medevacs.
 */
function MedevacsPage(props: MedevacsPageProps) {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(medevacColumns);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const locationsData = useGetQuery<Location[]>(['locations'], GET_LOCATIONS);
    const medevacsQuery = useGetQuery<Medevac[]>(['medevacs'], GET_MEDEVACS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_MEDEVACS, {
        invalidateQueries: ['medevacs']
    });

    // Methods
    const medevacs = useMemo<Medevac[] | undefined>(() => {
        if (!medevacsQuery?.data) return;
        let medevacs = medevacsQuery.data
            .map(m => {
                const location = locationsData?.data?.find(l => l.id === m.general.destinationId);
                return {
                    ...m,
                    general: {
                        ...m.general,
                        destinationId: (location?.name || 'Deleted') as unknown as number
                    }
                };
            })
            .sort((a, b) => (a.general.createdAt > b.general.createdAt ? -1 : 1));

        medevacs = applyFilter(medevacs);
        tableView.current?.changePage(1);

        return props.numberOfRows ? medevacs.slice(0, props.numberOfRows) : medevacs;
    }, [medevacsQuery.data, locationsData, filter]);

    const deleteMedevacs = () => {
        if (toBeDeleted.length > 0)
            deleteMutation
                .mutateAsync({ ids: toBeDeleted })
                .then(() => {
                    setToBeDeleted([]);
                    setDeleteOpen(false);
                })
                .catch(() => {});
    };

    const onButtonPress = () => {
        if (medevacs) {
            setToBeDeleted(medevacs.map(m => m.id));
            setDeleteOpen(true);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={deleteMedevacs}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader
                title='medevacs:title'
                buttons={!props.numberOfRows ? ['general:deleteAll'] : []}
                onButtonPress={onButtonPress}
            />
            <TableFilter
                columns={medevacColumns}
                filter={filter}
                setFilter={setFilter}
                blacklist={['distribution']}
            />
            <Table<Medevac>
                ref={tableView}
                columns={medevacColumns}
                rowOptions={
                    !!medevacs
                        ? [
                              medevac => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setToBeDeleted([medevac.id]);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : []
                }
                data={medevacs}
            />
        </div>
    );
}

export default MedevacsPage;
