// Components
import { TagTableCell } from '../../components/tables/TableCell';

// Types
import { User } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const userColumns: ColumnConfig<User>[] = [
    { title: 'users:id', key: 'id' },
    { title: 'users:firstName', key: 'firstName' },
    { title: 'users:lastName', key: 'lastName' },
    { title: 'users:email', key: 'email' },
    {
        title: 'users:role',
        key: 'role',
        renderer: user => <TagTableCell text={user.role} />
    }
];
