import { StyleSheet, Text, View } from '@react-pdf/renderer';
import TopCheckboxesTreatmentsT3c from './TopCheckboxesTreatmentsT3c';
import BottomCheckboxesTreatmentsT3c from './BottomCheckboxesTreatmentsT3c';
import FirstTableTreatmentsT3c from './FirstTableTreatmentsT3c';
import SecondTableTreatmentsT3c from './SecondTableTreatmentsT3c';

/**
 * Treatments
 * @description
 * This is the Treatments section of the T3C
 *
 * This section contains details about the
 * treatments that has been performed on the patient
 * @returns
 */
function Treatments() {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.firstRowContainer}>
                <Text style={styles.capitalLetter}>Treatments:</Text>
            </View>
            <TopCheckboxesTreatmentsT3c />
            <FirstTableTreatmentsT3c />
            <SecondTableTreatmentsT3c />
            <BottomCheckboxesTreatmentsT3c />
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        paddingBottom: 8,
        borderBottom: 1.5,
        borderBottomColor: '#1F2937',
        flexDirection: 'column'
    },
    firstRowContainer: {
        marginLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    capitalLetter: {
        marginTop: 5,
        fontWeight: 700,
        fontSize: 20,
        color: '#1F2937'
    }
});

export default Treatments;
