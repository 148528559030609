import { StyleSheet, Text, View } from '@react-pdf/renderer';

/**
 * Top Checkboxes Treatments T3C
 * @description
 * This is the first section of checkboxes in the
 * Treatments section of the T3C
 *
 * @returns
 */
function TopCheckboxesTreatmentsT3c() {
    // Render
    return (
        <View style={styles.container}>
            <View style={styles.firstColContainer}>
                <View style={styles.spacer}></View>
                <View style={styles.firstRowContainer}>
                    <Text style={styles.text}>C: TQ-</Text>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Extremity</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Junctional</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Truncal</Text>
                    </View>
                </View>
                <View style={styles.secondRowContainer}>
                    <Text style={styles.text}>Dressing- </Text>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Hemostatic</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Pressure</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Other</Text>
                    </View>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.text}>A: </Text>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Intact</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>NPA</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>CRIC</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>ET-Tube</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>SGA</Text>
                    </View>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.text}>B: </Text>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>O2</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Needle-D</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Chest-Tube</Text>
                    </View>
                    <View style={styles.couple}>
                        <View style={styles.squares} />
                        <Text style={styles.squareText}>Chest-Seal</Text>
                    </View>
                </View>
            </View>
            <View style={styles.secondColContainer}>
                <Text style={styles.customText}>Type</Text>
                <View style={styles.underline}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.underline}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.underline}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.underline}>
                    <Text style={styles.text}> </Text>
                </View>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    couple: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    squareText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2,
        marginRight: 5
    },
    squares: {
        border: 1,
        width: 10,
        height: 10,
        borderColor: '#1F2937'
    },
    text: {
        display: 'flex',
        flex: 1,
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    customText: {
        display: 'flex',
        flex: 1,
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5,
        alignSelf: 'center',
        marginBottom: 5
    },
    underline: {
        alignSelf: 'center',
        width: 100,
        borderBottom: 1,
        display: 'flex',
        borderBottomColor: '#1F2937',
        marginBottom: 5
    },
    firstColContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2
    },
    secondColContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    firstRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    spacer: {
        flex: 1
    },
    secondRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        marginBottom: 5
    }
});
export default TopCheckboxesTreatmentsT3c;
