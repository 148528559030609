// Types
import { Licence, User } from '../../types/entities';
import { AuthenticationState } from '../../types/redux';

// Others
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AuthenticationState = {
    licences: []
};

export const authenticationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        setLicences: (state, action: PayloadAction<Licence[]>) => {
            state.licences = action.payload;
        },
        logout: state => {
            localStorage.clear();
            state.user = null;
        }
    }
});

export const { setUser, setLicences, logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
