// React
import { useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import LocaleText from '../common/LocaleText';
import { FormInput } from '../forms/FormInput';

// Config
import { POST_LOCATION } from '../../config/endpoints';

// Types
import { Location } from '../../types/entities';
import { PostLocationRequest } from '../../types/requests';

type LocationFormProps = {
    /** Location to update */
    initialLocation?: Location;
    /** Called on submit or cancel */
    onClose?: () => void;
};

function fromLocationToDto(location: Location): PostLocationRequest {
    return location;
}

/**
 * Location form
 * @description
 * Form used to create or update a location.
 *
 * Uses HTML validation.
 * @param props
 */
function LocationForm(props: LocationFormProps) {
    // State
    const [location, setLocation] = useState<PostLocationRequest>(
        props.initialLocation
            ? fromLocationToDto(props.initialLocation)
            : ({} as PostLocationRequest)
    );

    // Api
    const locationMutation = usePostMutation<PostLocationRequest>(POST_LOCATION, {
        invalidateQueries: ['locations']
    });

    // Methods
    const handlePostRequest = () => {
        locationMutation
            .mutateAsync(location)
            .then(() => {
                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='locations:name'
                    value={location.name || ''}
                    onChange={text => setLocation({ ...location, name: text })}
                />
                <FormInput
                    label='locations:info'
                    value={location.info || ''}
                    onChange={text => setLocation({ ...location, info: text })}
                />
                <FormInput
                    label='locations:address'
                    value={location.address || ''}
                    onChange={text => setLocation({ ...location, address: text })}
                />
                <LocaleText text='locations:addressFormat' className='text-xs opacity-40' />
                <div className='grid grid-cols-2 gap-2 pt-4'>
                    <FormInput<number>
                        label='locations:nPlaces'
                        value={location.availablePlaces || ''}
                        contentType='number'
                        onChange={text => setLocation({ ...location, availablePlaces: text })}
                    />
                    <FormInput<number>
                        label='locations:nICU'
                        value={location.intensiveCarePlaces || ''}
                        contentType='number'
                        onChange={text => setLocation({ ...location, intensiveCarePlaces: text })}
                    />
                </div>
            </div>
            <div className='bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialLocation ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={locationMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default LocationForm;
