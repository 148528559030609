// React
import { Fragment } from 'react';

// Components
import LocaleText from './LocaleText';

// Types
import { Filter } from '../../types/structs';

// Others
import { ColumnConfig } from '@edalab/react-tables';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

type SearchFilterProps = {
    /** Filter value */
    filter: Filter;
    /** Table column config */
    columns: ColumnConfig[];
    /** Called when filter changes */
    setFilter: (filter: Filter) => void;
};

/**
 * Dropdown filter for table columns
 * @description
 * This component allows the user to select one table
 * column form the available ones.
 *
 * Its used for filtering.
 */
function SearchFilter(props: SearchFilterProps) {
    // Render
    return (
        <Menu as='div' className='relative inline-block text-left'>
            <div>
                <Menu.Button
                    className='focus:outline-none inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 
                    text-sm font-medium text-gray-700 shadow hover:bg-gray-50'>
                    <LocaleText text={props.filter.column.title} />
                    <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'>
                <Menu.Items className='focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                    {props.columns.map(c => (
                        <Menu.Item key={c.key}>
                            {() => (
                                <div
                                    onClick={() =>
                                        props.setFilter({
                                            column: c,
                                            value: ''
                                        })
                                    }>
                                    <div className='block cursor-pointer px-4 py-2 text-sm hover:bg-gray-200'>
                                        <LocaleText text={c.title} />
                                    </div>
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default SearchFilter;
