// Locale
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Languages
import * as LANGUAGES from './languages';
import english from './english.json';
import italian from './italian.json';
import german from './german.json';
import french from './french.json';
import lithuanian from './lithuanian.json';

const resources = {
    [LANGUAGES.ENGLISH]: english,
    [LANGUAGES.ITALIAN]: italian,
    [LANGUAGES.GERMAN]: german,
    [LANGUAGES.FRENCH]: french,
    [LANGUAGES.LITHUANIAN]: lithuanian
};

i18n.use(initReactI18next).init({
    resources,
    lng: LANGUAGES.ENGLISH,

    keySeparator: false,

    interpolation: {
        escapeValue: false
    }
});

export function isLanguageAvailable(lang: string) {
    return LANGUAGES.ARRAY.includes(lang);
}

export default i18n;
