import { StyleSheet, Text, View } from '@react-pdf/renderer';

/**
 * Second Table Treatments T3C
 * @description
 * This is the second table of the Treatments section of the T3C
 *
 * This table will display details about the meds
 * that has been given to a patient
 * @returns
 */
function SecondTableTreatmentsT3c() {
    // Methods
    const generateSmallColumns = (
        firstCell: string,
        secondCell?: string,
        thirdCell?: string,
        fourthCell?: string,
        fifthCell?: string,
        sixthCell?: string,
        seventhCell?: string,
        eighthCell?: string
    ) => {
        return (
            <View key={firstCell} style={styles.container}>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}>{firstCell}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{secondCell ? secondCell : ' '}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{thirdCell ? thirdCell : ' '}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{fourthCell ? fourthCell : ' '}</Text>
                </View>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}>{fifthCell ? fifthCell : ' '}</Text>
                </View>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}>{sixthCell ? sixthCell : ' '}</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}>{seventhCell ? seventhCell : ' '}</Text>
                </View>
                <View style={styles.greyRowBottom}>
                    <Text style={styles.text}>{eighthCell ? eighthCell : ' '}</Text>
                </View>
            </View>
        );
    };

    // Render
    return (
        <View style={styles.table}>
            <View style={styles.firstCol}>
                <View style={styles.title}>
                    <Text style={styles.text}>MEDS:</Text>
                </View>
                <View style={styles.firstGreyCell}>
                    <Text style={styles.text}>Analgesic</Text>
                </View>
                <View style={styles.whiteCell}>
                    <Text style={styles.text}>Antibiotic</Text>
                </View>
                <View style={styles.secondGreyCell}>
                    <Text style={styles.text}>Other</Text>
                </View>
            </View>
            <View style={styles.secondCol}>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}>Name</Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.normalWhiteRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.greyRow}>
                    <Text style={styles.text}> </Text>
                </View>
                <View style={styles.greyRowBottom}>
                    <Text style={styles.text}> </Text>
                </View>
            </View>
            {generateSmallColumns('Dose')}
            {generateSmallColumns('Route')}
            {generateSmallColumns('Time')}
        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        alignSelf: 'center',
        padding: 5
    },
    greyRow: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1,
        backgroundColor: '#D2D2D2'
    },
    normalWhiteRow: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    greyRowBottom: {
        flex: 1,
        borderLeft: 1,
        borderTop: 1,
        backgroundColor: '#D2D2D2',
        borderBottom: 1
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
        borderRight: 1,
        marginBottom: 10
    },
    firstCol: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2
    },
    title: {
        flex: 1,
        borderLeft: 1,
        borderLeftColor: 'white',
        borderTop: 1,
        borderTopColor: 'white'
    },
    firstGreyCell: {
        flex: 3,
        borderLeft: 1,
        borderTop: 1,
        backgroundColor: '#D2D2D2',
        justifyContent: 'center'
    },
    whiteCell: {
        flex: 2,
        borderLeft: 1,
        borderTop: 1,
        justifyContent: 'center'
    },
    secondGreyCell: {
        flex: 2,
        borderLeft: 1,
        borderTop: 1,
        borderBottom: 1,
        backgroundColor: '#D2D2D2',
        justifyContent: 'center'
    },
    secondCol: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3
    }
});
export default SecondTableTreatmentsT3c;
