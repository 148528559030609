// Components
import BaseButton from './BaseButton';
import LocaleText from './LocaleText';

type PageHeaderProps = {
    /** Page title */
    title: string;
    /** Page pretitle */
    pretitle?: string;
    /** Component mounted near buttons */
    children?: React.ReactNode;
    /** List of buttons */
    buttons?: string[];
    /** Called when a button is pressed */
    onButtonPress?: (buttonIndex: number) => any;
};

/**
 * Basic title header for pages
 * @description
 * Shows a title and an optional lists of buttons
 * @param props
 */
function PageHeader(props: PageHeaderProps) {
    // Render
    return (
        <div className='mb-10 border-b-2 border-white pb-6 md:flex md:items-center md:justify-between md:space-x-5'>
            <div className='items-start space-x-5'>
                <LocaleText
                    className='pt-1.5 text-2xl font-bold text-gray-900'
                    text={props.title}
                />
                {props.pretitle}
            </div>
            <div className='justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3'>
                {props.buttons?.map((button, index) => (
                    <BaseButton
                        key={index}
                        label={button}
                        onClick={() => props.onButtonPress && props.onButtonPress(index)}
                    />
                ))}
                {props.children}
            </div>
        </div>
    );
}

export default PageHeader;
