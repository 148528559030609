// React
import { useCallback, useEffect, useState } from 'react';

// Components
import Dropdown from '../common/Dropdown';
import LocaleText from '../common/LocaleText';
import Switch from '../common/Switch';

type FormInputProps<T> = {
    /** Input label */
    label: string;
    /** Input value */
    value?: string | number;
    /** Type of the input content */
    contentType?: 'text' | 'password' | 'email' | 'number';
    /** If true, user can skip it */
    optional?: boolean;
    /** If true, disables the input */
    disabled?: boolean;
    /** Called when input value changes */
    onChange?: (input: T) => void;
};

/**
 * Input used inside a form
 * @param props
 */
export function FormInput<T = string>(props: FormInputProps<T>) {
    // Methods
    const cleanValue = useCallback(
        (text: any) => {
            if (typeof text === 'string' && props.contentType === 'number') {
                return parseFloat(text);
            } else return text;
        },
        [props.contentType]
    );

    // Render
    return props.optional ? (
        <div>
            <LocaleText text={props.label} className='mb-2' />
            <input
                value={props.value}
                disabled={props.disabled}
                type={props.contentType}
                onChange={e => props.onChange && props.onChange(cleanValue(e.target.value))}
                className='focus:outline-none relative mb-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-secondary focus:ring-secondary sm:text-sm'
            />
        </div>
    ) : (
        <div>
            <LocaleText text={props.label} className='mb-2' />
            <input
                required
                disabled={props.disabled}
                value={props.value}
                type={props.contentType}
                onChange={e => props.onChange && props.onChange(cleanValue(e.target.value))}
                className='focus:outline-none relative mb-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-secondary focus:ring-secondary sm:text-sm'
            />
        </div>
    );
}

type FormSelectProps = {
    /** Input label */
    label: string;
    /** Selectable options */
    items?: any[];
    /** Selected option value */
    selected: any;
    /** Called when selected option changes */
    setSelected: React.Dispatch<React.SetStateAction<any>>;
};

/**
 * Select input used inside a form
 * @param props
 */
export function FormSelect(props: FormSelectProps) {
    // Render
    return (
        <div>
            <LocaleText text={props.label} className='mb-2 mt-3' />
            <Dropdown
                items={props.items}
                selected={props.selected}
                setSelected={props.setSelected}
            />
        </div>
    );
}

type FormCheckboxProps = {
    /** Input label */
    label: string;
    /** Checkbox checked value */
    value?: boolean;
    /** If true, user can skip it */
    optional?: boolean;
    /** Called when input value changes */
    onChange: (value: boolean) => void;
};

/**
 * Checkbox input used inside a form
 * @param props
 */
export function FormCheckbox(props: FormCheckboxProps) {
    // State
    const [enabled, setEnabled] = useState(props.value ? props.value : false);

    // Effects
    useEffect(() => {
        if (!enabled && props.value !== undefined) props.onChange(enabled);
        else if (enabled) props.onChange(enabled);
        // eslint-disable-next-line
    }, [enabled]);

    // Render
    return (
        <div className='ml-5'>
            <LocaleText text={props.label} className='mb-3 mt-2' />
            <Switch enabled={enabled} setEnabled={setEnabled} />
        </div>
    );
}
