// React
import { createContext, useCallback, useEffect, useRef } from 'react';

// Hooks
import { useAppDispatch } from '../../hooks/useAppDispatch';

// Store
import store from '../../store';
import { addNotification } from '../../store/slices/statusSlice';

// Api
import { useQueryClient } from 'react-query';

// Config
import { BACKEND_IP, BACKEND_PROTOCOL, SOCKET_PORT } from '../../config/constants';

// Types
import { SERVER_EVENT, STORAGE_KEY } from '../../types/enums';

// Others
import io from 'socket.io-client';

const SOCKET_URL = `${BACKEND_PROTOCOL}://${BACKEND_IP}${SOCKET_PORT ? `:${SOCKET_PORT}` : ''}`;

export const socket = io(SOCKET_URL, {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 5000
});

type SocketContextType = {
    getConnectionState: () => boolean;
};

export const SocketContext = createContext<SocketContextType>({} as SocketContextType);

type SocketProviderProps = {
    /** Components that uses the socket */
    children: React.ReactNode;
};

/**
 * Provider that handles the socket communication
 * @description
 * Only the authenticated user can instantiate a socket communication.
 * @param props
 */
function SocketProvider(props: SocketProviderProps) {
    // Hooks
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    // References
    const isConnected = useRef(false);

    // Callbacks
    const handleUpdate = useCallback(
        (title: string, text: string, disableNotification?: boolean) => {
            queryClient.invalidateQueries(['chats']);
            !disableNotification &&
                dispatch(addNotification({ id: String(Math.random() * 1000), title, text }));
        },
        [dispatch, queryClient]
    );

    // Methods
    const onUpdateDashboard = () => queryClient.invalidateQueries();

    const onChatAdded = (operatorId: number) =>
        handleUpdate('New operator connected', `operator ${operatorId} connected`);

    const onChatArchived = (operatorId: number) =>
        handleUpdate('Operator disconnected', `operator ${operatorId} disconnected`);

    const onNewMessage = (data: { operatorId: number; text: string }) => {
        const selectedChat = store.getState().status.selectedChat;
        if (selectedChat?.operatorId === data.operatorId && selectedChat.isClosed === false) {
            localStorage.setItem(selectedChat.id, new Date().toISOString());
            handleUpdate(`Message received from operator ${data.operatorId}`, data.text, true);
        } else handleUpdate(`Message received from operator ${data.operatorId}`, data.text);
    };

    const getConnectionState = () => isConnected.current;

    // Effects
    useEffect(() => {
        socket.on('connect', () => {
            console.log('Socket connected from provider');
            isConnected.current = true;
        });

        socket.on(SERVER_EVENT.UPDATE_DASHBOARD, onUpdateDashboard);

        socket.on(SERVER_EVENT.CHAT_ADDED, onChatAdded);

        socket.on(SERVER_EVENT.CHAT_ARCHIVED, onChatArchived);

        socket.on(SERVER_EVENT.NEW_MESSAGE, onNewMessage);

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
            isConnected.current = false;
        });

        socket.auth = {
            refreshToken: localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
        };

        socket.connect();

        return () => {
            socket.disconnect();
            socket.off('connect');
            socket.off(SERVER_EVENT.UPDATE_DASHBOARD);
            socket.off(SERVER_EVENT.CHAT_ADDED);
            socket.off(SERVER_EVENT.CHAT_ARCHIVED);
            socket.off(SERVER_EVENT.NEW_MESSAGE);
            socket.off('disconnect');
        };
    }, []);

    // Render
    return (
        <SocketContext.Provider value={{ getConnectionState }}>
            {props.children}
        </SocketContext.Provider>
    );
}

export default SocketProvider;
