// React
import { useState } from 'react';

// Hooks
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

// Store
import { setSelectedMission } from '../../store/slices/statusSlice';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput } from '../forms/FormInput';

// Config
import { POST_MISSIONS } from '../../config/endpoints';

// Types
import { Mission } from '../../types/entities';
import { STORAGE_KEY } from '../../types/enums';

type MissionFormProps = {
    /** Called on submit or cancel */
    onClose?: () => void;
};

/**
 * Mission form
 * @description
 * Form used to create a mission.
 *
 * Uses HTML validation.
 * @param props
 */
function MissionForm(props: MissionFormProps) {
    // Hooks
    const dispatch = useAppDispatch();

    // Global state
    const selectedMission = useAppSelector(state => state.status.selectedMission);

    // State
    const [mission, setMission] = useState<Mission>({} as Mission);

    // Query
    const missionMutation = usePostMutation<Mission, Mission>(POST_MISSIONS, {
        invalidateQueries: ['missions']
    });

    // Methods
    const handlePostRequest = () => {
        missionMutation
            .mutateAsync({
                ...mission,
                startTime: new Date()
            })
            .then(savedMission => {
                if (!selectedMission) {
                    localStorage.setItem(STORAGE_KEY.SELECTED_MISSION, savedMission.id.toString());
                    dispatch(setSelectedMission(savedMission));
                }

                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='missions:name'
                    value={mission.name || ''}
                    onChange={text => setMission({ ...mission, name: text })}
                />
            </div>
            <div className='rounded-bl-lg rounded-br-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label='general:add'
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={missionMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default MissionForm;
