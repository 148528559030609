// Redux
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setError } from '../../store/slices/statusSlice';

// Icons
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

type BannerProps = {
    /** Message to display on the banner  */
    message: string;
};

/**
 * Message banner
 * @description
 * Banner that appears at the bottom of the page. Should be
 * used to display application errors or warnings
 * @param props
 */
function Banner(props: BannerProps) {
    // Hooks
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // Render
    return (
        <div className='fixed inset-x-0 bottom-0 z-50 pb-2 sm:pb-5'>
            <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
                <div className='rounded-lg bg-secondary p-2 shadow-lg sm:p-3'>
                    <div className='flex flex-wrap items-center justify-between'>
                        <div className='flex w-0 flex-1 items-center'>
                            <span className='flex rounded-lg bg-blue-900 p-2'>
                                <SpeakerphoneIcon
                                    className='h-6 w-6 text-white'
                                    aria-hidden='true'
                                />
                            </span>
                            <p className='ml-3 truncate font-medium text-white'>
                                <span className='hidden md:inline'>
                                    {t(`errors:${props.message}`)}
                                </span>
                            </p>
                        </div>
                        <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-2'>
                            <button
                                type='button'
                                onClick={() => dispatch(setError(undefined))}
                                className='focus:outline-none -mr-1 flex rounded-md p-2 hover:bg-blue-900 focus:ring-2 focus:ring-white'>
                                <span className='sr-only'>{t('general:cancel')}</span>
                                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
