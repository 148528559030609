// React
import { useEffect, useMemo, useState } from 'react';

// Config
import { BACKEND_PROTOCOL, BACKEND_IP, BACKEND_PORT } from '../../config/constants';

// Utils
import { getDateFromString } from '../../utils/dates';

// Types
import { Message } from '../../types/socketio';

// Others
import { PauseIcon, PlayIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

const baseURL = `${BACKEND_PROTOCOL}://${BACKEND_IP}:${BACKEND_PORT}`;

type ChatAudioProps = {
    /** Message containing the audio */
    message: Message;
    /** Message header */
    header: string;
};

/**
 * Chat audio
 * @description
 * Chat bubble containing an audio message that can be played, paused
 * and resumed.
 *
 * The message props contains only the name of the audio file
 * that will be sourced directly from the server
 * @param props
 */
function ChatAudio(props: ChatAudioProps) {
    // Hooks
    const { t } = useTranslation();

    // State
    /* const audio = useMemo(
        () => new Audio(`BACKEND/media/chat/audio/${props.message.mediaName}.mp4`),
        [props.message.mediaName]
    ); */
    const audio = useMemo(
        () => new Audio(`${baseURL}/media/chat/audio/${props.message.mediaName}.mp4`),
        [props.message.mediaName]
    );
    const [playing, setPlaying] = useState(false);

    // Methods
    const getMessageStyle = () => {
        switch (props.message.type) {
            case 'OPERATOR':
                return 'bg-gray-50 mr-8 md:mr-24';
            default:
                return 'bg-white ml-8 md:ml-24';
        }
    };

    // Effects
    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing, audio]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    // Render
    return (
        <div className={`px-4 py-6 shadow sm:rounded-lg sm:px-6 ${getMessageStyle()}`}>
            <div className='sm:flex sm:items-baseline sm:justify-between'>
                <h3 className='text-base font-medium'>
                    <span className='text-gray-900'>{props.header}</span>{' '}
                    <span className='text-gray-600'>{t('chat:sent')}</span>
                </h3>
                <p className='mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3'>
                    {getDateFromString(props.message.timestamp).toLocaleString()}
                </p>
            </div>
            <div className='mt-4 space-y-6 text-sm text-gray-800'>
                {playing ? (
                    <PauseIcon className='h-10 cursor-pointer' onClick={() => setPlaying(false)} />
                ) : (
                    <PlayIcon className='h-10 cursor-pointer' onClick={() => setPlaying(true)} />
                )}
            </div>
        </div>
    );
}

export default ChatAudio;
