// React
import { useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput } from '../forms/FormInput';

// Config
import { POST_CONTACT } from '../../config/endpoints';

// Types
import { Contact } from '../../types/entities';

// Others
import { PostContactRequest } from '../../types/requests';

type ContactFormProps = {
    /** Contact to update */
    initialContact?: Contact;
    /** Contact location */
    locationId: number;
    /** Called on submit or cancel */
    onClose?: () => void;
};

function fromLocationToDto(contact: Contact, locationId: number): PostContactRequest {
    return {
        ...contact,
        locationId: locationId
    };
}

/**
 * Contact form
 * @description
 * Form used to create or update a contact.
 *
 * Uses HTML validation.
 * @param props
 */
function ContactForm(props: ContactFormProps) {
    // Query
    const contactMutation = usePostMutation<PostContactRequest>(POST_CONTACT, {
        invalidateQueries: ['contacts', props.locationId.toString()]
    });

    // State
    const [contact, setContact] = useState<PostContactRequest>(
        props.initialContact
            ? fromLocationToDto(props.initialContact, props.locationId)
            : ({} as PostContactRequest)
    );

    // Methods
    const handlePostRequest = () => {
        let data: PostContactRequest = {
            ...contact,
            locationId: props.locationId
        };

        if (data.phoneNumber) {
            const n = Number(data.phoneNumber);
            if (Number.isNaN(n)) data.phoneNumber = '';
            else {
                data.phoneNumber = data.phoneNumber.replace(/\D/g, '');
            }
        }

        contactMutation
            .mutateAsync(data, [{ key: 'locationId', value: props.locationId.toString() }])
            .then(() => {
                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='contacts:firstName'
                    value={contact.firstName || ''}
                    onChange={text => setContact({ ...contact, firstName: text })}
                />
                <FormInput
                    label='contacts:lastName'
                    value={contact.lastName || ''}
                    onChange={text => setContact({ ...contact, lastName: text })}
                />
                <FormInput
                    label='contacts:email'
                    value={contact.email || ''}
                    optional
                    contentType='email'
                    onChange={text => setContact({ ...contact, email: text })}
                />
                <FormInput
                    label='contacts:address'
                    value={contact.address || ''}
                    optional
                    onChange={text => setContact({ ...contact, address: text })}
                />
                <FormInput
                    label='contacts:phoneNumber'
                    value={contact.phoneNumber || ''}
                    optional
                    onChange={text => setContact({ ...contact, phoneNumber: text })}
                />
                <FormInput
                    label='contacts:type'
                    value={contact.type || ''}
                    optional
                    onChange={text => setContact({ ...contact, type: text })}
                />
            </div>
            <div className='bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialContact ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={contactMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default ContactForm;
