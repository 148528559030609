// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import UserForm from '../components/forms/UserForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_USERS, DELETE_USERS } from '../config/endpoints';
import { userColumns } from '../config/tables/users';

// Types
import { User } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';

/**
 * Users page
 * @description
 * Ordered and filterable list of users.
 * Only users relative to the current selection organization
 * will be shown.
 *
 * Users with an equal or higher role will not be included.
 *
 * This are the users that authenticates in the dashboard.
 *
 * Only manager of the organization or admins can create, update or
 * delete users.
 */
function UsersPage() {
    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(userColumns, 2);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const usersQuery = useGetQuery<User[]>(['users'], GET_USERS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest>(DELETE_USERS, {
        invalidateQueries: ['users']
    });

    // Memos
    const users = useMemo<User[] | undefined>(() => {
        if (!usersQuery.data) return;
        tableView.current?.changePage(1);

        return applyFilter(usersQuery.data);
    }, [usersQuery.data, filter]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <UserForm initialUser={selectedUser} onClose={() => setOpen(false)} />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setSelectedUser(undefined);
                    }}
                    onConfirm={() => {
                        if (selectedUser) {
                            deleteMutation
                                .mutateAsync({ ids: [selectedUser?.id] })
                                .then(() => {
                                    setDeleteOpen(false);
                                    setSelectedUser(undefined);
                                })
                                .catch(() => {});
                        }
                    }}
                />
            </BaseModal>
            <PageHeader
                title='users:title'
                buttons={['general:add']}
                onButtonPress={() => {
                    setSelectedUser(undefined);
                    setOpen(true);
                }}
            />
            <TableFilter columns={userColumns} filter={filter} setFilter={setFilter} />
            <Table<User>
                ref={tableView}
                columns={userColumns}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!users
                        ? [
                              user => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setSelectedUser(user);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                onCellPress={item => {
                    setSelectedUser(item);
                    setOpen(true);
                }}
                data={users}
            />
        </div>
    );
}

export default UsersPage;
