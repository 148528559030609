// Api
import useGetQuery from '../../api/useGetQuery';

// Components
import LocaleText from '../common/LocaleText';

// Config
import { GET_OPERATORS } from '../../config/endpoints';

// Types
import { Operator } from '../../types/entities';
import { OPERATOR_ROLE } from '../../types/enums';

type OpenDirectFormType = {
    currentChatOperatorId: number;
    onClose: () => void;
    onOperatorSelected: (operator: Operator) => void;
};

/**
 * Open direct form
 * @description
 * Shows the list of system operators.
 *
 * If one of them is clicked, it will attempt to send an
 * open direct request to the opearator application.
 * @param props
 */
function OpenDirectForm(props: OpenDirectFormType) {
    // Api
    const operatorsQuery = useGetQuery<Operator[]>(['operators'], GET_OPERATORS);

    // Methods
    const onSelect = (operator: Operator) => {
        props.onOperatorSelected(operator);
        props.onClose();
    };

    // Render
    return (
        <nav className='h-full overflow-y-auto rounded bg-white' aria-label='Directory'>
            <div className='flex'>
                <LocaleText text='chat:selectOperator' className='px-6 py-4 text-lg font-medium' />
                <input type='text' className='h-0 w-0 opacity-0' />
            </div>
            <div className='flex'>
                {Object.values(OPERATOR_ROLE).map((r, index) => (
                    <div key={index} className='flex-1'>
                        <div className='top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500'>
                            <LocaleText text={`operators:${r}`} />
                        </div>
                        <ul className='z-0'>
                            {operatorsQuery.data
                                ?.filter(o => o.role === r && o.id !== props.currentChatOperatorId)
                                .map(operator => (
                                    <li
                                        key={operator.id}
                                        className='cursor-pointer bg-white'
                                        onClick={() => onSelect(operator)}>
                                        <div className='relative flex items-center space-x-3 px-6 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50'>
                                            <div className='focus:outline-none'>
                                                <span
                                                    className='absolute inset-0'
                                                    aria-hidden='true'
                                                />
                                                <p className='text-sm font-medium text-gray-900'>{`${operator.firstName} ${operator.lastName}`}</p>
                                                <p className='truncate text-sm text-gray-500'>
                                                    {operator.username}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                ))}
            </div>
        </nav>
    );
}

export default OpenDirectForm;
