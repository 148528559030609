// React
import { useEffect, useMemo, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseButton, { ButtonType } from '../components/common/BaseButton';
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import ContactForm from '../components/forms/ContactForm';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Config
import { GET_CONTACTS, DELETE_CONTACTS } from '../config/endpoints';
import { contactsColumns } from '../config/tables/contacts';

// Utils
import { parseUrl } from '../utils/api';

// Types
import { Contact } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table } from '@edalab/react-tables';
import { TrashIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router';

/**
 * Contacts page
 * @description
 * Ordered and filterable list of a specific location contacts.
 * Only contacts relative to the current selection organization
 * will be shown.
 *
 * Only manager of the organization or admins can create, update or
 * delete contacts.
 */
function ContactsPage() {
    // Params
    const { locationId } = useParams<{ locationId: string }>();

    // Hooks
    const { filter, setFilter, applyFilter } = useFilter(contactsColumns);

    // State
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState<Contact>();

    // Query
    const contactsQuery = useGetQuery<Contact[]>(
        ['contacts', locationId],
        parseUrl(GET_CONTACTS, [{ key: 'locationId', value: locationId }])
    );
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest>(DELETE_CONTACTS, {
        invalidateQueries: ['contacts', locationId]
    });

    // Memos
    const contacts = useMemo<Contact[] | undefined>(() => {
        if (!contactsQuery.data) return;
        return applyFilter(contactsQuery.data);
    }, [contactsQuery.data, filter]);

    // Effects
    useEffect(() => {
        if (!open && !!selectedContact) setSelectedContact(undefined);
    }, [open]);

    // Render
    return (
        <div>
            <BaseModal open={open} setOpen={setOpen}>
                <ContactForm
                    initialContact={selectedContact}
                    locationId={parseInt(locationId)}
                    onClose={() => setOpen(false)}
                />
            </BaseModal>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setSelectedContact(undefined);
                    }}
                    onConfirm={() => {
                        if (selectedContact)
                            deleteMutation
                                .mutateAsync({ ids: [selectedContact.id] }, [
                                    { key: 'locationId', value: locationId }
                                ])
                                .then(() => {
                                    setDeleteOpen(false);
                                    setSelectedContact(undefined);
                                })
                                .catch(() => {});
                    }}
                    isLoading={deleteMutation.isLoading}
                />
            </BaseModal>
            <PageHeader title='contacts:title' />
            <TableFilter columns={contactsColumns} filter={filter} setFilter={setFilter} />
            <Table<Contact>
                columns={contactsColumns}
                data={contacts}
                baseRowClasses='cursor-pointer'
                rowOptions={
                    !!contacts
                        ? [
                              contact => (
                                  <TrashIcon
                                      className='mt-2 mr-2 w-5 cursor-pointer text-secondary md:mt-0 md:mr-5'
                                      onClick={() => {
                                          setSelectedContact(contact);
                                          setDeleteOpen(true);
                                      }}
                                  />
                              )
                          ]
                        : undefined
                }
                onCellPress={item => {
                    setSelectedContact(item);
                    setOpen(true);
                }}
            />
            <div className='mt-4 flex justify-end'>
                <BaseButton
                    label='general:add'
                    type={ButtonType.PRIMARY}
                    className='ml-3'
                    onClick={() => {
                        setSelectedContact(undefined);
                        setOpen(true);
                    }}
                />
            </div>
        </div>
    );
}

export default ContactsPage;
