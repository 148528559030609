// React
import { useState } from 'react';

// Api
import usePostMutation from '../../api/usePostMutation';

// Components
import BaseButton, { ButtonType } from '../common/BaseButton';
import { FormInput } from '../forms/FormInput';

// Config
import { POST_ORGANIZATION } from '../../config/endpoints';

// Types
import { Organization } from '../../types/entities';
import { PostOrganizationRequest } from '../../types/requests';

type OrganizationFormProps = {
    /** Organization to update */
    initialOrganization?: Organization;
    /** Called on submit or cancel */
    onClose?: () => void;
};

/**
 * Organization form
 * @description
 * Form used to create or update an organization.
 *
 * Uses HTML validation.
 * @param props
 */
function OrganizationForm(props: OrganizationFormProps) {
    // State
    const [organization, setOrganization] = useState<PostOrganizationRequest>(
        props.initialOrganization ? props.initialOrganization : ({} as PostOrganizationRequest)
    );

    // Query
    const organizationMutation = usePostMutation<PostOrganizationRequest>(POST_ORGANIZATION, {
        invalidateQueries: ['organizations']
    });

    // Methods
    const handlePostRequest = () => {
        organizationMutation
            .mutateAsync(organization)
            .then(() => {
                props.onClose && props.onClose();
            })
            .catch(() => {});
    };

    // Render
    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                handlePostRequest();
            }}>
            <div className='-space-y-px rounded-t-lg bg-white p-5 shadow-sm'>
                <FormInput
                    label='organizations:name'
                    value={organization.name || ''}
                    onChange={text => setOrganization({ ...organization, name: text })}
                />
            </div>
            <div className='rounded-bl-lg rounded-br-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                <BaseButton
                    label={props.initialOrganization ? 'general:update' : 'general:add'}
                    type={ButtonType.PRIMARY}
                    inputType='submit'
                    className='sm:ml-3'
                    isLoading={organizationMutation.isLoading}
                />
                <BaseButton
                    label='general:cancel'
                    type={ButtonType.SECONDARY}
                    onClick={() => props.onClose && props.onClose()}
                />
            </div>
        </form>
    );
}

export default OrganizationForm;
