// Others
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { Patient } from '../../../types/entities';
import image from '../../../assets/images/cross.png';

type HeaderT3cProps = {
    /** the patient of the T3C */
    patient: Patient;
    /** The color of the patient's last triage*/
    color: string;
};

/**
 * Header T3c
 * @description
 * Header section of the T3C
 *
 * the header section contains the Battle roster #
 * and the priority
 *
 * @param props
 *  */
function HeaderT3c(props: HeaderT3cProps) {
    // Render
    return (
        <View style={styles.textContainer}>
            <View style={styles.titleWrapper}>
                <Text style={styles.text}>BATTLE ROSTER #:</Text>
                <View style={styles.underline}>
                    <Text style={styles.normalText}>
                        {(props.patient.personalInformations.firstName
                            ? props.patient.personalInformations.firstName.substring(0, 1)
                            : '') +
                            (props.patient.personalInformations.lastName
                                ? props.patient.personalInformations.lastName.substring(0, 1)
                                : '') +
                            (props.patient.general.code
                                ? props.patient.general.code.substring(
                                      props.patient.general.code.length - 4,
                                      props.patient.general.code.length
                                  )
                                : '')}
                    </Text>
                </View>
            </View>
            <View style={styles.evacWrapper}>
                <Text style={styles.text}>EVAC: </Text>

                <View style={styles.squares}>
                    {props.color?.includes('red') && <Image src={image} style={styles.img} />}
                </View>
                <Text style={styles.squareText}>Urgent</Text>
                <View style={styles.squares}>
                    {props.color?.includes('yellow') && <Image src={image} style={styles.img} />}
                </View>
                <Text style={styles.squareText}>Priority</Text>
                <View style={styles.squares}>
                    {props.color?.includes('green') && <Image src={image} style={styles.img} />}
                </View>
                <Text style={styles.squareText}>Routine</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    textContainer: {
        marginTop: 5,
        borderBottom: 1.5,
        paddingBottom: 5,
        borderBottomColor: '#1F2937',
        flexDirection: 'column'
    },
    titleWrapper: {
        justifyContent: 'center',
        flexDirection: 'row'
    },
    evacWrapper: {
        marginTop: 3,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    underline: {
        width: 200,
        borderBottom: 1,
        borderBottomColor: '#1F2937',
        alignItems: 'center'
    },
    text: {
        fontWeight: 500,
        fontSize: 13,
        color: '#1F2937',
        marginRight: 5
    },
    normalText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937'
    },
    squareText: {
        fontWeight: 500,
        fontSize: 12,
        color: '#1F2937',
        marginLeft: 2,
        marginRight: 5
    },
    squares: {
        border: 1,
        width: 10,
        height: 10,
        borderColor: '#1F2937'
    },
    img: { height: 9, width: 8 }
});

export default HeaderT3c;
