// Components
import GeneralPdfPage from './GeneralPdfPage';
import HeaderPdf from './HeaderPdf';

// Assets
import black from '../../assets/fonts/black.ttf';
import bold from '../../assets/fonts/bold.ttf';
import light from '../../assets/fonts/light.ttf';
import medium from '../../assets/fonts/medium.ttf';
import regular from '../../assets/fonts/regular.ttf';
import thin from '../../assets/fonts/thin.ttf';

// Types
import { Fas, Patient, Sas, Triage } from '../../types/entities';

// Others
import { Document, Font, StyleSheet, Page, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

type PatientPdfProps = {
    patient: Patient;
    latestTriage?: Triage;
    latestFas?: Fas;
    latestSas?: Sas;
    latestNews2?: Triage;
    latestVitalSigns?: Triage;
    images?: string[];
};

const patientHeaders = [
    { key: 'personalInformations', title: 'Personal informations' },
    { key: 'status', title: 'Status' }
];

const triagesHeaders = [{ key: 'details', title: 'Details', subtitle: 'Specific triage details' }];

const fasHeaders = [
    { key: '<c>', title: '<C>', subtitle: 'Detailed informations' },
    { key: 'a', title: 'A', subtitle: 'Detailed informations' },
    { key: 'b', title: 'B', subtitle: 'Detailed informations' },
    { key: 'c', title: 'C', subtitle: 'Detailed informations' },
    { key: 'd', title: 'D', subtitle: 'Detailed informations' },
    { key: 'e', title: 'E', subtitle: 'Detailed informations' }
];

const sasHeaders = [
    { key: 'history', title: 'History', subtitle: 'Detailed informations' },
    { key: 'objectiveExams', title: 'Objective Exams', subtitle: 'Detailed informations' },
    { key: 'vitalSigns', title: 'Vital Signs', subtitle: 'Detailed informations' },
    { key: 'instrumentalExams', title: 'Instrumental Exams', subtitle: 'Detailed informations' },
    { key: 'labTests', title: 'Lab Tests', subtitle: 'Detailed informations' }
];

Font.register({
    family: 'Roboto',
    fonts: [
        { src: black, fontWeight: 800 },
        { src: bold, fontWeight: 700 },
        { src: medium, fontWeight: 500 },
        { src: regular, fontWeight: 400 },
        { src: light, fontWeight: 300 },
        { src: thin, fontWeight: 100 }
    ]
});

/**
 * Pdf component that renders patient informations
 * @param props
 */
function PatientPdf(props: PatientPdfProps) {
    // Hooks
    const { t } = useTranslation();

    // Methods
    const getFullImagePath = (imageName: string) =>
        `backend/media/patients/${props.patient.general.code}/${imageName}`;

    // Render
    return (
        <Document>
            <GeneralPdfPage
                details={props.patient}
                headers={patientHeaders}
                title={`${t('pdf:patient')} ${props.patient.general.code}`}
                color={props.patient.general.color}
            />
            {props.latestTriage && (
                <GeneralPdfPage
                    details={props.latestTriage}
                    headers={triagesHeaders}
                    title='pdf:latestTriage'
                    color={props.latestTriage.general.color}
                />
            )}
            {props.latestFas && (
                <GeneralPdfPage
                    details={props.latestFas}
                    headers={fasHeaders}
                    title='pdf:latestFas'
                    color={props.latestFas.general.color}
                />
            )}
            {props.latestSas && (
                <GeneralPdfPage
                    details={props.latestSas}
                    headers={sasHeaders}
                    title='pdf:latestSas'
                    color={props.latestSas.general.color}
                />
            )}
            {props.latestVitalSigns && (
                <GeneralPdfPage
                    details={props.latestVitalSigns}
                    headers={triagesHeaders}
                    title='pdf:latestVitalSigns'
                />
            )}
            {props.latestNews2 && (
                <GeneralPdfPage
                    details={props.latestNews2}
                    headers={triagesHeaders}
                    title='pdf:latestNews2'
                    color={props.latestNews2.general.color}
                />
            )}
            {props.images && props.images.length > 0 && (
                <Page style={styles.page} wrap={false}>
                    <HeaderPdf title='pdf:images' />
                    <View style={styles.imagesRow}>
                        {props.images.map(i => (
                            <View key={i} style={styles.imageContainer}>
                                <Image src={getFullImagePath(i)} style={styles.image} />
                            </View>
                        ))}
                    </View>
                </Page>
            )}
        </Document>
    );
}

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        backgroundColor: 'rgb(229, 231, 235)', //'#F9FAFB'
        padding: 10,
        paddingBottom: 70
    },
    imagesRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal: -10
    },
    imageContainer: {
        paddingHorizontal: 10,
        paddingBottom: 10,
        width: '25%'
    },
    image: {
        width: '100%'
    }
});

export default PatientPdf;
