// Utils
import { isDate } from '../utils/dates';

/**
 * Used for producing a set of data that can be
 * used to generate a csv file
 */
function useCsv() {
    // Methods
    const toString = (value: any) => {
        if (typeof value === 'string') return value;
        else if (typeof value === 'number') return value.toString();
        else if (typeof value === 'boolean') return String(value);
        else if (isDate(value)) {
            const date = new Date(value);
            return `${date.toLocaleString()}`;
        }
    };

    const generateCsv = (obj: any) => {
        const csvHeaders: any[] = [];
        let csvObj: any[] = [];
        if (typeof obj === 'object')
            for (const [k, v] of Object.entries(obj)) {
                if (v && typeof v === 'object')
                    for (const [key, value] of Object.entries(v)) {
                        csvHeaders.push(`${k}_${key}`);
                        if (Array.isArray(value)) csvObj.push(value.toString());
                        else csvObj.push(toString(value));
                    }
            }
        // Todo: implement
        else if (Array.isArray(obj)) {
        }

        return [csvHeaders, csvObj];
    };

    return generateCsv;
}

export default useCsv;
