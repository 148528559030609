// React
import { useMemo, useRef, useState } from 'react';

// Hooks
import useFilter from '../hooks/useFilter';

// Api
import useDeleteMutation from '../api/useDeleteMutation';
import useGetQuery from '../api/useGetQuery';

// Components
import BaseModal from '../components/common/BaseModal';
import PageHeader from '../components/common/PageHeader';
import DeleteConfirmationForm from '../components/forms/DeleteConfirmationForm';
import TableFilter from '../components/tables/TableFilter';

// Navigation
import { useHistory } from 'react-router-dom';

// Config
import { DELETE_PATIENTS, GET_LOCATIONS, GET_PATIENTS } from '../config/endpoints';
import { patientColumns } from '../config/tables/patients';

// Types
import { Patient, Location } from '../types/entities';
import { GeneralDeleteRequest } from '../types/requests';

// Others
import { Table, TableView } from '@edalab/react-tables';

type PatientsPageProps = {
    /** Number of table rows to be displayed */
    numberOfRows?: number;
};

/**
 * Patients page
 * @description
 * Ordered and filterable list of patients.
 * Only patients relative to the current selection organization
 * will be shown.
 *
 * Patients can not be deleted.
 */
function PatientsPage(props: PatientsPageProps) {
    // Hooks
    const history = useHistory();
    const { filter, setFilter, applyFilter } = useFilter(patientColumns, 0, [
        { colKey: 'general.latestTriage', locKey: 'patients' },
        { colKey: 'personalInformations.sex', locKey: 'patients' },
        { colKey: 'general.color', locKey: 'general' },
        { colKey: 'status.operationSite', locKey: 'status' }
    ]);

    // State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState<string[]>([]);

    // Reference
    const tableView = useRef<TableView>(null);

    // Query
    const patientsQuery = useGetQuery<Patient[]>(['patients'], GET_PATIENTS);
    const locationsQuery = useGetQuery<Location[]>(['locations'], GET_LOCATIONS);
    const deleteMutation = useDeleteMutation<GeneralDeleteRequest<string>>(DELETE_PATIENTS);

    // Memos
    const patients = useMemo<Patient[] | undefined>(() => {
        if (!patientsQuery?.data) return;
        let patients = patientsQuery.data
            .map(p => {
                const location = locationsQuery?.data?.find(l => l.id === p.status.locationId);
                return {
                    ...p,
                    status: {
                        ...p.status,
                        locationId: location?.name || '-'
                    }
                };
            })
            .sort((a, b) => (a.general.createdAt > b.general.createdAt ? -1 : 1));

        patients = applyFilter(patients);
        tableView.current?.changePage(1);

        return props.numberOfRows ? patients.slice(0, props.numberOfRows) : patients;
    }, [patientsQuery, locationsQuery, props.numberOfRows, filter]);

    // Methods
    const navigate = (patient: Patient, key: string) => {
        if (!key.includes('location'))
            history.push({
                pathname: 'patients/' + patient.general.code,
                state: patient
            });
    };

    const deletePatients = () => {
        if (toBeDeleted.length > 0) {
            deleteMutation.mutate({ ids: toBeDeleted });
            setToBeDeleted([]);
            setDeleteOpen(false);
        }
    };

    // Render
    return (
        <div>
            <BaseModal open={deleteOpen} setOpen={setDeleteOpen}>
                <DeleteConfirmationForm
                    onClose={() => {
                        setDeleteOpen(false);
                        setToBeDeleted([]);
                    }}
                    onConfirm={deletePatients}
                />
            </BaseModal>
            <PageHeader title='patients:title' />
            <TableFilter
                columns={patientColumns}
                filter={filter}
                setFilter={setFilter}
                blacklist={['status.locationId', 'status.evacuated']}
            />
            <Table<Patient>
                ref={tableView}
                columns={patientColumns}
                baseRowClasses='cursor-pointer'
                data={patients}
                onCellPress={(item, key) => navigate(item, key)}
            />
        </div>
    );
}

export default PatientsPage;
