// Components
import {
    BooleanTableCell,
    ColorTableCell,
    DoubleTableCell,
    LocalizedTableCell,
    LocationTableCell,
    SingleTableCell,
    TagTableCell
} from '../../components/tables/TableCell';

// Utils
import { getColorValue } from '../../utils/colors';

// Types
import { Patient } from '../../types/entities';

// Others
import { ColumnConfig } from '@edalab/react-tables';

export const patientColumns: ColumnConfig<Patient>[] = [
    {
        title: 'patients:code',
        key: 'general.code'
    },
    {
        title: 'general:createdAt',
        key: 'general.createdAt',
        flex: 0.8,
        renderer: patient => (
            <DoubleTableCell
                first={new Date(patient.general.createdAt).toLocaleDateString()}
                second={new Date(patient.general.createdAt).toLocaleTimeString()}
            />
        )
    },
    {
        title: 'patients:lastName',
        key: 'personalInformations.lastName',
        renderer: patient => (
            <DoubleTableCell
                first={patient.personalInformations.lastName}
                second={patient.personalInformations.firstName}
            />
        )
    },
    {
        title: 'patients:color',
        key: 'general.color',
        renderer: patient => (
            <ColorTableCell text={patient.general.color} color={patient.general.color} />
        ),
        compare(a, b) {
            if (getColorValue(a.general.color) < getColorValue(b.general.color)) return 1;
            else if (getColorValue(a.general.color) === getColorValue(b.general.color)) return 0;
            else return -1;
        }
    },
    {
        title: 'patients:latestTriage',
        key: 'general.latestTriage',
        renderer: patient =>
            !patient.general.latestTriage ? (
                <SingleTableCell text='-' />
            ) : (
                <TagTableCell text={`patients:${patient.general.latestTriage}`} />
            )
    },
    {
        title: 'patients:operationSite',
        key: 'status.operationSite',
        renderer: patient => (
            <LocalizedTableCell
                text={
                    patient.status?.operationSite ? `status:${patient.status?.operationSite}` : '-'
                }
            />
        )
    },
    {
        title: 'patients:location',
        key: 'status.locationId',
        disableOrderIcon: true,
        renderer: patient => (
            <LocationTableCell
                latitude={patient.status.currentLatitude}
                longitude={patient.status.currentLongitude}
            />
        )
    },
    {
        title: 'patients:sex',
        key: 'personalInformations.sex',
        flex: 0.8,
        compare: (a, b) => {
            if (!a || a.personalInformations.sex === '-') return -1;
            else if ((a.personalInformations.sex || '-') > (b.personalInformations.sex || '-'))
                return 1;
            else return -1;
        },
        renderer: patient => (
            <LocalizedTableCell
                text={
                    patient.personalInformations?.sex
                        ? `patients:${patient.personalInformations?.sex}`
                        : '-'
                }
            />
        )
    },
    {
        title: 'patients:evacuated',
        key: 'status.evacuated',
        flex: 0.8,
        renderer: patient => <BooleanTableCell value={patient.status.evacuated} />
    }
];
