// React
import React, { Fragment } from 'react';

// Others
import { Dialog, Transition } from '@headlessui/react';

type BaseModalProps = {
    /**  */
    open: boolean;
    /** Content of the modal */
    children?: React.ReactNode;
    /** Class that defines how large the modal should be */
    widthClass?: string;
    /** Set modal open status */
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    /** Called when modal is closed */
    onClose?: () => void;
};

/**
 * Wrapper for all modal components
 * @describe
 * Provides the default behaviour for a modal
 * (click handlers, faded background... )
 * @param props
 */
function BaseModal(props: BaseModalProps) {
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as='div'
                static
                className='fixed inset-0 z-10 overflow-y-auto'
                open={props.open}
                onClose={val => {
                    props.setOpen(val);
                    if (!val && props.onClose) props.onClose();
                }}>
                <div className='min-h-screen flex items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>
                    <span
                        className='hidden sm:inline-block sm:h-screen sm:align-middle'
                        aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                        <div
                            className={`inline-block transform rounded-lg text-left align-bottom transition-all sm:w-full sm:align-middle ${
                                props.widthClass || 'sm:max-w-lg'
                            }`}>
                            {props.children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default BaseModal;
